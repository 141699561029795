import { Component, Optional, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import { Job, JobApplication, User } from '@core/models';
import { Store, Select } from '@ngxs/store';
import { RequestToBookJobApplication, AcceptJobApplicationAndCreatePayment, PaymentsState } from '@core/states';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-request-to-book-modal',
  templateUrl: './request-to-book-modal.component.html',
  styleUrls: ['./request-to-book-modal.component.scss'],
})
export class RequestToBookModalComponent implements OnInit {
  @Select(PaymentsState.paymentSuccessId)
  public paymentSuccessId: Observable<string>;

  public emailIcon = faEnvelope;

  public jobApplication: JobApplication;
  public job: Job;
  public isSuccess = false;
  public isBusy = false;
  public user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<RequestToBookModalComponent>,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.jobApplication = this.data.jobApplication;
    this.user = this.data.user;
    this.job = this.data.job;

    if (this.job.bypassPayment === false) {
      this.paymentSuccessId.subscribe((value) => {
        if (this.jobApplication.id === value) {
          this.dialogRef.close();
        }
      });
    }
  }

  public async acceptJobApplication(): Promise<void> {
    this.isBusy = true;

    try {
      if (this.job.bypassPayment) {
        await this.store.dispatch(
          new RequestToBookJobApplication(this.user.id, this.jobApplication.jobId, this.jobApplication.id),
        );
        this.isSuccess = true;
        this.isBusy = false;
      } else {
        const returnUrl = `${environment.baseUrl}/account/jobs/status/open`;
        await this.store.dispatch(
          new AcceptJobApplicationAndCreatePayment(
            this.user.id,
            this.jobApplication.user.id,
            this.jobApplication.jobId,
            this.jobApplication.id,
            returnUrl,
            !this.job.bypassPayment,
          ),
        );
      }
    } catch (error) {
      console.error('Failed to accept applicant', error);
    }
  }

  public acceptJobApplicationAndCreatePayment(): void {
    this.isBusy = true;

    if (this.job.bypassPayment) {
      this.dialogRef.close();
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
