import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Job, JobApplication } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import {
  AcceptJobApplicationAndCreatePayment,
  RequestToBookJobApplication,
} from '@core/states/users/jobs/jobs.actions';
import { PaymentsState } from '@core/states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-request-book-modal',
  templateUrl: './mobile-request-book-modal.component.html',
  styleUrls: ['./mobile-request-book-modal.component.scss'],
})
export class MobileRequestBookModalComponent implements OnInit {
  @Select(PaymentsState.paymentSuccessId)
  public paymentSuccessId: Observable<string>;

  @ViewChild('appRequestToBookModal') private modalComponent: MobileModalDialogComponent;

  public isSuccess: boolean;
  public isBusy: boolean;
  public jobApplication: JobApplication;
  public job: Job;

  constructor(private store: Store, public translate: TranslateService) {}

  ngOnInit(): void {}

  async openDialog(applicant: JobApplication, job: Job): Promise<void> {
    this.jobApplication = applicant;
    this.job = job;

    if (this.job.bypassPayment === false) {
      this.paymentSuccessId.subscribe((value) => {
        if (this.jobApplication.id === value) {
          this.close();
        }
      });
    }

    return await this.modalComponent.open().then(() => {
      this.isBusy = false;
      this.isSuccess = false;
      this.jobApplication = null;
      this.job = null;
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public async acceptJobApplication(): Promise<void> {
    this.isBusy = true;
    try {
      if (this.job.bypassPayment) {
        await this.store.dispatch(
          new RequestToBookJobApplication(
            this.jobApplication.user.id,
            this.jobApplication.jobId,
            this.jobApplication.id,
          ),
        );
        this.isSuccess = true;
        this.isBusy = false;
      } else {
        await this.store.dispatch(
          new AcceptJobApplicationAndCreatePayment(
            this.jobApplication.user.id,
            this.jobApplication.user.id,
            this.jobApplication.jobId,
            this.jobApplication.id,
            null,
            !this.job.bypassPayment,
          ),
        );
        this.isSuccess = true;
        this.isBusy = false;
      }
    } catch (error) {
      alert('Failed to accept applicant');
    }
  }
}
