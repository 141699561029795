<div *ngIf="!applied" class="d-flex flex-column w-100 text-white p-4">
  <div class="container" *ngIf="!isShowVideoField; else videoField">
    <div class="row justify-content-center">
      <h4 translate>This job requires an audition video</h4>
    </div>
    <div class="row video-description p-3 my-4">{{ job.requestTalentVideoDescription }}</div>
    <div class="mt-5">
      <app-button (click)="showVideoField()" buttonStyle="artyclick-purple" class="mb-3">
        <div class="px-2" translate>Upload video/insert link</div>
      </app-button>
      <app-button *ngIf="!jobApplication" (click)="skipAndApply()" buttonStyle="white-outline" class="mb-3">
        <div class="px-2" translate>Skip and apply</div>
      </app-button>
    </div>
  </div>

  <ng-template #videoField>
    <form [formGroup]="form" *ngIf="form" class="container">
      <div class="row justify-content-center">
        <h4 translate>This job requires an audition video</h4>
      </div>
      <p>
        Your video is exceeding 20mb. Please upload your video to YouTube and copy/paste the link in here. In YouTube
        choose 'Unlisted' so your video is not visible on your account.
      </p>
      <p>*Casterbee is currently working on an improve video-upload module</p>
      <div class="row mb-3">
        <div class="col-12 d-flex flex-wrap my-4 align-items-center justify-content-center">
          <app-media-item-field
            [albumId]="job.id"
            uploadType="jobApplication"
            [formControl]="media"
            [disableDelete]="true"
            class="d-flex"></app-media-item-field>
        </div>
      </div>

      <app-button [isDisabled]="!form.valid" (click)="applyWithVideo()" buttonStyle="artyclick-purple" class="mb-3">
        <div class="px-2" *ngIf="!jobApplication" translate>Apply with audition video</div>
        <div class="px-2" *ngIf="!!jobApplication" translate>Upload audition video</div>
      </app-button>
      <app-button *ngIf="!jobApplication" (click)="skipAndApply()" buttonStyle="white-outline" class="mb-3">
        <div class="px-2" translate>Skip and apply</div>
      </app-button>
    </form>
  </ng-template>
</div>

<div *ngIf="applyModal && applied" class="d-flex flex-column w-100 text-white p-4">
  <div class="container justify-content-center align-items-center d-flex flex-column">
    <div class="row justify-content-center">
      <img class="checkmark" src="/assets/img/purple-checklist.png" />
      <h5 class="text-center my-3" translate>
        You applied to the job. When the Caster accepts your application you will get a notification
      </h5>
    </div>
    <app-button *ngIf="!jobApplication" (click)="closeModal()" buttonStyle="artyclick-purple" class="mb-3 w-50">
      <div class="px-2" translate>Close</div>
    </app-button>
  </div>
</div>

<div *ngIf="isOnlyVideoUpload && applied" class="d-flex flex-column w-100 text-white p-4">
  <div class="container justify-content-center align-items-center d-flex flex-column">
    <div class="row justify-content-center">
      <img class="checkmark" src="/assets/img/purple-checklist.png" />
      <h5 class="text-center my-3" translate>
        Your video is added to your job application. When the Caster accepts your application you will get a
        notification
      </h5>
    </div>
  </div>
</div>
