<ng-container *appScreenSize="'dekstop'">
  <form (ngSubmit)="onSubmit()" *ngIf="user" [formGroup]="form" body class="form px-sm-4 px-1">
    <h1 class="h3 pb-4" translate>Account details</h1>

    <div class="row mb-3">
      <app-label class="col-12 col-sm-2 align-self-center">
        <span translate>Username</span>
      </app-label>
      <div class="col-12 col-sm-8 col-12">
        <div class="d-flex">
          <app-text-field
            class="bg-gray w-100 flex-fill border-0 rounded mb-1"
            [isReadOnly]="true"
            [title]="user.times_profile_changed > 3 ? 'Your limit of profile name changes has been reached.' : ''"
            formControlName="profileName"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-validation-indicator [field]="form.controls.profileName" class="mr-2"></app-validation-indicator>
            <app-locked-indicator [locked]="true" class="mr-2"></app-locked-indicator>
            <app-visibility-indicator [visible]="true"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-sm-8 col-12 ml-auto">
        <small class="text-lilac" translate>
          This is your public name. Using your real name increases credibility with applicants
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label class="col-12 col-sm-2 align-self-center" [isRequired]="true">
        <span translate>Profile image</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex align-items-center">
          <app-image
            [image]="form.value.photoFile || profileThumb"
            [ngClass]="{
              'ng-invalid': !form.controls.photoFile.valid,
              'border-danger': !(form.value.photoFile || profileThumb)
            }"
            class="preview rounded-circle mr-2"></app-image>
          <app-file-field
            accept="image/png,image/x-png,image/gif,image/jpeg"
            formControlName="photoFile"></app-file-field>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="true"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small
          *ngIf="!form.controls.photoFile.valid && form.controls.photoFile.hasError('invalid')"
          class="text-error"
          translate>
          Only images are allowed to be selected in this field
        </small>
        <br *ngIf="!form.controls.photoFile.valid && form.controls.photoFile.hasError('invalid')" />
        <small class="text-lilac" translate>
          An attractive profile including a clear own picture increases your chances on succesfull bookings, and job
          posts.
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <!-- <div class="row mb-0">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>VAT registered?</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <label class="d-flex align-items-center mr-3">
            <app-radio class="mr-2" formControlName="userType" value="company"></app-radio>
            <span translate>Yes</span>
          </label>
          <label class="d-flex align-items-center">
            <app-radio class="mr-2" formControlName="userType" value="private"></app-radio>
            <span translate>No</span>
          </label>

          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="true"></app-visibility-indicator>
          </div>
        </div>
      </div>
    </div> -->
    <ng-container *ngIf="userType() === 'company'">
      <div class="row mb-0">
        <div class="col-12 col-sm-8 offset-sm-2">
          <div class="border rounded py-3 mt-0 px-3">
            <div class="row mb-3">
              <app-label class="col-12 col-md-3 align-self-center">
                <span translate>VAT number</span>
              </app-label>
              <div class="col-4 col-md-3 mb-1">
                <app-select class="bg-gray border-0 rounded" formControlName="vatNumberCountryId" [searchable]="true">
                  <app-option [value]="null"></app-option>
                  <app-option
                    *ngFor="let country of countries"
                    [value]="country.id"
                    [searchValue]="country.alpha2Code"
                    class="d-flex w-100 py-1 align-items-center">
                    <img
                      class="flag mr-2"
                      src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                        country.isoCode.toLowerCase()
                      }}.svg"
                      [alt]="country.alpha2Code" />
                    {{ country.alpha2Code }}
                  </app-option>
                </app-select>
              </div>
              <div class="col-8 col-md-6 d-flex">
                <app-text-field
                  class="bg-gray flex-fill border-0 rounded mb-1"
                  formControlName="vatNumber"></app-text-field>
                <div class="ml-3 d-flex align-items-center">
                  <app-validation-indicator [field]="vatCheckResult" class="mr-2"></app-validation-indicator>
                  <app-visibility-indicator [visible]="false"></app-visibility-indicator>
                </div>
              </div>
              <div class="col-12 col-md-9 offset-sm-3">
                <small class="text-lilac" translate>
                  Make sure you enter your VAT / Company number without any dots or dashes
                </small>
              </div>
            </div>
            <div class="row mb-3">
              <app-label class="col-12 col-md-3 align-self-center">
                <span translate>CoC number</span>
              </app-label>
              <div class="col-12 col-md-9">
                <div class="d-flex">
                  <app-text-field
                    class="bg-gray w-100 flex-fill border-0 rounded mb-1"
                    formControlName="coc_nr"></app-text-field>
                  <div class="ml-3 d-flex align-items-center">
                    <app-validation-indicator [field]="coc_nr" class="mr-2"></app-validation-indicator>
                    <app-visibility-indicator [visible]="false"></app-visibility-indicator>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="vat" class="row mb-3">
              <div class="col-12 col-md-6 offset-md-3">
                <app-button
                  [isBusy]="isBusy"
                  (click)="validateVat()"
                  [isDisabled]="isVatNumberCheckDisabled()"
                  class="mr-5"
                  gradientName="purple-bright">
                  <button class="w-100 h-100 bg-transparent border-0 text-white px-2" translate type="submit">
                    Verify
                  </button>
                </app-button>
              </div>
            </div>
            <div class="row mb-3" *ngIf="vatCheckResult">
              <app-label class="col-12 col-md-3 align-self-center">
                <span translate>VAT registered as:</span>
              </app-label>
              <div class="col-12 col-md-9">
                {{ vatCheckResult.name }}
                <br />
                <span class="newlines">{{ vatCheckResult.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row my-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>First Name</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field
            [isReadOnly]="user.idVerification"
            class="bg-gray w-100 flex-fill border-0 rounded mb-1"
            formControlName="firstName"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-validation-indicator [field]="form.controls.firstName" class="mr-2"></app-validation-indicator>
            <app-locked-indicator [locked]="user.idVerification" class="mr-2"></app-locked-indicator>
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>Use your official given name in your passport of your ID-card.</small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Last Name</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field
            [isReadOnly]="user.idVerification"
            class="bg-gray w-100 flex-fill border-0 rounded mb-1"
            formControlName="lastName"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-validation-indicator [field]="form.controls.lastName" class="mr-2"></app-validation-indicator>
            <app-locked-indicator [locked]="user.idVerification" class="mr-2"></app-locked-indicator>
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>
          Use your official given name in your passport of your ID-card.
          <br />
          INFO: This will only be visible to other party after booking has been made.
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Date of birth</span>
      </app-label>
      <div class="col-12 col-sm-4">
        <div class="d-flex">
          <app-date-field
            [isReadOnly]="user.idVerification"
            class="bg-gray w-100 flex-fill border-0 rounded mb-1"
            formControlName="birthday"></app-date-field>
          <div class="ml-3 d-flex align-items-center">
            <app-locked-indicator [locked]="user.idVerification" class="mr-2"></app-locked-indicator>
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>Use your official given birthdate in your passport or your ID-card.</small>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center"><span translate>Gender</span></app-label>
      <div class="col-12 col-sm-8">
        <div class="row">
          <div class="col-12 col-sm-4 d-flex">
            <label class="d-flex align-items-center mr-3">
              <app-radio
                [isReadOnly]="user.idVerification"
                class="mr-2"
                formControlName="gender"
                value="male"></app-radio>
              <span translate>Male</span>
            </label>
            <label class="d-flex align-items-center">
              <app-radio
                [isReadOnly]="user.idVerification"
                class="mr-2"
                formControlName="gender"
                value="female"></app-radio>
              <span translate>Female</span>
            </label>
          </div>
          <!-- <div class="col-sm-4 col-12 d-flex border-left-1">
            <label class="d-flex align-items-center">
              <app-checkbox
                [isReadOnly]="user.idVerification"
                class="mr-2"
                formControlName="transgender"
                value="true"></app-checkbox>
              <span translate>LGBTQ+</span>
            </label>
            <div class="ml-3 d-flex align-items-center">
              <app-locked-indicator [locked]="user.idVerification" class="mr-2"></app-locked-indicator>
              <app-visibility-indicator [visible]="true"></app-visibility-indicator>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>
          Fill in the gender you indentify yourself + use transgender if you want to have this known outside.
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div *ngIf="countries | sort: 'callingCode' as countries" class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Mobile phonenumber</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="row">
          <div class="col-8 col-sm-4 mb-1">
            <app-select class="bg-gray border-0 rounded" formControlName="phonenumberCountryId" [searchable]="true">
              <app-option [value]="null"></app-option>
              <app-option
                *ngFor="let country of countries"
                [value]="country.id"
                [searchValue]="country.translateKey | translate"
                class="d-flex w-100 py-1 align-items-center">
                <img
                  class="flag mr-2"
                  src="https://storage.googleapis.com/casterbee-public/img/flags/{{ country.isoCode.toLowerCase() }}.svg"
                  [alt]="country.isoCode" />
                +{{ country.callingCode }}
              </app-option>
            </app-select>
          </div>
          <div class="col-12 col-sm-6 d-flex">
            <app-text-field
              class="bg-gray flex-fill border-0 rounded mb-1"
              formControlName="phonenumber"></app-text-field>
            <div class="ml-3 d-flex align-items-center">
              <app-validation-indicator [field]="form.controls.phonenumber" class="mr-2"></app-validation-indicator>
              <app-visibility-indicator [visible]="false"></app-visibility-indicator>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showContactDetail">
      <div class="row mb-3">
        <app-label class="col-12 col-sm-2">
          <span translate>Contact Details</span>
        </app-label>
        <div class="ml-3">
          <div>
            <label class="d-flex mb-1">
              <app-radio class="mr-2" formControlName="contactDetails" value="confirmBooking"></app-radio>
              <span translate>Confirmed bookings</span>
            </label>
            <p style="margin-left: 27px">Only Casters when I confirmed a booking.*</p>
            <hr />
          </div>
          <div>
            <label class="d-flex mb-1">
              <app-radio class="mr-2" formControlName="contactDetails" value="appliedJobs"></app-radio>
              <span translate>Applied to jobs</span>
            </label>
            <p style="margin-left: 27px">
              Only when I applied to a job post, that Caster can see my contact details immediately.*
            </p>
            <hr />
          </div>
          <!-- <div>
            <label class="d-flex mb-1">
              <app-radio class="mr-2" formControlName="contactDetails" value="casterVerify"></app-radio>
              <span translate>Show to verified Casters</span>
            </label>
            <p style="margin-left:27px">Alread when a Caster sees my profile, they can see my contact details.*</p>
            <hr>
          </div>
          <div>
            <label class="d-flex mb-1">
              <app-radio class="mr-2" formControlName="contactDetails" value=""></app-radio>
              <span translate>Show to the public</span>
            </label>
            <p style="margin-left:27px">Show my contact details to anyone visiting the website, even when they don't
              have an account*</p>
            <hr>
          </div> -->
          <div class="d-flex">
            <img src=" /assets/img/light.svg" alt="" />
            <p class="ml-2 mt-3">
              *Casters with a premium membership can always see your contact details at any stage.
            </p>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
    </ng-container>

    <div *ngIf="showMobileVerification" class="row mb-3">
      <app-label class="col-12 col-sm-2 align-self-center"><span translate>Code SMS-Code</span></app-label>
      <div class="col-12 col-sm-8">
        <app-verify-mobile
          [phonenumber]="fullPhonenumber"
          class="d-flex"
          formControlName="mobileVerificationCode"></app-verify-mobile>
      </div>
      <div class="col-2"></div>
    </div>
    -->

    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center"><span translate>Email</span></app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field
            class="bg-gray w-100 flex-fill border-0 rounded mb-1"
            formControlName="email"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-validation-indicator [field]="form.controls.email" class="mr-2"></app-validation-indicator>
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>
          This is your default email to login and receive alerts and notifications to
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label class="col-12 col-sm-2 align-self-center">
        <span translate>Job alert</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <label class="d-flex align-items-center mr-3">
            <app-checkbox class="mr-2" formControlName="activeJobAlert"></app-checkbox>
            <span translate>Receive job alert via e-mail</span>
          </label>

          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>
          Check the box if you want to receive an automatic email when a new job is posted
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label class="col-12 col-sm-2 align-self-center"><span translate>ID verification</span></app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex align-items-center">
          <app-image
            [image]="form.value.idVerificationFile || idThumb"
            class="preview rounded-circle mr-2"
            [ngClass]="{ 'ng-invalid': !form.controls.idVerificationFile.valid }"></app-image>
          <app-file-field
            accept="image/png,image/x-png,image/gif,image/jpeg"
            formControlName="idVerificationFile"></app-file-field>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small *ngIf="!form.controls.idVerificationFile.valid" translate>
          Only images are allowed to be selected in this field
        </small>
        <br *ngIf="!form.controls.idVerificationFile.valid" />
        <small class="text-lilac" translate>
          IMPORTANT: Please fill in the other details on this page first before starting your ID verifciation Uploading
          your passport or ID card (max 5mb). You photo, first, last name the date of birth and the document number need
          to be visible
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label class="col-12 col-sm-2 align-self-center"><span translate>Website</span></app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field
            class="bg-gray w-100 flex-fill border-0 rounded mb-1"
            formControlName="website"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-validation-indicator [field]="form.controls.website" class="mr-2"></app-validation-indicator>
            <app-locked-indicator [locked]="false" class="mr-2"></app-locked-indicator>
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>If you have a website, enter the URL here.</small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Postal code</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field
            class="bg-gray flex-fill border-0 rounded-sm mb-1"
            formControlName="postalCode"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto"></div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Street</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field class="bg-gray flex-fill border-0 rounded-sm mb-1" formControlName="street"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto"></div>
      <div class="col-2"></div>
    </div>
    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>House number</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-text-field
            class="bg-gray flex-fill border-0 rounded-sm mb-1"
            formControlName="houseNumber"></app-text-field>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="false"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>The adress is important for bank & invoicing details.</small>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Place</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-geolocate-field
            class="bg-gray flex-fill border-0 rounded-sm mb-1"
            formControlName="city"></app-geolocate-field>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="true"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small
          class="text-error"
          *ngIf="!form.controls.city.valid && form.controls.city.hasError('invalidCoordinates')"
          translate>
          The city isn't recognized. Please select one of the cities from the suggestions
        </small>
        <br />
        <small class="text-lilac" translate>
          Fill in the city to have a more precise filter and outcome of your searches.
        </small>
      </div>
      <div class="col-2"></div>
    </div>

    <div *ngIf="countries" class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-center">
        <span translate>Country</span>
      </app-label>
      <div class="col-12 col-sm-8">
        <div class="d-flex">
          <app-select class="bg-gray border-0 w-100 rounded" formControlName="countryId" [searchable]="true">
            <app-option [value]="null"></app-option>
            <app-option
              *ngFor="let country of countries"
              [searchValue]="country.name"
              [value]="country.id"
              class="d-flex w-100 py-1 align-items-center">
              <img
                class="flag mr-2"
                src="https://storage.googleapis.com/casterbee-public/img/flags/{{ country.isoCode.toLowerCase() }}.svg" />
              {{ country.translateKey | translate }}
            </app-option>
          </app-select>
          <div class="ml-3 d-flex align-items-center">
            <app-visibility-indicator [visible]="true"></app-visibility-indicator>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>Fill the country you are currently living.</small>
      </div>
      <div class="col-2"></div>
    </div>

    <div *ngIf="languages" class="row mb-3">
      <app-label [isRequired]="true" class="col-12 col-sm-2 align-self-start">
        <span translate>Which languages do you speak?</span>
      </app-label>
      <div class="col-sm-6 col-12 show-invalid">
        <div [formGroup]="userNativeLanguage" class="row mb-2">
          <div class="col-12 d-flex">
            <app-select class="bg-gray border-0 w-100 rounded" formControlName="id" [searchable]="true">
              <app-option [value]="null">{{ 'Add native language' | translate }}</app-option>
              <app-option
                *ngFor="let language of languages"
                [value]="language.id"
                [searchValue]="language.translateKey | translate"
                class="d-flex w-100 py-1 align-items-center">
                {{ language.translateKey | translate }} ({{ 'Native' | translate }})
              </app-option>
            </app-select>
          </div>
        </div>

        <div
          *ngFor="let userLanguage of userLanguages.controls; let i = index"
          [formGroup]="userLanguage"
          class="row mb-2">
          <div class="col-12 d-flex">
            <app-select class="bg-gray border-0 w-100 rounded" formControlName="id" [searchable]="true">
              <app-option [value]="null"></app-option>
              <app-option
                *ngFor="let language of languages"
                [value]="language.id"
                [searchValue]="language.translateKey | translate"
                class="d-flex w-100 py-1 align-items-center">
                {{ language.translateKey | translate }}
              </app-option>
            </app-select>
            <div class="ml-3 d-flex align-items-center">
              <app-visibility-indicator [visible]="true" class="mr-2"></app-visibility-indicator>
              <app-icon
                (click)="deleteUserLanguage(i)"
                fill="white"
                icon="/assets/img/trash.svg"
                class="cursor-pointer"></app-icon>
            </div>
          </div>
        </div>

        <app-button (click)="addLanguageFormGroup(null)" gradientName="purple-bright" translate class="prefix-plus">
          <span translate>Add Language</span>
        </app-button>
      </div>

      <div class="col-12 col-sm-8 ml-auto">
        <small class="text-lilac" translate>Add the languages that you speak</small>
      </div>
      <div class="col-2"></div>
    </div>

    <hr class="mb-3" />

    <div class="row mb-3 align-items-center">
      <div class="col-1 p-0 d-flex justify-content-end">
        <app-icon class="big" fill="#BDA3FA" icon="/assets/img/address.svg"></app-icon>
      </div>
      <div class="col-8">
        <div>
          <small translate>
            Your address will only be visible to the other party after a booking has been paid. The address is important
            for the bank & invoice details.
          </small>
        </div>
      </div>
    </div>

    <div class="row mb-5 align-items-center">
      <div class="col-1 p-0 d-flex justify-content-end">
        <app-icon class="big" fill="#BDA3FA" icon="/assets/img/save_purple.svg"></app-icon>
      </div>
      <div class="col-8">
        <small translate>You acknowledge that your info is filled in thruthfully.</small>
        <br />
        <small translate>* Make sure that all the fields marked with * are filled in before you can save.</small>
      </div>
    </div>

    <div class="mb-3">
      <label class="mb-2" translate>Terms & Conditions</label>
      <label class="d-flex">
        <app-checkbox class="mr-2" formControlName="termsAndConditions"></app-checkbox>
        <small translate>
          I accept the
          <a href="/about/terms-and-conditions" target="_blank">terms & conditions</a>
          &
          <a href="/about/privacy-policy" target="_blank">privacy statement</a>
          .
        </small>
      </label>
    </div>
    <div class="d-flex align-items-center">
      <app-button [isBusy]="isBusy" [isDisabled]="isBusy || form.invalid" class="mr-5" gradientName="purple-bright">
        <button class="w-100 h-100 bg-transparent border-0 text-white px-2" translate type="submit">Save</button>
      </app-button>
      <app-alert
        *ngIf="message"
        [type]="message?.type"
        class="bg-gray rounded align-items-center px-2 align-self-stretch flex-fill">
        {{ message?.message }}
      </app-alert>
    </div>
  </form>
</ng-container>
<ng-container *appScreenSize="'mobile'">
  <form (ngSubmit)="onSubmit()" *ngIf="user" [formGroup]="form" class="form-setting">
    <!-- Username -->
    <div class="form-group">
      <label translate>Username</label>
      <input type="text" class="form-control" formControlName="profileName" />
      <p class="text-info" style="font-style: italic" translate>
        This is your public name. Using your real name increases credibility with applicants
      </p>
    </div>
    <div class="separator"></div>
    <!-- VAT registered -->
    <!-- <div class="form-group">
      <label translate>VAT registered</label>
      <div class="row">
        <div class="col">
          <label class="d-flex">
            <app-radio value="company" class="mr-2" formControlName="userType"></app-radio>
            <span translate>Yes</span>
          </label>
        </div>
        <div class="col">
          <label class="d-flex">
            <app-radio value="private" class="mr-2" formControlName="userType"></app-radio>
            <span translate>No</span>
          </label>
        </div>
      </div>
    </div> -->
    <ng-container *ngIf="userType() === 'company'">
      <div class="form-group">
        <div class="row">
          <div class="col">
            <app-select formControlName="vatNumberCountryId" [searchable]="true">
              <app-option [value]="null"></app-option>
              <app-option
                *ngFor="let country of countries"
                [value]="country.id"
                [searchValue]="country.alpha2Code"
                class="d-flex w-100 py-1 align-items-center">
                <img
                  class="flag mr-2"
                  src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                    country.isoCode.toLowerCase()
                  }}.svg"
                  [alt]="country.alpha2Code" />
                {{ country.alpha2Code }}
              </app-option>
            </app-select>
          </div>
          <div class="col">
            <input type="text" class="form-control" formControlName="vatNumber" />
          </div>
        </div>
        <p class="text-info" style="font-style: italic" translate>
          This is your public name. Using your real name increases credibility with applicants
        </p>
        <div class="form-group">
          <label translate>CoC number</label>
          <input type="text" class="form-control" formControlName="coc_nr" />
        </div>
      </div>
      <div class="text-center" *ngIf="vat">
        <button type="button" (click)="validateVat()" type="button" class="btn btn-add" translate>Verify</button>
      </div>
    </ng-container>
    <div class="separator"></div>
    <!-- First Name -->
    <div class="form-group" [ngClass]="{readonly: user.idVerification}">
      <label translate>First Name</label>
      <input type="text" class="form-control" [readonly]="user.idVerification" formControlName="firstName" />
      <p class="text-info" style="font-style: italic" translate>
        Use your official given name as shown in your passport of your ID-card.
      </p>
    </div>
    <div class="separator"></div>
    <!-- Last Name -->
    <div class="form-group" [ngClass]="{readonly: user.idVerification}">
      <label translate>Last Name</label>
      <input type="text" class="form-control" [readonly]="user.idVerification" formControlName="lastName" />
      <p class="text-info" style="font-style: italic" translate>
        INFO: This will only be visible to other party after booking has been made.
      </p>
    </div>
    <div class="separator"></div>
    <!-- Date of birth -->
    <div class="form-group" [ngClass]="{readonly: user.idVerification}">
      <label translate>Date of birth</label>
      <app-date-field [isReadOnly]="user.idVerification" iconColor="white" formControlName="birthday"></app-date-field>
      <p class="text-info" style="font-style: italic" translate>
        Use your official given birthdate as shown in your passport or your ID-card.
      </p>
    </div>
    <div class="separator"></div>
    <!-- Gender -->
    <div class="form-group" [ngClass]="{readonly: user.idVerification}">
      <label translate>Gender</label>
      <div class="row">
        <div class="col">
          <label class="d-flex">
            <app-radio
              [isReadOnly]="user.idVerification"
              value="male"
              class="mr-2"
              formControlName="gender"></app-radio>
            <span translate>Male</span>
          </label>
        </div>
        <div class="col">
          <label class="d-flex">
            <app-radio
              [isReadOnly]="user.idVerification"
              value="female"
              class="mr-2"
              formControlName="gender"></app-radio>
            <span translate>Female</span>
          </label>
        </div>
        <!-- <div class="col">
          <label class="d-flex">
            <app-radio [isReadOnly]="user.idVerification" value="true" class="mr-2"
              formControlName="transgender"></app-radio>
            <span translate>LGBTQ</span>
          </label>
        </div> -->
      </div>
      <!-- <p class="text-info" style="font-style: italic" translate>
        Fill in the gender you indentify yourself + use transgender if you want to have this known outside.
      </p> -->
    </div>
    <div class="separator"></div>
    <!-- Mobile phonenumber -->
    <ng-container *ngIf="countries | sort: 'callingCode' as countries">
      <div class="form-group">
        <label translate>Mobile phonenumber</label>
        <div class="row">
          <div class="col">
            <app-select formControlName="phonenumberCountryId" [searchable]="true">
              <app-option [value]="null"></app-option>
              <app-option
                *ngFor="let country of countries"
                [value]="country.id"
                [searchValue]="country.translateKey | translate"
                class="d-flex w-100 py-1 align-items-center">
                <img
                  class="flag mr-2"
                  src="https://storage.googleapis.com/casterbee-public/img/flags/{{ country.isoCode.toLowerCase() }}.svg"
                  [alt]="country.isoCode" />
                +{{ country.callingCode }}
              </app-option>
            </app-select>
          </div>
          <div class="col">
            <input type="text" class="form-control" formControlName="phonenumber" />
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="showMobileVerification">
      <app-verify-mobile
        [phonenumber]="fullPhonenumber"
        [styleUI]="'mobile'"
        class="d-flex"
        formControlName="mobileVerificationCode"></app-verify-mobile>
      <!-- <p class="text-info" style="font-style: italic" translate>
        You need to verify your mobile phonenumber. By clicking the button you'll receive a verification code by SMS
      </p> -->
    </div>
    <div class="separator"></div>
    <!-- Contact Detail -->
    <ng-container *ngIf="showContactDetail">
      <div class="form-group">
        <label translate>Contact Details</label>
        <div>
          <label class="d-flex">
            <app-radio class="mr-2" formControlName="contactDetails" value="confirmBooking"></app-radio>
            <span translate>Confirmed bookings</span>
          </label>
          <p style="margin-left: 27px">Only Casters when I confirmed a booking.*</p>
          <hr />
        </div>
        <div>
          <label class="d-flex">
            <app-radio class="mr-2" formControlName="contactDetails" value="appliedJobs"></app-radio>
            <span translate>Applied to jobs</span>
          </label>
          <p style="margin-left: 27px">
            Only when I applied to a job post, that Caster can see my contact details immediately.*
          </p>
          <hr />
        </div>
        <!-- <div>
          <label class="d-flex mb-1">
            <app-radio class="mr-2" formControlName="contactDetails" value="casterVerify"></app-radio>
            <span translate>Show to verified Casters</span>
          </label>
          <p style="margin-left:27px">Alread when a Caster sees my profile, they can see my contact details.*</p>
          <hr>
        </div>
        <div>
          <label class="d-flex mb-1">
            <app-radio class="mr-2" formControlName="contactDetails" value=""></app-radio>
            <span translate>Show to the public</span>
          </label>
          <p style="margin-left:27px">Show my contact details to anyone visiting the website, even when they don't
            have an account*</p>
          <hr>
        </div> -->
        <div class="d-flex">
          <img src=" /assets/img/light.svg" alt="" />
          <p class="ml-2 mt-3">*Casters with a premium membership can always see your contact details at any stage.</p>
        </div>
      </div>
      <div class="separator"></div>
    </ng-container>
    <!-- Email -->
    <div class="form-group">
      <label translate>Email</label>
      <input type="email" class="form-control" formControlName="email" />
      <p class="text-info" style="font-style: italic" translate>
        This is your default email to login and receive alerts and notifications to
      </p>
    </div>
    <div class="separator"></div>
    <!-- Notification Job -->
    <div class="form-group">
      <label translate>Job alert</label>
      <label class="d-flex">
        <app-checkbox value="private" class="mr-2" formControlName="activeJobAlert"></app-checkbox>
        <span translate>Receive job alert via e-mail</span>
      </label>
    </div>
    <div class="separator"></div>
    <!-- ID verification -->
    <div class="form-group">
      <label translate>ID verification</label>
      <div
        class="media"
        *ngIf="form.value.idVerificationFile || idThumb"
        [ngClass]="{ 'ng-invalid': !form.controls.idVerificationFile.valid }">
        <app-image [image]="form.value.idVerificationFile || idThumb" class="preview" style="border: none"></app-image>
        <div class="overlay">
          <button class="btn-delete-img" type="button" (click)="removeVerification()">
            <img src="/assets/img/mobile/trash.svg" alt="" />
          </button>
        </div>
      </div>
      <app-file-field
        accept="image/png,image/x-png,image/gif,image/jpeg"
        formControlName="idVerificationFile"
        [styleUI]="'mobile'"></app-file-field>
      <p class="text-info" style="font-style: italic" *ngIf="!form.controls.idVerificationFile.valid" translate>
        Only images are allowed to be selected in this field
      </p>
      <br *ngIf="!form.controls.idVerificationFile.valid" />
      <p class="text-info" style="font-style: italic" translate>
        IMPORTANT: Please fill in the other details on this page first before starting your ID verifciation Uploading
        your passport or ID card (max 5mb). You photo, first, last name the date of birth and the document number need
        to be visible
      </p>
    </div>
    <div class="separator"></div>
    <!-- Website -->
    <div class="form-group">
      <label translate>Website</label>
      <input type="text" class="form-control" formControlName="website" />
      <p class="text-info" style="font-style: italic" translate>If you have a website, enter the URL here.</p>
    </div>
    <div class="separator"></div>
    <!-- Postal Code -->
    <div class="form-group">
      <label translate>Postal code</label>
      <input type="text" class="form-control" formControlName="postalCode" />
    </div>
    <div class="separator"></div>
    <!-- Street -->
    <div class="form-group">
      <label translate>Street</label>
      <input type="text" class="form-control" formControlName="street" />
    </div>
    <div class="separator"></div>
    <!-- House number -->
    <div class="form-group">
      <label translate>House number</label>
      <input type="text" class="form-control" formControlName="houseNumber" />
      <p class="text-info" style="font-style: italic" translate>
        The adress is important for bank & invoicing details.
      </p>
    </div>
    <div class="separator"></div>
    <!-- Place -->
    <div class="form-group">
      <label translate>Place</label>
      <app-geolocate-field formControlName="city"></app-geolocate-field>
      <p
        class="text-info"
        style="font-style: italic"
        *ngIf="!form.controls.city.valid && form.controls.city.hasError('invalidCoordinates')"
        translate>
        The city isn't recognized. Please select one of the cities from the suggestions
        <br />
      </p>
      <p class="text-info" style="font-style: italic" translate>
        Fill in the city to have a more precise filter and outcome of your searches.
      </p>
    </div>
    <div class="separator"></div>
    <!-- Country -->
    <div class="form-group" *ngIf="countries">
      <label translate>Country</label>
      <app-select formControlName="countryId" [searchable]="true">
        <app-option [value]="null"></app-option>
        <app-option
          *ngFor="let country of countries"
          [searchValue]="country.name"
          [value]="country.id"
          class="d-flex w-100 py-1 align-items-center">
          <img
            class="flag mr-2"
            src="https://storage.googleapis.com/casterbee-public/img/flags/{{ country.isoCode.toLowerCase() }}.svg" />
          {{ country.translateKey | translate }}
        </app-option>
      </app-select>
      <p class="text-info" style="font-style: italic" translate>Fill the country you are currently living.</p>
    </div>
    <div class="separator"></div>
    <!-- Languange -->
    <div *ngIf="languages" class="mb-4 form-group">
      <label translate>Which languages do you speak?</label>
      <div [formGroup]="userNativeLanguage" class="mb-2">
        <div class="d-flex">
          <app-select class="border-0 w-100 rounded" formControlName="id" [searchable]="true">
            <app-option [value]="null">{{ 'Add native language' | translate }}</app-option>
            <app-option
              *ngFor="let language of languages"
              [value]="language.id"
              [searchValue]="language.translateKey | translate"
              class="d-flex w-100 py-1 align-items-center">
              {{ language.translateKey | translate }} ({{ 'Native' | translate }})
            </app-option>
          </app-select>
        </div>
        <div class="separator"></div>
      </div>
      <div *ngFor="let userLanguage of userLanguages.controls; let i = index" [formGroup]="userLanguage">
        <div class="d-flex">
          <app-select class="border-0 w-100 rounded" formControlName="id" [searchable]="true">
            <app-option [value]="null"></app-option>
            <app-option
              *ngFor="let language of languages"
              [value]="language.id"
              [searchValue]="language.translateKey | translate"
              class="d-flex w-100 py-1 align-items-center">
              {{ language.translateKey | translate }}
            </app-option>
          </app-select>
        </div>
        <div class="separator"></div>
      </div>
      <div class="text-center mb-3" *ngIf="userLanguages.controls.length > 0">
        <button
          type="button"
          (click)="deleteUserLanguage(userLanguages.controls.length -1)"
          class="btn btn-remove"
          translate>
          Delete
        </button>
      </div>
      <div class="text-center">
        <button type="button" (click)="addLanguageFormGroup(null)" class="btn btn-add" translate>Add Language</button>
      </div>
    </div>
    <!-- TnC -->
    <div class="separator"></div>
    <p class="text-info" style="font-style: italic" translate>
      Your address will only be visible to the other party after a booking has been paid. The address is important for
      the bank & invoice details.
    </p>
    <div class="separator"></div>
    <p class="text-info" style="font-style: italic" translate>
      <span translate>You acknowledge that your info is filled in thruthfully.</span>
      <span translate>* Make sure that all the fields marked with * are filled in before you can save.</span>
    </p>
    <div class="separator"></div>
    <div class="mb-3">
      <label class="mb-2 text-bold" translate>Terms & Conditions</label>
      <label class="d-flex">
        <app-checkbox class="mr-2" formControlName="termsAndConditions"></app-checkbox>
        <p style="position: relative; top: -2px" translate>
          I accept the
          <a href="/about/terms-and-conditions" target="_blank">terms & conditions</a>
          &
          <a href="/about/privacy-policy" target="_blank">privacy statement</a>
          .
        </p>
      </label>
    </div>
    <div class="mb-4" *ngIf="message?.type">
      <app-alert *ngIf="message" [type]="message?.type">{{ message?.message }}</app-alert>
    </div>
    <div class="text-center">
      <button type="submit" class="btn-submit" [disabled]="!form.valid">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
        <span *ngIf="!isBusy">{{ 'Save' | translate }}</span>
      </button>
    </div>
  </form>
</ng-container>
