<app-mobile-modal-dialog #appSuccessModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <div class="wrap-btn mb-2">
      <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
    </div>
  </div>
</app-mobile-modal-dialog>
