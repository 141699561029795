import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { ScreenSizeDirective } from './screen-size.directive';
import { NavbarScrollDirective } from './navbar-scroll.directive';
import { AutoAnimateDirective } from './auto-animate.directive';
import { UnleashDirective } from './unleash.directive';
import { NumberMaskDirective } from './input-sepetator.directive';
import { InputPriceFormatDirective } from './input-price.directive';
import { CustomCurrencyPipe } from './custom-currency.directive';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  declarations: [
    ScreenSizeDirective,
    NavbarScrollDirective,
    AutoAnimateDirective,
    UnleashDirective,
    NumberMaskDirective,
    InputPriceFormatDirective,
    CustomCurrencyPipe,
  ],
  exports: [
    ScreenSizeDirective,
    NavbarScrollDirective,
    AutoAnimateDirective,
    UnleashDirective,
    NumberMaskDirective,
    InputPriceFormatDirective,
    CustomCurrencyPipe,
  ],
  providers: [
    CurrencyPipe,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'IDR' },
    { provide: LOCALE_ID, useValue: 'id-ID' },
  ],
})
export class DirectiveModule {}
