import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SplideOptions } from '@splidejs/splide';
import { distinctUntilChanged } from 'rxjs/operators';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';
import { slideAndScaleTransition } from '@src/ui/generic/carousels/transitions';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';

@Component({
  selector: 'app-hero-carousel',
  templateUrl: './hero-carousel.component.html',
  styleUrls: ['./hero-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroCarouselComponent implements AfterViewInit {
  @Input()
  public items: Array<any>;

  public transition = slideAndScaleTransition;

  public options: SplideOptions = {
    type: 'loop',
    focus: 'center',
    keyboard: 'focused',
    waitForTransition: false,
  };

  public constructor(
    private cd: ChangeDetectorRef,
    private layoutCalculator: LayoutCalculatorService,
    private dialog: MatDialog,
  ) {
    this.layoutCalculator.layoutChanged$
      .pipe(distinctUntilChanged((r1: any, r2: any) => r1.containerWidth === r2.containerWidth))
      .subscribe((data) => this.updateParams(data));
  }

  public ngAfterViewInit(): void {
    this.updateParams(this.layoutCalculator.data);
  }

  public openVideo(): void {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        media: {
          url: '/assets/video/v2.mp4',
          mediaType: 'video',
        },
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public openBudgetcam(): void {
    window.open('https://acties.casterbee.nl/', '_blank');
  }

  private updateParams(data: any): void {
    const minHeight = 400;
    const width = data.containerWidth;
    const fixedHeight = data.containerWidth * 0.4;

    this.options = {
      ...this.options,
      gap: data.gap,
      fixedWidth: width,
      fixedHeight: fixedHeight < minHeight ? minHeight : fixedHeight,
    };

    this.cd.detectChanges();
  }
}
