import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { PaginatedResults, Payment } from '@core/models';
import { BankAccountDTO, ListResults, MerchantDTO, SettlementDTO, SettlementRowDTO, TransactionDTO } from '@core/dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  public constructor(private http: HttpClient) {}

  public createMerchant(userId: string): Observable<MerchantDTO> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/${userId}/merchant`, {})
      .pipe(map((data: MerchantDTO) => plainToClass(MerchantDTO, data)));
  }

  public getMerchantForUser(userId: string): Observable<MerchantDTO> {
    return this.http
      .get(`${environment.apiBaseUrl}/payments/${userId}/merchant`)
      .pipe(map((data: MerchantDTO) => plainToClass(MerchantDTO, data)));
  }

  public getBankAccountForUser(userId: string): Observable<BankAccountDTO> {
    return this.http
      .get(`${environment.apiBaseUrl}/payments/${userId}/bankaccount`)
      .pipe(map((data: BankAccountDTO) => plainToClass(BankAccountDTO, data)));
  }

  public createBankAccountForUser(userId: string): Observable<BankAccountDTO> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/${userId}/bankaccount`, {})
      .pipe(map((data: BankAccountDTO) => plainToClass(BankAccountDTO, data)));
  }

  public createPayment(
    talentId: string,
    jobApplicationId: string,
    returnUrl: string,
  ): Observable<{ payment: Payment; oppTransaction: TransactionDTO }> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/${talentId}/jobApplication/${jobApplicationId}`, { returnUrl })
      .pipe(
        map((data: { payment: Payment; oppTransaction: TransactionDTO }) => {
          data.payment = plainToClass(Payment, data.payment);
          data.oppTransaction = plainToClass(TransactionDTO, data.oppTransaction);
          return data;
        }),
      );
  }

  public createTravelExpensePayment(
    jobApplicationId: string,
    returnUrl: string,
  ): Observable<{ payment: Payment; oppTransaction: TransactionDTO }> {
    return this.http.post(`${environment.apiBaseUrl}/payments/travel-expenses/${jobApplicationId}`, { returnUrl }).pipe(
      map((data: { payment: Payment; oppTransaction: TransactionDTO }) => {
        data.payment = plainToClass(Payment, data.payment);
        data.oppTransaction = plainToClass(TransactionDTO, data.oppTransaction);
        return data;
      }),
    );
  }

  public createCreditPayment(
    packageId: string,
    returnUrl: string,
  ): Observable<{ payment: Payment; transaction: TransactionDTO }> {
    return this.http.post(`${environment.apiBaseUrl}/payments/credits/${packageId}`, { returnUrl }).pipe(
      map((data: { payment: Payment; transaction: TransactionDTO }) => {
        data.payment = plainToClass(Payment, data.payment);
        data.transaction = plainToClass(TransactionDTO, data.transaction);
        return data;
      }),
    );
  }

  public createJobPromotedPayment(jobId: string, returnUrl: string): Observable<TransactionDTO> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/job/${jobId}`, { returnUrl })
      .pipe(map((data: TransactionDTO) => plainToClass(TransactionDTO, data)));
  }

  public getPaymentById(paymentId: string): Observable<Payment> {
    return this.http
      .get(`${environment.apiBaseUrl}/payments/detail/${paymentId}`)
      .pipe(map((data: Payment) => plainToClass(Payment, data)));
  }

  public getPaymentsForUser(userId: string): Observable<PaginatedResults<Payment>> {
    return this.http.get(`${environment.apiBaseUrl}/payments/${userId}`).pipe(
      map((data: PaginatedResults<Payment>) => {
        data.results = data.results.map((item: Payment) => plainToClass(Payment, item));
        return data;
      }),
    );
  }

  public updateEscrow(paymentId: string, days: number): Observable<Payment> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/${paymentId}/escrow/${days}`, {})
      .pipe(map((data: Payment) => plainToClass(Payment, data)));
  }

  public requestRefund(jobApplicationId: string, paymentId: string, reason: string): Observable<Payment> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/${paymentId}/requestRefund`, {
        jobApplicationId,
        reason,
      })
      .pipe(map((data: Payment) => plainToClass(Payment, data)));
  }

  public getSettlementsForUser(userId: string): Observable<ListResults<SettlementDTO>> {
    return this.http.get(`${environment.apiBaseUrl}/payments/${userId}/balance`).pipe(
      map((data: ListResults<SettlementDTO>) => {
        if (data?.data != null) {
          data.data = data.data.map((item: SettlementDTO) => plainToClass(SettlementDTO, item));
        }
        return data;
      }),
    );
  }

  public getSettlementSpecifications(userId: string, settlementUid: string): Observable<ListResults<SettlementRowDTO>> {
    return this.http.get(`${environment.apiBaseUrl}/payments/${userId}/balance/specification/${settlementUid}`).pipe(
      map((data: ListResults<SettlementRowDTO>) => {
        if (data?.data != null) {
          data.data = data.data.map((item: SettlementRowDTO) => plainToClass(SettlementRowDTO, item));
        }
        return data;
      }),
    );
  }

  public getTransactionsForUser(userId: string): Observable<ListResults<TransactionDTO>> {
    return this.http.get(`${environment.apiBaseUrl}/payments/${userId}/transactions`).pipe(
      map((data: ListResults<TransactionDTO>) => {
        if (data?.data != null) {
          data.data = data.data.map((item: TransactionDTO) => plainToClass(TransactionDTO, item));
        }
        return data;
      }),
    );
  }

  public getPaymentStatus(payment_uid: string): Observable<{ status: string }> {
    return this.http.get<{ status: string }>(`${environment.apiBaseUrl}/payments/${payment_uid}/status`);
  }

  public getRefunds(
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<Payment>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/payments/refunds`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Payment>) => {
          data.results = data.results.map((item: Payment) => plainToClass(Payment, item));
          return data;
        }),
      );
  }

  updateRefunds(paymentId: string, refundState: string): Observable<Payment> {
    return this.http
      .post(`${environment.apiBaseUrl}/payments/${paymentId}/refundPayment`, {
        refundState,
      })
      .pipe(map((data: Payment) => plainToClass(Payment, data)));
  }

  public getAllPayments(
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'created',
    orderByDirection: string = 'desc',
  ): Observable<PaginatedResults<Payment>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/payments`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Payment>) => {
          data.results = data.results.map((item: Payment) => plainToClass(Payment, item));
          return data;
        }),
      );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async downloadCSV(paymentId: string): Promise<object> {
    return this.http.post(`${environment.apiBaseUrl}/payments/${paymentId}/csv`, {}).toPromise();
  }
}
