<div *ngIf="isSubmitted else jobForm;" class="job-request-card d-flex align-items-center flex-column">
  <div class="title" translate>Thank you for your submission</div>
  <div class="desc" translate>Your request has been received and is being reviewed.</div>
  <div class="desc" translate>We will contact you with further information as soon as possible.</div>
  <div class="close-btn">
    <app-solid-button (btnClick)="closeModal()" type="button" buttonStyle="purple-blue">
      {{ "Close" | translate }}
    </app-solid-button>
  </div>
</div>

<ng-template #jobForm>
  <div class="job-request-card d-flex align-items-center flex-column">
    <div class="title" translate>What kind of Talent are you looking for?</div>
    <form (ngSubmit)="submitJob()" [formGroup]="form" class="w-100 d-flex flex-column align-items-center">
      <div class="w-100">
        <input type="text" class="form-control" formControlName="name" placeholder="{{ 'Your name' | translate }}" />
        <input type="email" class="form-control" formControlName="email" placeholder="{{ 'Your email' | translate }}" />
        <input
          type="text"
          class="form-control"
          formControlName="phonenumber"
          placeholder="{{ 'Your phone number' | translate }}" />
      </div>
      <div class="d-flex flex-column w-100 description-container">
        <label translate>Description of the job</label>
        <textarea
          formControlName="message"
          rows="10"
          placeholder="{{ 'I\'m looking for a male actor for a commercial. Fee Rp8.000.000 incl. Buy-out, online, 3 months. Shootdate 1 January in Jakarta' | translate }}"></textarea>
      </div>
      <div class="submit-btn">
        <app-solid-button type="submit" buttonStyle="purple-blue" [isDisabled]="form.invalid" [isBusy]="isBusy">
          {{ "Submit" | translate }}
        </app-solid-button>
      </div>
    </form>
  </div>
</ng-template>

<button mat-button mat-dialog-close>&times;</button>
