import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobApplicationStatus } from '@core/interfaces';
import { Job, JobApplication, Language } from '@core/models';
import { JobApplicationsService } from '@core/services';
import { CreatePayment, InitiateChat, RequestTalentVideoForJobApplication, UpdateEscrow } from '@core/states';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { RequestRefundJobApplicationComponent } from '@shared/components/job-applications/request-refund/request-refund.component';
import { MessagingModalComponent } from '@src/ui/messaging/components/messaging-modal/messaging-modal.component';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { MobileCancelBookingModalComponent } from '../mobile-cancel-booking-modal/mobile-cancel-booking-modal.component';
import moment from 'moment';
import { MobileDetailTravelCostModalComponent } from '../mobile-detail-travel-cost-modal/mobile-detail-travel-cost-modal.component';
import { Navigate } from '@ngxs/router-plugin';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileRequestBookModalComponent } from '../mobile-request-book-modal/mobile-request-book-modal.component';
import { MobileRequestVideoModalComponent } from '../mobile-request-video-modal/mobile-request-video-modal.component';
import { tap } from 'rxjs/operators';
import { MobileSuccessModalComponent } from '../mobile-success-modal/mobile-success-modal.component';
import { MobileTalentContactModalComponent } from '../mobile-talent-contact-modal/mobile-talent-contact-modal.component';

@Component({
  selector: 'app-mobile-talent-aplicant-card',
  templateUrl: './mobile-talent-aplicant-card.component.html',
  styleUrls: ['./mobile-talent-aplicant-card.component.scss'],
})
export class MobileTalentAplicantCardComponent implements OnInit, AfterViewInit {
  @Input()
  public title: string;

  @Input()
  public job: Job;

  @Input()
  public applicants: JobApplication[];

  @Output()
  public applicantSelected = new EventEmitter<any>();

  @Output()
  public refreshApplicantList = new EventEmitter<any>();

  @Output()
  public applicantFullDataSelected = new EventEmitter<{
    applicant: JobApplication;
    checked: boolean;
  }>();

  @ViewChild('cancelBookingModal') private cancelBookingModal: MobileCancelBookingModalComponent;
  @ViewChild('rejectApplicant') private rejectApplicant: MobileCancelBookingModalComponent;
  @ViewChild('travelCostDetail') private travelCostDetail: MobileDetailTravelCostModalComponent;
  @ViewChild('requestToBookModal') private requestToBookModal: MobileRequestBookModalComponent;
  @ViewChild('requestVideoModal') private requestVideoModal: MobileRequestVideoModalComponent;
  @ViewChild('requestTravelCost') private requestTravelCost: MobileSuccessModalComponent;
  @ViewChild('contactModal') private contactModal: MobileTalentContactModalComponent;

  public dataApplicantSelected: Array<{ checked: boolean; id: string }> = [];
  public requestedRefund = false;
  public cancelBookingTitle = '';
  public applicantDetail: JobApplication | null = null;

  protected readonly JobApplicationStatus = JobApplicationStatus;

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public jobApplicationService: JobApplicationsService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.route.queryParamMap.pipe(tap()).subscribe((params) => {
      if (
        params.get('action') === 'travelCost' &&
        this.applicants.find((res) => res.id === params.get('applicantID')) !== undefined
      ) {
        this.travelCosts(this.applicants.find((res) => res.id === params.get('applicantID')));
      }
    });
  }

  public statusCheckedPerApplicant(applicant: JobApplication): boolean {
    return this.dataApplicantSelected.find((res) => res.id === applicant.id)?.checked;
  }

  public statusCheckedAllApplicant(applicants: JobApplication[]): boolean {
    return applicants.filter((applicant) => {
      if (!this.dataApplicantSelected.find((res) => res.id === applicant.id)?.checked) {
        return applicant;
      }
    }).length > 0
      ? false
      : true;
  }

  public applicantChecked(event: any, applicant: JobApplication) {
    this.applicantSelected.emit({ checked: event.target.checked, id: applicant.id });
    this.applicantFullDataSelected.emit({ applicant, checked: event.target.checked });
    this.dataApplicantSelected.push({ checked: event.target.checked, id: applicant.id });
    this.pushDataSelected(event.target.checked, applicant.id);
  }

  public applicantCheckedAll(event: any, applicants: JobApplication[]) {
    applicants.map((val) => {
      this.applicantSelected.emit({ checked: event.target.checked, id: val.id });
      this.applicantFullDataSelected.emit({ applicant: val, checked: event.target.checked });
      this.pushDataSelected(event.target.checked, val.id);
    });
  }

  public haveUrgentMessage(applicant: JobApplication): boolean {
    const needVideo: boolean = this.job.requestTalentVideo;
    const hasUploadedVideo: boolean = typeof applicant.talentVideoId === 'string';
    const talentRejectBooking: boolean = applicant.status === JobApplicationStatus.rejectbooking;
    const talentCancelBooking: boolean = applicant.status === JobApplicationStatus.cancelbooking;
    const casterCancelBooking: boolean = applicant.status === JobApplicationStatus.castercancelbooking;
    const refunded: boolean = applicant.status === JobApplicationStatus.refunded;
    const booked: boolean = applicant.status === JobApplicationStatus.confirmed;

    return (
      (needVideo && hasUploadedVideo) ||
      talentRejectBooking ||
      talentCancelBooking ||
      casterCancelBooking ||
      refunded ||
      booked
    );
  }

  public urgentMessage(applicant: JobApplication): string {
    const needVideo: boolean = this.job.requestTalentVideo;
    const hasUploadedVideo: boolean = typeof applicant.talentVideoId === 'string';
    const talentRejectBooking: boolean = applicant.status === JobApplicationStatus.rejectbooking;
    const talentCancelBooking: boolean = applicant.status === JobApplicationStatus.cancelbooking;
    const casterCancelBooking: boolean = applicant.status === JobApplicationStatus.castercancelbooking;
    const refunded: boolean = applicant.status === JobApplicationStatus.refunded;
    const booked: boolean = applicant.status === JobApplicationStatus.confirmed;

    if (needVideo && hasUploadedVideo) {
      return 'New Video';
    } else if (talentCancelBooking) {
      return 'Talent canceled booking';
    } else if (talentRejectBooking) {
      return 'Talent rejected booking';
    } else if (casterCancelBooking) {
      return 'You canceled this booking';
    } else if (refunded) {
      return 'Refunded';
    } else if (booked) {
      return 'Booked';
    } else {
      return '';
    }
  }

  public nativeLanguage(applicant: JobApplication): Language {
    return applicant.user.languages.filter((userLanguage) => userLanguage.native)?.[0];
  }

  public showVideoButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return applicant.talentVideoId !== null && this.job.requestTalentVideo;
  }

  public showRequestedTravelCosts(applicant: JobApplication): boolean {
    if (!applicant || !this.job) {
      return false;
    }

    return (
      applicant.tcEmailTalentSent &&
      ![
        JobApplicationStatus.travelCostPending,
        JobApplicationStatus.travelCostApproved,
        JobApplicationStatus.travelCostRejected,
      ].includes(applicant.status)
    );
  }

  public showRequestToBookWithPaymentButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      [JobApplicationStatus.applied, JobApplicationStatus.rejected].includes(applicant.status) &&
      !this.job.bypassPayment
    );
  }

  public showRequestToBookBypassPaymentButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      [JobApplicationStatus.applied, JobApplicationStatus.rejected].includes(applicant.status) && this.job.bypassPayment
    );
  }

  public showSendTravelCostsRequest(applicant: JobApplication): boolean {
    if (!applicant || !this.job) {
      return false;
    }

    return (
      !applicant.tcEmailTalentSent &&
      [JobApplicationStatus.confirmed, JobApplicationStatus.completed].includes(applicant.status) &&
      !this.job.completed &&
      !(moment(this.job.lastDate.end) < moment())
    );
  }

  public async viewTalentVideo(applicant: JobApplication): Promise<void> {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        media: applicant.talentVideo,
        width: 400,
        height: 250,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async openChatModal(applicant: JobApplication): Promise<void> {
    const senderId = this.job.user.id;
    const recipientId = applicant.user.id;
    if (recipientId != null) {
      await this.store.dispatch(new InitiateChat(senderId, recipientId)).toPromise();
    }

    const dialogRef = this.dialog.open(MessagingModalComponent, {
      data: {
        userId: this.job.user.id,
        recipientId: applicant.user.id,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      return;
    }
  }

  public async requestToBook(applicant: JobApplication): Promise<void> {
    this.requestToBookModal.openDialog(applicant, this.job);
  }

  public showCreatePaymentButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      [JobApplicationStatus.offered, JobApplicationStatus.accepted].includes(applicant.status) &&
      applicant.payment?.paymentStatus !== 'reserved' &&
      !this.job.bypassPayment
    );
  }

  public showReleasePaymentButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job) {
      return false;
    }

    return (
      !this.requestedRefund &&
      !applicant.payment?.refundRequested &&
      applicant.status === JobApplicationStatus.open &&
      applicant.payment?.paymentStatus === 'reserved'
    );
  }

  public showRequestRefundButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job) {
      return false;
    }

    return (
      !this.requestedRefund &&
      !applicant.payment?.refundRequested &&
      [
        JobApplicationStatus.offered,
        JobApplicationStatus.open,
        JobApplicationStatus.cancelbooking,
        JobApplicationStatus.rejectbooking,
        JobApplicationStatus.castercancelbooking,
      ].includes(applicant.status) &&
      applicant.payment?.paymentStatus === 'reserved'
    );
  }

  public async requestRefundPayment(applicant: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(RequestRefundJobApplicationComponent, {
      data: {
        jobApplication: applicant,
        payment: applicant.payment,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();
    if (result === true) {
      this.requestedRefund = true;
    }
  }

  public async releasePayment(applicant: JobApplication, days: number): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to release the payment?').toPromise();
    if (confirm(translation)) {
      this.store.dispatch(new UpdateEscrow(applicant.user.id, applicant.payment.id, days));
    }
  }

  public createPayment(applicant: JobApplication): void {
    const returnUrl = `${environment.baseUrl}/account/jobs/status/open`;
    this.store.dispatch(new CreatePayment(applicant.user.id, applicant.id, returnUrl));
  }

  public async requestToBookAndCreatePayment(applicant: JobApplication): Promise<void> {
    this.requestToBookModal.openDialog(applicant, this.job);
  }

  public showRejectApplicantButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return applicant.status === JobApplicationStatus.applied;
  }

  public async reject(applicant: JobApplication): Promise<void> {
    this.rejectApplicant.openDialog([applicant]);
  }

  public showCancelBookingButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return [JobApplicationStatus.offered, JobApplicationStatus.confirmed, JobApplicationStatus.open].includes(
      applicant.status,
    );
  }

  public showCancelRequestBookingButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return [JobApplicationStatus.accepted, JobApplicationStatus.requesttobook].includes(applicant.status);
  }

  public async cancelBooking(applicant: JobApplication): Promise<void> {
    this.cancelBookingTitle = this.getCancelBookingTitle(applicant);
    this.cancelBookingModal.openDialog([applicant]);
  }

  public showRequestVideoButton(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      this.job.requestTalentVideo === true &&
      applicant.casterRequestedVideo === null &&
      applicant.talentVideoId === null &&
      !this.showVideoRequestedInfo(applicant)
    );
  }

  public showVideoRequestedInfo(applicant: JobApplication): boolean {
    if (!applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return applicant.casterRequestedVideo !== null && applicant.talentVideoId === null;
  }

  public requestTalentVideo(applicant: JobApplication): void {
    this.store.dispatch(new RequestTalentVideoForJobApplication(applicant.id));
    this.requestVideoModal.openDialog();
  }

  public async requestTravelCosts(applicant: JobApplication): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to request travel costs?').toPromise();
    if (confirm(translation)) {
      await this.jobApplicationService.requestTravelCosts(applicant.id).toPromise();
      this.requestTravelCost.openDialog();
      this.refreshApplicantList.emit('');
    }
  }

  public travelCosts(applicant: JobApplication): void {
    this.travelCostDetail.openDialog(applicant);
  }

  public userDetail(applicant: JobApplication) {
    this.router.navigateByUrl(`/talents/profile/${applicant.user.id}/${applicant.user.id}`);
  }

  public viewContactDetail(applicant: JobApplication) {
    this.applicantDetail = applicant;
    this.contactModal.openDialog();
  }

  private getCancelBookingTitle(applicant: JobApplication): string {
    return [JobApplicationStatus.accepted, JobApplicationStatus.requesttobook].includes(applicant.status)
      ? 'Cancel booking request'
      : 'Cancel confirmed booking';
  }

  private pushDataSelected(checked: boolean, id: string) {
    const dataSelected = this.dataApplicantSelected.filter((res) => res.id !== id);
    dataSelected.push({ checked, id });
    this.dataApplicantSelected = dataSelected;
  }
}
