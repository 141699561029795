<app-mobile-modal-dialog #appProfileRecomendationModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div style="max-height: calc(100vh - 130px) !important" class="modal-body talent-recomendation-modal-container">
    <p>Recomended by:</p>
    <div class="talent-recomendation-list">
      <div class="talent-user" (click)="viewUserDetail(referral)" *ngFor="let referral of referralList">
        <div class="recomended-thumbnail" [class.enable-border]="!referral.user.photo?.id">
          <app-mobile-image-preview
            [image]="referral.user.photo?.id ? getUserPhoto(referral.user.photo?.id) : '/assets/img/mobile/profile.svg'"></app-mobile-image-preview>
        </div>
        <p>{{ referral.user?.firstName }}</p>
      </div>
    </div>
    <button (click)="closeDialog()">Close</button>
  </div>
</app-mobile-modal-dialog>
