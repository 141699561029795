<div class="job-header sticky-top">
  <div class="title" (click)="openDetailJob()">
    <p>{{jobDetail.title | truncate: 90:true}}</p>
    <p>
      {{ jobDetail.rate + jobDetail.extraFees | customCurrency }} | {{ jobDetail.firstDate | dateRange: { exact:
      jobDetail.exactDate, withTime: false, dateFormatStartDate:"DD MMM", dateFormat: "DD MMM 'YY"} }} |
      <ng-container *ngFor="let jobCountry of jobDetail?.countries; let i = index">
        <ng-container *ngIf="i >= 1">, {{ jobCountry.city }}, {{ jobCountry.country.name }}</ng-container>
        <ng-container *ngIf="i == 0">{{ jobCountry.city }}, {{ jobCountry.country.name }}</ng-container>
      </ng-container>
    </p>
  </div>
  <button class="btn" (click)="openDialog()"><img src="/assets/img/mobile/edit.svg" alt="" /></button>
</div>
<ng-content></ng-content>
<app-mobile-job-detail #detailJobModal (userWillPromoteJob)="userWillPromoteJob($event)"></app-mobile-job-detail>
<app-mobile-modal-dialog #optionsModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="modal-body">
    <div class="text-center">
      <button class="btn btn-transprent" (click)="editJob()">
        <img src="/assets/img/mobile/edit.svg" alt="" />
        {{'Edit job' | translate}}
      </button>
    </div>
    <div class="text-center" *ngIf="jobDetail.enableNotification && !jobDetail.completed">
      <button class="btn btn-transprent" (click)="notificationJob()">
        <img src="/assets/img/mobile/mail-disabled.svg" alt="" />
        {{'Disable email applicants' | translate}}
      </button>
    </div>
    <div class="text-center" *ngIf="!jobDetail.enableNotification && !jobDetail.completed">
      <button class="btn btn-transprent" (click)="notificationJob()">
        <img src="/assets/img/mobile/mail-disabled.svg" alt="" />
        {{'Enable email applicants' | translate}}
      </button>
    </div>
    <!-- <div class="text-center">
      <button class="btn btn-transprent" (click)="duplicateJob()">
        <img src="/assets/img/mobile/duplicate.svg" alt="" />
        {{'Duplicate job' | translate}}
      </button>
    </div> -->
    <div class="text-center">
      <button class="btn btn-transprent" (click)="deleteJob()">
        <img src="/assets/img/mobile/trash.svg" alt="" />
        {{'Delete job' | translate}}
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-block" (click)="closeDialog()">{{'Close' | translate}}</button>
  </div>
</app-mobile-modal-dialog>
