import { Component, Input } from '@angular/core';
import { IChat } from '@core/interfaces';
import { User } from '@core/models';
import { ModalService } from '@src/modal/services/modal.service';

@Component({
  selector: 'app-message-header',
  templateUrl: './message-header.component.html',
  styleUrls: ['./message-header.component.scss'],
})
export class MessageHeaderComponent {
  @Input()
  public chat: IChat;

  @Input()
  public user: User;

  constructor(private modalService: ModalService) {}

  public showProfile(): void {
    if (!!this.user.profiles) {
      this.modalService.routeTo(['profile', this.user.id, this.user.profiles[0].id]);
    }
  }
}
