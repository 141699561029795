<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #mobileCreditModal>
  <ng-container *ngIf="isSuccess; else showPackage">
    <div class="modal-body">
      <app-mobile-success [message]="'The payment was successful.'">
        <p class="text-center">{{packageSelected?.amount}} {{'credits have been added to your account' | translate}}</p>
      </app-mobile-success>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-container>
  <ng-template #showPackage>
    <ng-container *ngIf="jobQuota !== undefined">
      <ng-container *ngIf="jobQuota.available > 0 || jobQuota.unlimited; else upgradeMembership">
        <div class="modal-body">
          <h6 class="text-bold text-center mb-3" style="font-size: 26px">
            {{'YourCreditsMobile' | translate: { credits: jobQuota.available } }}
          </h6>
          <p class="text-center" style="font-style: italic" translate>
            Purchase more to apply for additional jobs and enhance your chances of success.
          </p>
          <div class="text-center">
            <ng-container *ngIf="creditPackages$ | async as creditPackages">
              <button
                *ngFor="let creditPackage of creditPackages;"
                type="button"
                (click)="buyCredit(creditPackage)"
                class="btn-extra btn-bg-purple mb-3">
                {{'BuyCredit' | translate: { amount: creditPackage.amount, price: creditPackage.price | number :
                '1.0-0'} }}
              </button>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <div class="wrap-btn mb-2">
            <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</app-mobile-modal-dialog>
