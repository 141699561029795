<app-mobile-modal-dialog #appMembershipConfirmation [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="membership-confirmation-container" *ngIf="membership && oldMembership && freeMembership">
    <h2 translate>Change membership?</h2>

    <ng-container *ngIf="!isShowWarningMessage">
      <p class="my-4 text-center h5" translate *ngIf="membership.price_month > 0">
        <span *ngIf="user.membership?.interval !== 'year' && user.membership?.interval !== 'month'">
          Make your choice.
        </span>
        <ng-container *ngIf="!isUpgradeMembership() && !isDowngradeMembership()">
          <span>
            Your current membership is on a
            <br />
            {{user.membership?.interval}}ly plan. Next bill {{ user.membership.next_bill_date | date }}
          </span>
          <br />
          <br />
        </ng-container>
        <span>Membership is not valid for already applied jobs</span>
      </p>

      <p class="my-4" translate *ngIf="membership.price_month == 0" translate>
        Click the button below to cancel your current subscription, and return to the free membership.
      </p>

      <ng-container *ngIf="membership.price_month > 0">
        <button
          *ngIf="oldMembership.id == membership.id && oldMembership.interval == 'month'"
          [disabled]="true"
          class="mb-3 outline-button">
          <div
            class="px-2"
            [innerHTML]="'currentMonthMembership' | translate: { price_month: membership.price_month | number : '1.0-0' }"></div>
        </button>
        <button
          *ngIf="!(oldMembership.id == membership.id && oldMembership.interval == 'month')"
          (click)="accept('month')"
          [disabled]="isBusy"
          [isBusy]="isMonthlyButtonProcessing"
          class="mb-3 outline-button">
          <div
            class="px-2"
            [innerHTML]="'newMonthMembership' | translate: { price_month: membership.price_month | number : '1.0-0' }"></div>
        </button>
        <button
          *ngIf="oldMembership.id == membership.id && oldMembership.interval == 'year'"
          [disabled]="true"
          buttonStyle="purple"
          class="mb-3 purple-button">
          <div
            class="px-2"
            [innerHTML]="'currentYearMembership' | translate: { price_year: membership.price_year | number : '1.0-0' }"></div>
        </button>
        <button
          *ngIf="!(oldMembership.id == membership.id && oldMembership.interval == 'year')"
          (click)="accept('year')"
          [disabled]="isBusy"
          [isBusy]="isYearlyButtonProcessing"
          class="mb-3 purple-button">
          <div
            class="px-2"
            [innerHTML]="'newYearMembership' | translate: { price_year: membership.price_year| number : '1.0-0' }"></div>
        </button>
      </ng-container>

      <ng-container *ngIf="membership.price_month == 0">
        <button (click)="accept('month')" class="outline-button mb-3">
          <div class="px-2" translate>Change to Free membership</div>
        </button>
      </ng-container>

      <ng-container *ngIf="sameMembership">
        <button (click)="cancelMembership()" class="black-button" translate>Cancel membership</button>
      </ng-container>

      <button class="mt-8 transparent-button" (click)="closeDialog(false)">Close</button>
    </ng-container>

    <ng-container *ngIf="isShowWarningMessage">
      <ng-container *ngIf="isDowngradeMembership() && !isIntervalChange()">
        <p class="my-4" translate>Are you sure you want to change your membership?</p>
        <p translate>
          Please note that changing your membership to a lower level will reduce your job reaction, portfolio photo and
          video quota as well.
        </p>
        <p translate>
          By changing your membership, you agree to update your payment amount accordingly. Do you want to proceed?
        </p>
      </ng-container>

      <ng-container *ngIf="(isUpgradeMembership() || isChangeToDiscount()) && !isIntervalChange()">
        <p class="my-4" translate>
          By upgrading your membership, you agree to update your payment amount accordingly. Do you want to proceed?
        </p>
      </ng-container>

      <ng-container *ngIf="isIntervalChange()">
        <p class="mt-4" *ngIf="isDowngradeMembership()" translate>
          Please note that changing your membership to a lower level will reduce your job reaction, portfolio photo and
          video quota as well.
        </p>
        <p [ngClass]="{ 'mt-4': !isDowngradeMembership() }" translate>
          By changing your membership, you agree to update your payment amount accordingly. Do you want to proceed?
        </p>
      </ng-container>
      <button
        (click)="processMembership(selectedInterval)"
        class="purple-button mt-5 mb-3 custom-width-button"
        [isBusy]="isBusy">
        <div class="px-2" translate>Yes</div>
      </button>

      <button (click)="closeModal()" class="black-button custom-width-button">
        <div class="px-2" translate>No</div>
      </button>
    </ng-container>
  </div>
</app-mobile-modal-dialog>
<app-mobile-success-modal #accountMembershipNotification>
  <app-mobile-success [message]="updateMembershipMessage"></app-mobile-success>
</app-mobile-success-modal>
