import { Component, Input, OnInit } from '@angular/core';
import { User } from '@core/models';
import { MatDialog } from '@angular/material/dialog';
import { ReportUserModalComponent } from '@src/visitor/modals/components/report-user-modal/report-user-modal.component';
import { JoinModalComponent } from '@src/visitor/modals/components/join-modal/join-modal.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from '@core/states';

@Component({
  selector: 'app-talent-action-buttons',
  templateUrl: './talent-action-buttons.component.html',
  styleUrls: ['./talent-action-buttons.component.scss'],
})
export class TalentActionButtonsComponent implements OnInit {
  @Input()
  public talent: User;

  @Select(UserState.user)
  public user$: Observable<User>;

  public loggedIn: boolean;

  constructor(private dialog: MatDialog) {
    this.user$.subscribe((user) => {
      this.loggedIn = user != null;
    });
  }

  ngOnInit(): void {}

  async openReportUser(): Promise<void> {
    if (!this.loggedIn) {
      this.dialog.open(JoinModalComponent, {});
      return;
    }

    const dialogRef = this.dialog.open(ReportUserModalComponent, {
      data: {
        talentId: this.talent.id,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();
  }
}
