import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import * as moment from 'moment-timezone';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { environment } from '@environments/environment';
import { AuthModule } from '@auth/auth.module';
import { TranslatePoHttpLoader } from '@src/app.translate-po-loader';
import { SharedModule } from '@shared/shared.module';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {
  CategoriesService,
  CountriesService,
  DecksService,
  DiscountCodesService,
  EmailsService,
  FeeTypesService,
  FiltersService,
  GeoLocationsService,
  JobsService,
  LanguagesService,
  MediaService,
  MembershipsService,
  MessagingService,
  NotificationsService,
  PackagesService,
  PaymentsService,
  RegionService,
  ResizeService,
  RouterHelperService,
  SseService,
  TransactionsService,
  UserReportsService,
  UsersService,
  EmbedVideoService,
  CreditService,
} from './services';

import * as allStates from './states';
import { CasterGuard } from './guards/caster-guard';
import { TalentGuard } from './guards/talent-guard';
import { AdminGuard } from './guards/admin-guard';
import { AccountGuard } from './guards/account-guard';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { createErrorHandler } from '@sentry/angular';
import localeNl from '@angular/common/locales/nl';
import localeID from '@angular/common/locales/id';
import { UnsubscribeComponent } from '@core/helpers';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  position: 'bottom-left',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#5f43f0',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#e8963d',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  content: {
    href: '/about/privacy-policy',
  },
  type: 'opt-in',
};

registerLocaleData(environment.locale === 'ID' ? localeID : localeNl);
moment.tz.setDefault('utc');

const states = [
  allStates.AuthState,
  allStates.CategoriesState,
  allStates.CountriesState,
  allStates.DiscountCodesState,
  allStates.DecksState,
  allStates.EmailsState,
  allStates.FiltersState,
  allStates.FeeTypesState,
  allStates.GeocoderState,
  allStates.InvoicesState,
  allStates.JobsState,
  allStates.LanguagesState,
  allStates.MediaState,
  allStates.MembershipsState,
  allStates.MessagingState,
  allStates.NotesState,
  allStates.NotificationsState,
  allStates.PackagesState,
  allStates.PaymentsState,
  allStates.ProfilesState,
  allStates.RegionState,
  allStates.ReviewsState,
  allStates.SearchState,
  allStates.SseState,
  allStates.SignUpState,
  allStates.TalentsState,
  allStates.TransactionsState,
  allStates.UserReportsState,
  allStates.UserState,
  allStates.UsersState,
  allStates.VerificationsState,
  allStates.VerifyEmailState,
  allStates.VerifyMobileState,
  allStates.VerifyVatState,
  allStates.JobRequestsState,
  allStates.CreditState,
];

@NgModule({
  declarations: [UnsubscribeComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgxsModule.forRoot(states, { developmentMode: !environment.production }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KDRVWNR',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslatePoHttpLoader => new TranslatePoHttpLoader(http, 'assets/i18n', '.po'),
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot({
      loginUrl: `${environment.apiBaseUrl}/auth/login`,
      verifyUrl: `${environment.apiBaseUrl}/auth/verify`,
      refreshUrl: `${environment.apiBaseUrl}/auth/refresh`,
      roles: {},
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FontAwesomeModule,
    SharedModule,
  ],
  providers: [
    CasterGuard,
    TalentGuard,
    AdminGuard,
    AccountGuard,
    EmailsService,
    CategoriesService,
    CountriesService,
    FeeTypesService,
    FiltersService,
    GeoLocationsService,
    JobsService,
    LanguagesService,
    MediaService,
    MembershipsService,
    MessagingService,
    NotificationsService,
    PaymentsService,
    PackagesService,
    RegionService,
    RouterHelperService,
    SseService,
    TransactionsService,
    UserReportsService,
    UsersService,
    ResizeService,
    EmbedVideoService,
    CreditService,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'IDR' },
    { provide: 'googleTagManagerId', useValue: 'GTM-KDRVWNR' },
    { provide: LOCALE_ID, useValue: 'id-ID' },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
  ],
})
export class CoreModule {
  public constructor(private library: FaIconLibrary) {
    this.library.addIcons(icons.faArrowDown, icons.faArrowUp, icons.faArrowsAltV);
  }
}
