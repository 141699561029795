import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { HideChat } from '@core/states';
import { IChat } from '@core/interfaces';
import { User } from '@core/models';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
})
export class ChatItemComponent {
  @Input()
  public chat: IChat;

  @Input()
  public user: User;

  @Input()
  public activeChat: IChat;

  public hover: boolean;

  public constructor(private store: Store) {
    this.hover = false;
  }

  public chatDelete(chat: IChat): void {
    if (confirm('Are you sure you want to hide this chat?')) {
      this.store.dispatch(new HideChat(chat));
    }
  }
}
