import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IMedia, IUserAlbum } from '@core/interfaces';
import { IUserProfile } from '@core/interfaces/user-profile';
import { Language, User } from '@core/models';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { TalentDetailPageComponent } from '@src/visitor/pages/components/talent-detail-page/talent-detail-page.component';
import { MobileAccountMessagingListComponent } from '../mobile-account-messaging-list/mobile-account-messaging-list.component';
import { Select, Store } from '@ngxs/store';
import { AuthState, InitiateChat, UserState } from '@core/states';
import { Observable } from 'rxjs';
import { RouterHelperService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MobileStorageKey, MobileStorageService } from '../services/mobile-storage.service';

@Component({
  selector: 'app-mobile-talent-card',
  templateUrl: './mobile-talent-card.component.html',
  styleUrls: ['./mobile-talent-card.component.scss'],
})
export class MobileTalentCardComponent implements OnInit, OnChanges {
  @ViewChild('talentsElement') public talentsElement: ElementRef;

  @Input()
  public talents: IUserProfile[];

  @Select(UserState.user)
  public user$: Observable<User>;

  public scrollToUser = '';

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private el: ElementRef,
    private router: Router,
    private mobileStorage: MobileStorageService,
  ) {}

  ngOnInit(): void {
    const modalParam = this.activatedRoute.snapshot.queryParamMap.get('goTo');
    if (modalParam) {
      this.scrollToUser = modalParam;
      setTimeout(() => {
        const myElement = this.el.nativeElement.querySelector('#selectedTalent');
        myElement.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'nearest' });
      }, 100);

      // removing param on url immediatly
      const queryParams: Params = {};
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
      });
    }
  }

  public get activeRole(): string {
    return this.mobileStorage.get(MobileStorageKey.activeRole);
  }

  public get authenticated(): boolean {
    return this.store.selectSnapshot(AuthState.authenticated);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('talents' in changes) {
    }
  }

  public nativeLanguage(userProfile: IUserProfile): Language {
    return userProfile.user.languages.filter((userLanguage) => userLanguage.native)?.[0];
  }

  public isHaveVideo(userProfile: IUserProfile): IMedia {
    const userAlbum = userProfile.albums.filter((userMedia) => {
      if (userMedia.albumType === 'video' && userMedia.media.length >= 0 && userMedia.media[0]?.link !== null) {
        return userMedia.media;
      }
    })?.[0];
    if (userAlbum !== undefined && userAlbum.media.length > 0) {
      return userAlbum.media[0];
    }
  }

  public playVideo(media: IMedia) {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        mediaId: undefined,
        media,
        width: 400,
        height: 250,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async viewProfile(userId, profileId): Promise<void> {
    this.store.dispatch(new Navigate(['talents/profile', userId, profileId]));
  }

  public async sendMessage(recipientId, senderId): Promise<void> {
    await this.store.dispatch(new InitiateChat(senderId, recipientId)).toPromise();
    const dialogRef = this.dialog.open(MobileAccountMessagingListComponent, {
      data: {
        userId: senderId,
        recipientId,
      },
    });
  }
}
