import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Chat } from '@core/models/chat.model';
import { Message } from '@core/models/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  public constructor(private http: HttpClient) {}

  public loadChats(): Observable<Chat[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/messaging/chats`)
      .pipe(map((data: Chat[]) => plainToClass(Chat, data)));
  }

  public sendMessage(message: any): Observable<Message> {
    return this.http
      .post(`${environment.apiBaseUrl}/messaging/chat/${message.chatId}/message`, message)
      .pipe(map((data: Message) => plainToClass(Message, data)));
  }

  public initiateChat(senderId: string, recipientId: string): Observable<Chat> {
    return this.http
      .post(`${environment.apiBaseUrl}/messaging/chat`, { senderId, recipientId })
      .pipe(map((data: Chat) => plainToClass(Chat, data)));
  }

  public loadChat(chatId: string): Observable<Chat> {
    return this.http
      .get(`${environment.apiBaseUrl}/messaging/chat/${chatId}`)
      .pipe(map((data: Chat) => plainToClass(Chat, data)));
  }

  public markMessagesAsRead(chatId: string): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/messaging/chat/${chatId}/mark-read`, {});
  }

  public hideChat(chatId: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/messaging/chat/${chatId}/hide`);
  }
}
