<div class="d-flex flex-column w-100 text-white p-5">
  <h2 translate>ACCEPT JOB OFFER?</h2>
  <p translate>Awesome, you are about to accept this job.</p>
  <p translate>Make sure all the information is clear to you about this job.</p>
  <p translate>When accepted, you have a binding agreement with the Caster about this specific assignment.</p>
  <div class="col-12">
    <app-button (click)="acceptJob()" buttonStyle="purple" class="mb-3">
      <div class="px-2" translate>Accept</div>
    </app-button>
    <app-button (click)="closeModal()" buttonStyle="white-outline" class="mb-3">
      <div class="px-2" translate>Cancel</div>
    </app-button>
  </div>
  <p translate>When accepted, the job shows at your accepted jobs in the dashboard</p>
</div>
