import { IChat, IMessage } from '@core/interfaces';

export class InitiateChat {
  public static readonly type = '[Messaging] Initiate chat';
  public constructor(public userId: string, public recipientId: string) {}
}

export class UnsetActiveChat {
  public static readonly type = '[Messaging] Unset active chat';
}

export class MessageReceived {
  public static readonly type = '[Messaging] Message received';
  public constructor(public message: IMessage) {}
}

export class LoadChats {
  public static readonly type = '[Messaging] Load chats';
  public constructor() {}
}

export class MarkMessagesAsRead {
  public static readonly type = '[Messaging] Mark messages as read';
  public constructor(public chat: IChat) {}
}

export class SendMessage {
  public static readonly type = '[Messaging] Send message';
  public constructor(public message: IMessage) {}
}

export class ActivateChat {
  public static readonly type = '[Messaging] Activate chat';
  public constructor(public chatId: string) {}
}

export class HideChat {
  public static readonly type = '[Messaging] Hide chat';
  public constructor(public chat: IChat) {}
}
