import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-invitation-remove-modal',
  templateUrl: './mobile-invitation-remove-modal.component.html',
  styleUrls: ['./mobile-invitation-remove-modal.component.scss'],
})
export class MobileInvitationRemoveModalComponent implements OnInit {
  @Output() public removeAction = new EventEmitter();
  @ViewChild('appRemoveInvitaionModal') private modalComponent: MobileModalDialogComponent;

  constructor() {}

  ngOnInit(): void {}

  async openDialog(): Promise<void> {
    return await this.modalComponent.open().then(() => {});
  }

  public removeInvitation(): void {
    this.removeAction.emit('');
  }

  public closeDialog(): void {
    this.modalComponent.close().then(() => {});
  }
}
