<div class="d-flex flex-column w-100 text-white p-5">
  <p
    class="my-4 h5 text-center"
    [innerHTML]="'You will buy credits' | translate: { amount: creditPackage.amount, price: creditPackage.price | number : '1.0-0' }"></p>

  <div class="d-flex flex-row align-items-center justify-content-center">
    <app-button (click)="closeModal()" buttonStyle="black" class="mb-3">
      <div class="px-2" translate>Cancel</div>
    </app-button>
    <app-button
      (click)="accept()"
      [isDisabled]="isBusy"
      [isBusy]="isBusy"
      buttonStyle="artyclick-purple"
      class="mb-3 ml-2">
      <div class="px-2" translate>Continue and Pay</div>
    </app-button>
  </div>
</div>
