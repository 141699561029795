import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SliderComponent } from '../slider/slider.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-money-slider',
  templateUrl: '../slider/slider.component.html',
  styleUrls: ['../slider/slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneySliderComponent),
      multi: true,
    },
  ],
})
export class MoneySliderComponent extends SliderComponent implements OnChanges {
  @Input()
  public currency = environment.currency;

  @Input()
  public floor = 0;

  @Input()
  public ceil = 500;

  protected defaultOptions = {
    floor: this.floor,
    ceil: this.ceil,
    showSelectionBar: true,
    translate: (value: number) => `${this.currencySymbol} ${value}`,
  };

  private currencySymbol = '€';

  public ngOnChanges(changes: SimpleChanges): void {
    if ('floor' in changes) {
      this.defaultOptions.floor = changes.floor.currentValue;
    }

    if ('ceil' in changes) {
      this.defaultOptions.ceil = changes.ceil.currentValue;
    }
  }
}
