<div class="d-block" style="height:{{ options?.fixedHeight }}px">
  <app-carousel [items]="items" [options]="options" [transition]="transition" class="d-block">
    <ng-template #carouselSlideTpl let-item="item">
      <div class="background d-block w-100 h-100 rounded-sm" style="background-image: url({{ item.image }})">
        <div class="d-flex flex-column w-100 h-100 slide-content">
          <div *ngIf="!item.showLogo" class="row flex-fill align-items-center content mx-md-0 ml-md-5 px-5 mx-3">
            <div style="width: 100%">
              <app-icon
                *ngIf="showQuote"
                class="quote-sign"
                fill="transparent"
                icon="assets/img/quote.svg"
                stroke="white"></app-icon>
              <h1
                [innerHTML]="item.title.lines | translate"
                style="
                  text-align: center;
                  font-size: 28px;
                  margin: 170px auto auto auto;
                  font-family: Inter, sans-serif !important;
                "></h1>

              <div class="d-flex flex-column flex-md-row mt-5">
                <app-button
                  *ngFor="let button of item.buttons"
                  [buttonStyle]="button.buttonStyle"
                  [routerLink]="button.url"
                  [fragment]="button.fragment"
                  class="h5 mb-3 mb-md-0 mr-md-3 shadow-sm">
                  <span class="px-1 px-md-5" translate>{{ button.label }}</span>
                </app-button>
              </div>
            </div>
          </div>

          <div *ngIf="item.showLogo" class="flex-fill align-items-center content mx-3 main">
            <img src="assets/img/hero/logos-black.png" alt="logos" class="ml-2" />
            <h1 class="text-black ml-4 text-center text-md-left">
              <i [innerHTML]="item.title.lines | translate"></i>
            </h1>
          </div>

          <!-- <div *ngIf="!item.showBudgetcamLink" class="ml-auto d-flex mr-3 mb-5">
            <app-button buttonStyle="white-outline" class="h5 outlined-button" (click)="openVideo()">
              <span class="px-5" translate>See talents at work</span>
            </app-button>
          </div> -->

          <div *ngIf="item.showBudgetcamLink" class="ml-auto d-flex mr-3 mb-5">
            <app-button buttonStyle="white-outline" class="h5 outlined-button" (click)="openBudgetcam()">
              <span class="px-5" translate>Bekijk voorwaarden</span>
            </app-button>
          </div>
        </div>
      </div>
    </ng-template>
  </app-carousel>
</div>
