<app-mobile-modal-dialog #appConfirmBookingModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div *ngIf="data !== null && data !== undefined">
    <ng-container *ngIf="!isSuccess else success">
      <form (ngSubmit)="rejectBooking()" [formGroup]="form">
        <div class="modal-body">
          <ng-container *ngIf="data.actionType === 'rejectBooking'">
            <div class="text-center">
              <p class="mb-2" translate>Rejecting a booking may have consequenses for your account</p>
              <p translate>Write a message to the Caster</p>
            </div>
          </ng-container>
          <ng-container *ngIf="data.actionType === 'cancelBooking'">
            <div class="text-center">
              <p class="mb-2" translate>Cancel a booking may have consequenses for your account</p>
              <p translate>Write a message to the Caster</p>
            </div>
          </ng-container>
          <ng-container *ngIf="data.actionType === 'rejectJob'">
            <h6 class="text-bold text-center" translate>Are you sure you want to reject this job?</h6>
          </ng-container>
          <ng-container *ngIf="data.actionType === 'confirmJob'">
            <h6 class="text-center mt-5" style="font-size: 22px" translate>
              Are you sure want to
              <br />
              confirm your application
            </h6>
          </ng-container>
          <ng-container *ngIf="data.actionType === 'cancelJobApplication'">
            <h6 class="text-center mt-5" style="font-size: 22px" translate>
              Are you sure you want to cancel your application?
            </h6>
          </ng-container>
          <ng-container *ngIf="data.actionType === 'acceptJob'">
            <div class="cancel-booking-info">
              <h6 class="mb-2" translate>{{'Accept Job Offer ?' | translate}}</h6>
              <div class="text-center">
                <p translate>*Please note:</p>
              </div>
              <ol>
                <li translate>Awesome, you are about to accept this job.</li>
                <li translate>Make sure all the information is clear to you about this job</li>
                <li translate>
                  When accepted, you have a binding agreement with the Caster about this specific assignment
                </li>
              </ol>
            </div>
          </ng-container>
          <textarea
            *ngIf="data.actionType !== 'rejectJob' && 
            data.actionType !== 'confirmJob' && 
            data.actionType !== 'cancelJobApplication' &&
            data.actionType !== 'acceptJob'"
            formControlName="reason"
            class="textarea-control"
            style="height: 156px !important"
            placeholder="{{'Enter your reason' | translate}}"></textarea>
        </div>
        <div class="modal-footer">
          <div class="wrap-btn mb-2" *ngIf="data.actionType === 'rejectBooking'">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; width: 182px"
              (click)="rejectBooking()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Reject booking' | translate }}</span>
            </button>
          </div>
          <div class="wrap-btn mb-2" *ngIf="data.actionType === 'cancelBooking'">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; width: 182px"
              (click)="cancelBooking()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Cancel booking' | translate }}</span>
            </button>
          </div>
          <div class="wrap-btn mb-2" *ngIf="data.actionType === 'acceptJob'">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; width: 182px"
              (click)="acceptJob()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Accept' | translate }}</span>
            </button>
          </div>
          <div class="wrap-btn mb-2" *ngIf="data.actionType === 'rejectJob'">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; width: 182px"
              (click)="rejectJob()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Reject Job' | translate }}</span>
            </button>
          </div>
          <div class="wrap-btn mb-2" *ngIf="data.actionType === 'confirmJob'">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; width: 182px"
              (click)="confirmJob()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Confirm' | translate }}</span>
            </button>
          </div>
          <div class="wrap-btn mb-2" *ngIf="data.actionType === 'cancelJobApplication'">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; width: 182px"
              (click)="cancelJobApplication()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Cancel application' | translate }}</span>
            </button>
          </div>
          <button type="button" (click)="close()" class="btn btn-block">{{'Cancel' | translate}}</button>
        </div>
      </form>
    </ng-container>
    <ng-template #success>
      <div class="modal-body">
        <app-mobile-success
          *ngIf="data.actionType === 'rejectBooking'"
          [message]="'You have rejected the booking.'"></app-mobile-success>
        <app-mobile-success
          *ngIf="data.actionType === 'cancelBooking'"
          [message]="'You have cancel the booking.'"></app-mobile-success>
        <app-mobile-success
          *ngIf="data.actionType === 'rejectJob'"
          [message]="'You have rejected the job.'"></app-mobile-success>
        <app-mobile-success
          *ngIf="data.actionType === 'cancelJobApplication'"
          [message]="'You have cancel application.'"></app-mobile-success>
        <app-mobile-success
          *ngIf="data.actionType === 'confirmJob'"
          [message]="'Thank you for confirming. You are now booked!'">
          <p class="text-center" translate>
            Your contact details have been send to the Caster. They will inform you details like the Callsheet
          </p>
        </app-mobile-success>
        <app-mobile-success
          *ngIf="data.actionType === 'acceptJob'"
          [message]="'Thank you for confirming. You are now accept job!'">
          <p class="text-center" translate>
            Your contact details have been send to the Caster. They will inform you details like the Callsheet
          </p>
        </app-mobile-success>
      </div>
      <div class="modal-footer">
        <div class="wrap-btn mb-2">
          <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
        </div>
      </div>
    </ng-template>
  </div>
</app-mobile-modal-dialog>
