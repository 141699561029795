import { IUser } from './user';
import { IJobApplication } from './job-application';
import { IJob } from './job';
import { IInvoice } from './invoice';
import { IPaymentType } from './payment-type';
import { CreateTransactionDTO } from '@core/dto';
import { CreditTransaction } from '@core/models/credit-transaction.model';

export enum PaymentStatus {
  cancelled = 'cancelled',
  chargeback = 'chargeback',
  completed = 'completed',
  created = 'created',
  expired = 'expired',
  failed = 'failed',
  pending = 'pending',
  planned = 'planned',
  released = 'released',
  reserved = 'reserved',
  refunded = 'refunded',
  capture = 'capture',
}

export interface IPayment {
  id: string;
  user: IUser;
  jobApplication?: IJobApplication;
  job?: IJob;
  invoice?: IInvoice;
  paymentType: IPaymentType;
  amount: number;
  currency: string;
  paymentDetails?: string;
  transactionDetails?: CreateTransactionDTO;
  paymentStatus?: PaymentStatus;
  transactionUid?: string;
  refundRequested?: boolean;
  refundUid?: string;
  refundStatus?: string;
  bookingsType?: string;
  bookingsNumber?: number;
  creditTransaction?: CreditTransaction;
}
