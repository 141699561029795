import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-membership-modal',
  templateUrl: './mobile-membership-modal.component.html',
  styleUrls: ['./mobile-membership-modal.component.scss'],
})
export class MobileMembershipModalComponent implements OnInit {
  @ViewChild('mobileMembershipModal') public modalComponent: MobileModalDialogComponent;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public async openDialog() {
    return await this.modalComponent.open();
  }

  public goToMembershipPage(): void {
    this.modalComponent.close();
    this.router.navigateByUrl('/account/details/dashboard?openModal=membership');
  }
}
