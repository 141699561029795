import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesModule } from '../generic/images/images.module';
import { IconsModule } from '../generic/icons/icons.module';
import { SendMessageButtonComponent } from './components/send-message-button/send-message-button.component';
import { MessagingModalComponent } from './components/messaging-modal/messaging-modal.component';
import { MessagingListComponent } from './components/messaging-list/messaging-list.component';
import { ButtonsModule } from '../generic/buttons/buttons.module';
import { FormsModule } from '../generic/forms/forms.module';
import { GradientsModule } from '../generic/gradients/gradients.module';
import { ChatItemComponent } from './components/chat-item/chat-item.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SpinnersModule } from '@src/ui/generic/spinners/spinners.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { MessageHeaderComponent } from './components/message-header/message-header.component';
import { ProfilePictureModule } from '@src/ui/profile-picture/profile-picture.module';
import { MessageTimeComponent } from './components/message-time/message-time.component';

const components = [
  SendMessageButtonComponent,
  MessagingModalComponent,
  ChatItemComponent,
  MessagingListComponent,
  MessageHeaderComponent,
  MessageTimeComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    GradientsModule,
    ButtonsModule,
    ImagesModule,
    IconsModule,
    MatDialogModule,
    SpinnersModule,
    PipesModule,
    ProfilePictureModule,
    // MessageTimeComponent,
  ],
  exports: components,
})
export class MessagingModule {}
