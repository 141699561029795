<div [ngClass]="{ active: chat?.id === activeChat?.id }" class="wrapper d-flex p-2 align-items-center">
  <div class="photo-container position-relative d-flex px-0 mr-1">
    <app-profile-picture [src]="user.photo?.thumbUrl"></app-profile-picture>
    <div *ngIf="chat.totalUnread" class="position-absolute notification-dot text-center">
      {{ chat.totalUnread }}{{ chat.totalUnread > 99 ? "+" : "" }}
    </div>
  </div>
  <div class="content-container px-0 d-flex flex-column">
    <div class="profile-name">{{ user.profileName }}</div>
    <div class="message" *ngIf="chat.lastMessageReceived">{{ chat.lastMessageReceived.message }}</div>
    <div class="date" *ngIf="chat.lastMessageReceived">{{ chat.lastMessageReceived.created | dateAgo }}</div>
  </div>

  <div class="px-0 button-container d-flex col-2">
    <app-trashbin (click)="chatDelete(chat)"></app-trashbin>
  </div>
</div>
