import { Directive, HostListener, ElementRef, Renderer2, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputPriceFormat]',
})
export class InputPriceFormatDirective implements OnInit {
  constructor(private el: ElementRef, private control: NgControl, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const input = event.target;
    const inputValue = input.value.toString();

    // Remove non-digit characters for processing
    const numericValue = inputValue.replace(/\D/g, '');

    // Format the value
    const formattedValue = this.formatNumber(numericValue);

    // Update the form control with the numeric value
    const numericFormControlValue = parseInt(numericValue, 10);

    if (!isNaN(numericFormControlValue)) {
      this.control.control.setValue(numericFormControlValue, { emitEvent: false });
    }

    // Update the input display value
    this.renderer.setProperty(input, 'value', formattedValue);
  }

  ngOnInit(): void {
    // Format the initial value if it exists
    const initialValue = this.control.control.value;
    if (initialValue) {
      const formattedValue = this.formatNumber(initialValue.toString());
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    }
  }

  formatNumber(value: string): string {
    // Format the number with dots as thousand separators
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
