import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UnleashClient } from 'unleash-proxy-client';

@Injectable({
  providedIn: 'root',
})
export class Unleash {
  unleash: UnleashClient;

  constructor() {
    this.init();
  }

  init() {
    try {
      this.unleash = new UnleashClient({
        url: 'https://unleash.casterbee.co.id/api/frontend',
        clientKey: environment.unleashKey,
        appName: 'default',
      });
    } catch (error) {
      console.log(error);
    }
  }

  start() {
    this.unleash.start();
  }

  public isFlag(flag: string): boolean {
    const defaultCountry = '-id';
    return this.unleash.isEnabled(flag + defaultCountry);
  }
}
