import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Membership, User } from '@core/models';
import { FetchCurrentUser, LoadMemberships, MembershipsState, UserState } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MobileAccountMembershipConfirmationModalComponent } from '../mobile-account-membership-confirmation-modal/mobile-account-membership-confirmation-modal.component';

@Component({
  selector: 'app-mobile-account-membership-modal',
  templateUrl: './mobile-account-membership-modal.component.html',
  styleUrls: ['./mobile-account-membership-modal.component.scss'],
})
export class MobileAccountMembershipModalComponent implements OnInit {
  @ViewChild('membershipConfirmationModal')
  public membershipConfirmationModal: MobileAccountMembershipConfirmationModalComponent;

  @Select(MembershipsState.memberships)
  public memberships$: Observable<Membership[]>;

  @Input() public user: Observable<User>;

  @ViewChild('appMembershipModal') private modalComponent: MobileModalDialogComponent;

  public tempUserData: User;

  public memberships: Membership[];

  public freeMembership: Membership;

  public disableMembership = true;

  constructor(private store: Store) {}

  public async ngOnInit(): Promise<void> {}

  public async openDialog() {
    const userSnapshot = this.store.selectSnapshot(UserState.user);
    if (!userSnapshot) {
      await this.store.dispatch(new FetchCurrentUser()).toPromise();
    }
    if (!this.store.selectSnapshot(MembershipsState.memberships)) {
      this.store.dispatch(new LoadMemberships());
    }

    combineLatest([this.user, this.memberships$])
      .pipe(filter(([user, memberships]) => !!user && !!memberships))
      .subscribe(([user, memberships]) => {
        //hide free versions and old membership models unless the user is subscribed to an old version
        this.tempUserData = user;
        this.memberships = memberships?.filter(
          (m) =>
            (m.id === user.membership.id || !m.hide) &&
            !(user.membership.title.includes(m.title) && user.membership.id !== m.id),
        );

        this.freeMembership = memberships.find((m) => m.price_month === 0 && !m.hide);
      });
    return await this.modalComponent.open().then(() => {});
  }

  public async closeDialog() {
    return await this.modalComponent.close();
  }

  public isFreeMembership(membership: Membership): boolean {
    return membership?.title?.includes('Free');
  }

  public getMembershipLevel(membership: Membership): number {
    return membership?.title?.includes('Premium') ? 3 : membership?.title?.includes('Starter') ? 2 : 1;
  }

  public getButtonPlanLabel(membership: Membership, currentMembership: Membership): string {
    return this.getMembershipLevel(membership) > this.getMembershipLevel(currentMembership)
      ? 'Upgrade plan'
      : 'Downgrade plan';
  }

  public openModal(membership: Membership, oldMembership: Membership): void {
    if (oldMembership.id === membership.id && oldMembership.price_month === 0) {
      return;
    }
    this.membershipConfirmationModal.openDialog({
      membership,
      oldMembership,
      freeMembership: this.freeMembership,
    });
  }

  public get sortedMembership() {
    const currentMembership = [];
    const upgradeMembership = [];
    const downgradeMembership = [];
    this.memberships.forEach((membership) => {
      const buttonLabel = this.getButtonPlanLabel(membership, this.tempUserData['upcomingMembership']);
      if (membership.id === this.tempUserData.membership?.id) {
        currentMembership.push(membership);
      } else {
        switch (buttonLabel) {
          case 'Upgrade plan':
            upgradeMembership.push(membership);
            break;
          case 'Downgrade plan':
            downgradeMembership.push(membership);
            break;

          default:
            break;
        }
      }
    });
    const tempMembershipData = [...currentMembership, ...upgradeMembership, ...downgradeMembership];
    return tempMembershipData;
  }
}
