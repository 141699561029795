import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
})
export class MoneyComponent {
  @Input()
  public amount: number;

  @Input()
  public currency = environment.currency;

  @Input()
  public symbol = environment.currency === 'Rp' ? 'symbol-narrow' : 'symbol';

  @Input()
  public format = '1.0-0';
}
