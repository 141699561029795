import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { PaginatedResults, User } from '@core/models';
import { IUser, UserRole } from '@core/interfaces';
import { JobQuotaDTO } from '@core/dto/job-quota.dto';
import { StorageService } from '@auth/services';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public constructor(private http: HttpClient, private storageService: StorageService) {}

  public findAll(
    query: string,
    role: UserRole,
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<User>> {
    return this.http
      .get<PaginatedResults<User>>(`${environment.apiBaseUrl}/users`, {
        params: {
          q: `${query ?? ''}`,
          role: `${role ?? ''}`,
          page: `${page}`,
          limit: `${limit}`,
          orderBy: `${orderBy}`,
          orderByDirection: `${orderByDirection}`,
        },
      })

      .pipe(
        map((data: PaginatedResults<User>) => {
          data.results = data.results.map((item: User) => plainToClass(User, item));
          return data;
        }),
      );
  }

  public findPendingVerifications(): Observable<User[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/users/pending`)
      .pipe(map((data: User[]) => plainToClass(User, data)));
  }

  public resendEmailVerification(user: IUser): Observable<void> {
    return this.http.post<void>(`${environment.apiBaseUrl}/users/resend-email-verification`, user);
  }

  public markAsVerified(user: IUser): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/verify/id/${user.id}`, {})
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public markAsUnverified(user: IUser): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/unverify/id/${user.id}`, {})
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public getById(id: string): Observable<User> {
    return this.http.get(`${environment.apiBaseUrl}/users/${id}`).pipe(map((data: User) => plainToClass(User, data)));
  }

  public getAllCasters(): Observable<User[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/users/casters`)
      .pipe(map((data: PaginatedResults<User>) => data.results.map((user: User) => plainToClass(User, user))));
  }

  public saveUser(userId: string, userData: FormData): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/${userId}`, userData)
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public signUp(user: IUser): Observable<User> {
    let param = '';
    if (this.storageService.getReferral()) {
      param = `referral=${this.storageService.getReferral()}`;
      this.storageService.removeReferral();
    }

    if (this.storageService.getAgentCode()) {
      param = `agentCode=${this.storageService.getAgentCode()}`;
    }

    return this.http
      .post(`${environment.apiBaseUrl}/users?${param}`, user)
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public verifyEmail(verificationCode: string): Observable<User> {
    return this.http
      .post(`${environment.apiBaseUrl}/users/verify/email`, { verificationCode })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public sendMobileVerificationCode(phonenumber: string): Observable<User> {
    return this.http
      .post(`${environment.apiBaseUrl}/users/verify/send-verification-code`, { phonenumber })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public checkMobileVerificationCode(phone: string, code: string): Observable<boolean> {
    return this.http
      .post(`${environment.apiBaseUrl}/users/verify/mobile`, {
        phoneNumber: phone,
        verificationCode: code,
      })
      .pipe(map((data: any) => data?.valid === true));
  }

  public forgotPassword(formData: any): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/users/forgot-password`, formData)
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public checkHash(hash: string): Observable<User> {
    return this.http
      .post(`${environment.apiBaseUrl}/users/check-reset-password/${hash}`, {})
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public resetPassword(hash: string, password: string): Observable<User> {
    return this.http
      .post(`${environment.apiBaseUrl}/users/reset-password/${hash}`, { password: `${password}` })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public searchByName(name: string): Observable<User[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/users/search/${name}`)
      .pipe(map((data: User[]) => plainToClass(User, data)));
  }

  public updateRoles(userId: string, roles: string[]): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/${userId}/roles`, { roles })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public setVisibility(userId: string, hidden: boolean): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/${userId}/visibility`, { hidden })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public setBypassPayment(userId: string, bypassPayment: boolean): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/${userId}/bypassPayment`, { bypassPayment })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public setActiveJobAlert(userId: string, activeJobAlert: boolean): Observable<User> {
    return this.http
      .put(`${environment.apiBaseUrl}/users/${userId}/activeJobAlert`, { activeJobAlert })
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public fetchPotentialUsersForJob(jobId: string): Observable<User[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/users/talentJobAlert/${jobId}`)
      .pipe(map((data: User[]) => plainToClass(User, data)));
  }

  public fetchJobQuota(): Observable<JobQuotaDTO> {
    return this.http.get(`${environment.apiBaseUrl}/users/jobquota`).pipe(map((data: JobQuotaDTO) => data));
  }

  public fetchUserDetails(userId: string): Observable<User> {
    return this.http
      .get(`${environment.apiBaseUrl}/users/details/${userId}`)
      .pipe(map((data: User) => plainToClass(User, data)));
  }

  public connectTalentAgent(agentCode: string) {
    return this.http.put(`${environment.apiBaseUrl}/talent-agent/accept`, { id: agentCode });
  }

  public getContact(userId: string, applicationId?: string): Observable<User> {
    return this.http
      .get(`${environment.apiBaseUrl}/users/${userId}/contact?applicationId=${applicationId}`)
      .pipe(map((data: User) => plainToClass(User, data)));
  }
}
