import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Job, PaginatedResults } from '@core/models';
import { IJob } from '@core/interfaces/job';
import { SaveJobDto } from '@core/dto/save-job.dto';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  public constructor(private http: HttpClient) {}

  public findAll(
    page: number = 0,
    limit: number = 21,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
    promoted: boolean = false,
    filters: any,
  ): Observable<PaginatedResults<Job>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
      promoted: `${promoted}`,
      ...filters,
    };

    return this.http
      .get(`${environment.apiBaseUrl}/jobs`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Job>) => {
          data.results = data.results.map((item: Job) => plainToClass(Job, item));
          return data;
        }),
      );
  }

  public getJob(jobId: string): Observable<Job> {
    return this.http.get(`${environment.apiBaseUrl}/jobs/${jobId}`).pipe(map((item: Job) => plainToClass(Job, item)));
  }

  public getJobForEdit(jobId: string): Observable<Job> {
    return this.http
      .get(`${environment.apiBaseUrl}/jobs/edit/${jobId}`)
      .pipe(map((item: Job) => plainToClass(Job, item)));
  }

  public getJobDetailsForList(jobId: string): Observable<Job> {
    return this.http
      .get(`${environment.apiBaseUrl}/jobs/listdetails/${jobId}`)
      .pipe(map((item: Job) => plainToClass(Job, item)));
  }

  public getJobsForUser(
    userId: string,
    status: string,
    page: number = 1,
    limit: number = 25,
  ): Observable<PaginatedResults<Job>> {
    const searchParams = {
      page: `${page - 1}`,
      limit: `${limit}`,
      orderBy: 'created',
      orderByDirection: 'desc',
    };

    return this.http
      .get(`${environment.apiBaseUrl}/jobs/${userId}/${status}`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Job>) => {
          data.results = data.results.map((item: Job) => plainToClass(Job, item));
          return data;
        }),
      );
  }

  public getJobsForAdmin(
    query: string,
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'created',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<Job>> {
    return this.http
      .get(`${environment.apiBaseUrl}/jobs/admin`, {
        params: {
          q: `${query ?? ''}`,
          page: `${page}`,
          limit: `${limit}`,
          orderBy: `${orderBy}`,
          orderByDirection: `${orderByDirection}`,
        },
      })
      .pipe(
        map((data: PaginatedResults<Job>) => {
          data.results = data.results.map((item: Job) => plainToClass(Job, item));
          return data;
        }),
      );
  }

  public saveJob(data: SaveJobDto): Observable<Job> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${data.job.id}`, data)
      .pipe(map((jobData: IJob) => plainToClass(Job, jobData)));
  }

  public markJobCompleted(id: string): Observable<IJob> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${id}/mark-completed`, {})
      .pipe(map((data: IJob) => plainToClass(Job, data)));
  }

  public cancelJob(id: string): Observable<IJob> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${id}/cancel`, {})
      .pipe(map((data: IJob) => plainToClass(Job, data)));
  }

  public duplicateJob(id: string): Observable<IJob> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${id}/duplicate`, {})
      .pipe(map((data: IJob) => plainToClass(Job, data)));
  }

  public enableJobNotification(id: string): Observable<IJob> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${id}/notification/enable`, {})
      .pipe(map((data: IJob) => plainToClass(Job, data)));
  }

  public disableJobNotification(id: string): Observable<IJob> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${id}/notification/disable`, {})
      .pipe(map((data: IJob) => plainToClass(Job, data)));
  }

  public rejectJobApplications(jobId: string, ids: string[], reason: string): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}/job-applications/${jobId}/reject_job_applications`, {
      ids,
      reason,
    });
  }
}
