import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Job, JobApplication } from '@core/models';
import { Store } from '@ngxs/store';
import { ApplyToJob, UploadVideoForJobApplication } from '@core/states';
import { MediaType } from '@core/interfaces';
import { Media } from '@core/models';
import { plainToClass } from 'class-transformer';
import { v4 } from 'uuid';

@Component({
  selector: 'app-job-application-upload-audition-video',
  templateUrl: './upload-audition-video.component.html',
  styleUrls: ['./upload-audition-video.component.scss'],
})
export class UploadAuditionVideoComponent implements OnInit {
  @Output()
  public cancelClicked = new EventEmitter();

  @Input()
  public job: Job;

  @Input()
  public jobApplication?: JobApplication;

  @Input()
  public userId: string;

  @Input()
  public applyModal: boolean;

  @Input()
  public isOnlyVideoUpload: boolean;

  public isBusy = false;

  public isShowVideoField = false;

  public applied = false;

  public form = new FormGroup({
    media: new FormControl(null, [
      Validators.required,
      (control: AbstractControl) => {
        if (!control.value) {
          return { required: true };
        }
        if (!control.value.link && !control.value.uploaded) {
          return { uploaded: false };
        }
        return null;
      },
    ]),
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<UploadAuditionVideoComponent>,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    if (!!this.data) {
      this.job = this.data?.job;
      this.jobApplication = this.data?.jobApplication;
    }

    const media = plainToClass(Media, {
      id: v4(),
      mediaType: MediaType.video,
      processed: false,
      uploaded: false,
      link: null,
    });
    this.form.setValue({
      media,
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public get media(): FormGroup {
    return this.form.get('media') as FormGroup;
  }

  public showVideoField(): void {
    this.isShowVideoField = true;
  }

  public applyWithVideo(): void {
    if (this.form.valid) {
      if (this.jobApplication === null || this.jobApplication === undefined) {
        this.store.dispatch(new ApplyToJob(this.userId, this.job.id, this.media.value));
      } else {
        this.store.dispatch(new UploadVideoForJobApplication(this.jobApplication.id, this.media.value));
      }
      if (this.isOnlyVideoUpload || this.applyModal) {
        this.applied = true;
      } else {
        this.closeModal();
      }
    }
  }

  public skipAndApply(): void {
    this.store.dispatch(new ApplyToJob(this.userId, this.job.id));
    if (this.applyModal) {
      this.applied = true;
    } else {
      this.closeModal();
    }
  }
}
