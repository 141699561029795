import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value: any,
    currencyCode: string = 'IDR',
    display: string | boolean = 'symbol',
    digitsInfo: string = '1.0-0',
    locale: string = 'id-ID',
  ): any {
    // Use the CurrencyPipe to transform the value
    let transformedValue = this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    // Replace the default currency symbol with 'Rp' if the currencyCode is 'IDR'
    if (currencyCode === 'IDR' && transformedValue) {
      transformedValue = transformedValue.replace('Rp', 'Rp ');
    }
    return transformedValue;
  }
}
