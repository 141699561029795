import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IChat } from '@core/interfaces';
import {
  ActivateChat,
  MarkMessagesAsRead,
  MessagingState,
  SendMessage,
  UnsetActiveChat,
  UserState,
} from '@core/states';
import { Select, Store } from '@ngxs/store';
import { animationFrameScheduler, Observable } from 'rxjs';
import { delay, filter, switchMap } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Chat, User } from '@core/models';

@Component({
  selector: 'app-messaging-list',
  templateUrl: './messaging-list.component.html',
  styleUrls: ['./messaging-list.component.scss'],
})
export class MessagingListComponent implements OnInit {
  @Select(MessagingState.chats)
  public chats$: Observable<Chat[]>;

  @Select(MessagingState.activeChat)
  public activeChat$: Observable<Chat>;

  @Select(UserState.user)
  public talent$: Observable<User>;

  @ViewChild('messagesContainer')
  public messagesContainer: ElementRef;

  @ViewChild('messageField')
  public messageField: ElementRef;

  public recipientId: string;
  public userId: string;
  public chatId: string;
  public form = new FormGroup({
    message: new FormControl(null, [Validators.required]),
  });
  public isBusy = false;
  public message = '';
  public messageCover = 'dutch';

  public constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<MessagingListComponent>,
    private translate: TranslateService,
  ) {
    if (this.data != null) {
      this.userId = this.data.userId;
      this.recipientId = this.data.recipientId;
    } else {
      this.talent$.subscribe((talent) => {
        if (talent != null) {
          this.userId = talent.id;
        }
      });
    }
    if (this.dialogRef) {
      this.dialogRef
        .afterClosed()
        .pipe(
          switchMap(() => this.store.selectOnce(MessagingState.activeChat)),
          switchMap((chat: IChat) => this.store.dispatch(new MarkMessagesAsRead(chat))),
          switchMap(() => this.store.dispatch(new UnsetActiveChat())),
        )
        .subscribe();
    }
  }

  public async ngOnInit(): Promise<void> {
    this.activeChat$
      .pipe(
        filter((chat) => !!chat),
        switchMap((chat) => this.setRecipient(chat)),
        delay(0, animationFrameScheduler),
        switchMap(() => this.scrollToBottom()),
      )
      .subscribe();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.messageCover = event.lang;
    });

    this.messageCover = this.translate.currentLang;
  }

  public async scrollToBottom(): Promise<void> {
    if (!this.messagesContainer) {
      return;
    }
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
  }

  public async setRecipient(chat: any): Promise<void> {
    this.recipientId = this.userId === chat.recipientId ? chat.senderId : chat.recipientId;
  }

  public onMessageFieldKeyPress(evt: KeyboardEvent): void {
    if (evt.code === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      this.sendMessage();
    }
  }

  public onMessageFieldPaste(evt: ClipboardEvent): void {
    evt.preventDefault();
    const text = evt.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  }

  public onChangeMessage(value: string): void {
    this.form.setValue({ message: value });
  }

  public async sendMessage(): Promise<void> {
    if (!this.messageField?.nativeElement.innerHTML) {
      return;
    }

    const chat = this.store.selectSnapshot(MessagingState.activeChat);

    const messageReplaceBr = this.messageField.nativeElement.innerHTML
      .replace(/<br\s*\\?>/g, '\r\n')
      .trim()
      .replace(/&nbsp;/gi, '');

    await this.store.dispatch(
      new SendMessage({
        chatId: chat.id,
        senderId: this.userId,
        recipientId: this.recipientId,
        message: messageReplaceBr,
      }),
    );

    this.message = '';
    this.messageField.nativeElement.textContent = '';
  }

  public onChatClicked(chat: IChat): void {
    const activeChat = this.store.selectSnapshot(MessagingState.activeChat);

    if (activeChat?.id === chat.id) {
      this.store.dispatch(new UnsetActiveChat());
    } else {
      this.store.dispatch(new ActivateChat(chat.id));
      this.store.dispatch(new MarkMessagesAsRead(chat));
    }
  }
}
