<section class="job" *ngIf="jobs !== undefined && jobs.length > 0">
  <div class="job-vertical">
    <a [href]="'/account/jobs/status/'+status+'/'+job.id+'/details'" class="card-job" *ngFor="let job of jobs">
      <div class="content">
        <div class="header">
          <img [src]="(job.media.length === 0) ? '/assets/img/caster-default.png': job.media[0].url" alt="" alt="" />
          <div>
            <p>{{job.title | truncate: 100:true}}</p>
          </div>
        </div>
        <div class="body">
          <ol>
            <li *ngIf="!job.hideRate">{{job.totalRate | customCurrency}}</li>
            <li>
              {{ job.firstDate | dateRange: { exact: job.exactDate, withTime: false, dateFormatStartDate:"DD MMM",
              dateFormat: "DD MMM 'YY"} }}
            </li>
            <li class="duration" *ngIf="job.dates.length > 0">{{ job.firstDate.start | timeRemaining }}</li>
          </ol>
          <ol>
            <li>
              {{ job.jobApplications?.length || 0 }}
              <span>{{'Applicants' | translate}}</span>
            </li>
            <li>{{ job.totalBookedTalent }} {{ 'Booked talents' | translate | truncate:20}}</li>
          </ol>
        </div>
      </div>
    </a>
  </div>
</section>
<section class="job" *ngIf="jobsAplicant !== undefined && jobsAplicant.length > 0">
  <div class="job-vertical">
    <div class="card-job" *ngFor="let job of jobsAplicant" (click)="jobDetail(job.job,job)">
      <div class="notification" *ngIf="urgentMessageApplicant(job)">
        <div class="notif-badge notif-badge-danger"></div>
        <p>{{urgentMessageApplicant(job)}}</p>
      </div>
      <div class="content">
        <div class="header">
          <img
            [src]="(job.job.media.length === 0) ? '/assets/img/caster-default.png': job.job.media[0].url"
            alt=""
            alt="" />
          <div>
            <p>{{job.job.title | truncate: 100:true}}</p>
          </div>
        </div>
        <div class="body">
          <ol>
            <li *ngIf="!job.job.hideRate">{{job.job.totalRate | customCurrency}}</li>
            <li *ngIf="!job.job.extraFees && (!job.job.hideRate || (userAlreadyApplied$ | async) === true)">
              <span translate>Buyout</span>
              &nbsp;
              <span translate>Included</span>
            </li>
            <li class="duration" *ngIf="job.job.dates.length > 0">{{ job.job.firstDate.start | timeRemaining }}</li>
          </ol>
          <ol>
            <li *ngIf="job.job.countries.length>0 && job.job.countries[0].city != ''">
              {{job.job.countries[0].city}}, {{job.job.countries[0].country.isoCode}}
            </li>
            <li>
              {{ job.job.firstDate | dateRange: { exact: job.job.exactDate, withTime: false, dateFormatStartDate:"DD
              MMM", dateFormat: "DD MMM 'YY"} }}
            </li>
            <li class="duration"></li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>
<app-mobile-job-detail #detailJobModal></app-mobile-job-detail>
<app-mobile-upload-video-modal #uploadVideoModal></app-mobile-upload-video-modal>
<app-mobile-post-travel-cost-modal #postTravelCostModal></app-mobile-post-travel-cost-modal>
