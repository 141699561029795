<div *ngIf="jobApplication$ | async as jobApplication">
  <ng-container *ngIf="!mobileVersion">
    <div class="text-white p-3 p-md-5 w-100">
      <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>
      <div class="row mt-2">
        <div class="col-md-6">
          <div style="width: 90vh">
            <div class="d-flex mb-3">
              <app-image
                image="/assets/img/caster-default.png"
                class="preview rounded-circle mr-2 profile-pic"></app-image>
              <div class="my-auto mx-0">
                <h5 class="h5 pb-2">Caster</h5>
                <app-rating
                  [average]="jobApplication.job.user.averageReview"
                  [count]="jobApplication.job.user.totalReviews"></app-rating>
              </div>
            </div>
          </div>
          <h3 class="pb-2">{{ jobApplication.job.title }}</h3>
          <p class="mb-3">{{ jobApplication.job.shortDescription }}</p>
          <div class="row mb-4">
            <div class="col-6">
              <div class="border p-2 rounded d-flex justify-content-center align-items-center h-100">
                <app-money [amount]="jobApplication.job.totalRate" class="h5"></app-money>
              </div>
            </div>
            <div class="col-6">
              <div class="border p-2 rounded text-center h-100">
                <app-icon class="mx-auto my-2" fill="white" icon="/assets/img/location.svg"></app-icon>
                <div class="m-0 font-weight-light">
                  <div *ngIf="jobApplication.job?.workFromHome">Online (work from home)</div>
                  <div *ngFor="let jobCountry of jobApplication.job?.countries">
                    <span class="job-city" *ngIf="jobCountry.city">{{ jobCountry.city }}</span>
                    <span translate>{{ jobCountry.country.translateKey }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="border p-2 rounded text-center h-100">
                <app-icon class="mx-auto my-2" fill="white" icon="/assets/img/briefcase.svg"></app-icon>
                <span class="d-block nowrap">{{ jobApplication.job.category.translateKey | translate }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="border p-2 rounded text-center h-100">
                <app-icon class="mx-auto my-2" fill="white" icon="/assets/img/clock.svg"></app-icon>
                <ng-container *ngIf="jobApplication.job.dates.length > 0">
                  <span class="d-block">
                    {{ jobApplication.job.firstDate | dateRange: { exact: jobApplication.job.exactDate, withTime: false
                    } }}
                    <ng-container *ngIf="jobApplication.job.dates.length > 1">
                      - {{ jobApplication.job.lastDate | dateRange: { exact: jobApplication.job.exactDate, withTime:
                      false } }}
                    </ng-container>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Fee details</h3>
                  </div>
                </div>

                <div class="row my-3" *ngIf="jobApplication.job.rate">
                  <div class="col-6"><strong translate>Fee</strong></div>
                  <div class="col-6 text-right" translate>
                    <span *ngIf="jobApplication.job.feeType.slug !== 'fee'" class="h3 flex-grow-1 text-break" translate>
                      {{ jobApplication.job.feeType.translateKey }}
                    </span>
                    <app-money
                      *ngIf="jobApplication.job.feeType.slug === 'fee'"
                      [amount]="jobApplication.job.rate"
                      class="h6 flex-grow-1 text-break"></app-money>
                  </div>
                </div>
                <div class="row my-3" *ngIf="jobApplication.job.extraFees">
                  <div class="col-6"><strong translate>Buyout</strong></div>
                  <div class="col-6 text-right" translate>
                    <app-money [amount]="jobApplication.job.extraFees" class="h6 flex-grow-1 text-break"></app-money>
                  </div>
                </div>
                <div class="row my-3" *ngIf="!jobApplication.job.extraFees">
                  <div class="col-6"><strong translate>Buyout</strong></div>
                  <div class="col-6 text-right" translate>
                    <span class="h6 flex-grow-1 text-break" translate>Included</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3" *ngIf="jobApplication.job.exactDate">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Date(s)</h3>
                  </div>
                </div>
                <div class="row my-3" *ngFor="let jobDate of jobApplication.job.getSortedDates; let i = index">
                  <div class="col-4">
                    <strong translate>{{ 'dayX' | translate: { day: i + 1 } }}</strong>
                  </div>
                  <div class="col-8 text-right" translate>
                    {{ jobDate | dateRange: { exact: jobApplication.job.exactDate } }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3" *ngIf="!jobApplication.job.exactDate">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Date(s)</h3>
                  </div>
                </div>
                <div class="row my-3" *ngFor="let jobDate of jobApplication.job.getSortedDates; let i = index">
                  <div class="col-6">
                    <strong translate>Period</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div translate class="mr-1">{{ jobDate | dateRange: { exact: jobApplication.job.exactDate } }}</div>
                  </div>
                  <div class="col-6">
                    <strong translate>Total days</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div translate class="mr-1">{{ jobDate.days }}</div>
                  </div>
                  <div class="col-6">
                    <strong translate>Avg. hours per day</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div translate class="mr-1">{{ jobDate.hours_per_day }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h2 translate>Publication details</h2>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Publishing channels</strong>
                  </div>
                  <div class="col-6 text-right">
                    <div *ngFor="let channel of jobApplication.job.formattedPublishingChannels" translate class="mr-1">
                      {{ channel }}
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Broadcast countries</strong>
                  </div>
                  <div class="col-6 text-right">
                    <ng-container *ngIf="jobApplication.job.broadcastCountries == 'worldwide'">
                      {{ jobApplication.job.broadcastCountries | translate }}
                    </ng-container>
                    <ng-container *ngIf="jobApplication.job.broadcastCountries == 'other'">
                      <div *ngFor="let country of jobApplication.job?.licenceCountries">
                        {{ country.translateKey | translate }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Licence duration</strong>
                  </div>
                  <div class="col-6 text-right">
                    <span translate>{{ jobApplication.job.licenceDuration }}</span>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Commercial use</strong>
                  </div>
                  <div class="col-6 text-right">
                    <span translate>{{ jobApplication.job.commercialUse ? 'Yes' : 'No' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row mb-3">
                  <div class="col-12">
                    <h3 translate>Talent description</h3>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6" translate><strong>Gender</strong></div>
                  <div class="col-6 text-right">
                    <div *ngFor="let gender of jobApplication.job?.formattedGenders" translate class="mr-1">
                      {{ gender }}
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <strong translate>Age range</strong>
                  </div>
                  <div class="col-6 text-right">
                    {{ jobApplication.job.startAge }} - {{ jobApplication.job.endAge }}
                  </div>
                </div>
                <div class="row my-3" *ngIf="jobApplication.job?.talentCountries">
                  <div class="col-6">
                    <strong translate>Talents requested from</strong>
                  </div>
                  <div class="col-6 text-right">
                    <ng-container *ngIf="jobApplication.job?.talentCountries.length === 0" translate>No</ng-container>
                    <div *ngFor="let jobTalentCountry of jobApplication.job?.talentCountries">
                      <span class="job-city" *ngIf="jobTalentCountry.city">{{ jobTalentCountry.city }}</span>
                      <span>{{ jobTalentCountry.country.translateKey | translate }}</span>
                      <span *ngIf="jobTalentCountry.distance">&nbsp;- +{{ jobTalentCountry.distance }} km</span>
                    </div>
                  </div>
                </div>
                <div *ngFor="let detail of jobFilters" class="row my-3">
                  <div class="col-6">
                    <strong translate>{{ detail.translateKey }}</strong>
                  </div>
                  <div class="col-6 text-right">
                    <ng-container *ngIf="detail.type !== 'color'">{{ detail.value }}</ng-container>
                    <ng-container *ngIf="detail.type === 'color'">
                      <div class="d-flex float-right">
                        <app-color-indicator [color]="detail.value[0]"></app-color-indicator>
                        <span>-</span>
                        <app-color-indicator [color]="detail.value[1]"></app-color-indicator>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12">
              <div class="p-2 border rounded">
                <div class="row my-3">
                  <div class="col-6"><strong translate>Food included</strong></div>
                  <div class="col-6 text-right" translate>{{ jobApplication.job.foodIncluded ? 'Yes' : 'No' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <app-button
            (click)="acceptJob(jobApplication)"
            *ngIf="jobApplication.status == 'offered'"
            buttonStyle="purple"
            class="mb-3 rounded">
            <div class="px-2" translate>Accept</div>
          </app-button>
          <app-button
            (click)="confirmJob(jobApplication)"
            *ngIf="jobApplication.status == 'requesttobook'"
            buttonStyle="purple"
            class="mb-3 rounded">
            <div class="px-2" translate>Confirm booking</div>
          </app-button>
          <app-button
            (click)="uploadJobApplicationVideo(jobApplication)"
            *ngIf="isVideoRequested(jobApplication)"
            buttonStyle="purple"
            class="mb-3 rounded">
            <div class="px-2" *ngIf="jobApplication.talentVideoId === null" translate>Upload video</div>
            <div class="px-2" *ngIf="jobApplication.talentVideoId !== null" translate>Re-upload video</div>
          </app-button>
          <ng-container *appUnleash="'travel-cost'">
            <div class="d-flex" *ngIf="showTravelCosts(jobApplication)">
              <app-button
                (click)="claimTravelCosts(jobApplication)"
                buttonStyle="purple"
                class="mb-3 rounded flex-grow-1"
                translate>
                Add travel costs
              </app-button>
            </div>
          </ng-container>
          <app-button
            (click)="cancelJobApplication(jobApplication)"
            *ngIf="jobApplication.status == 'applied' || jobApplication.status == 'accepted'"
            buttonStyle="purple"
            class="mb-3 rounded">
            <div class="px-2" translate>Cancel application</div>
          </app-button>

          <app-button
            (click)="rejectJob(jobApplication)"
            *ngIf="jobApplication.status == 'offered'"
            buttonStyle="white-outline"
            class="mb-3 rounded">
            <div class="px-2" translate>Reject</div>
          </app-button>
          <app-button
            (click)="rejectBooking(jobApplication)"
            *ngIf="jobApplication.status == 'requesttobook'"
            buttonStyle="black-outlined"
            class="mb-3 rounded">
            <div class="px-2" translate>Reject booking</div>
          </app-button>
          <app-button
            (click)="cancelBooking(jobApplication)"
            *ngIf="jobApplication.status == 'confirmed'"
            buttonStyle="black-outlined"
            class="mb-3 rounded">
            <div class="px-2" translate>Cancel booking</div>
          </app-button>
          <div class="d-flex">
            <app-button
              (click)="openReportJob(jobApplication)"
              buttonStyle="white-outline"
              class="mb-3 rounded flex-grow-1"
              translate>
              Report
            </app-button>
          </div>
          <div class="d-flex" *ngIf="jobApplication.status === 'completed'">
            <app-button
              (click)="review(jobApplication.job, jobApplication)"
              buttonStyle="white-outline"
              class="mb-3 rounded flex-grow-1"
              translate>
              Add Review
            </app-button>
          </div>
          <app-carousel [items]="jobApplication?.job.media" class="d-block">
            <ng-template #carouselSlideTpl let-item="item">
              <app-image [image]="item.url" class="cover"></app-image>
            </ng-template>
          </app-carousel>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-mobile-job-detail #detailJobModal *ngIf="mobileVersion"></app-mobile-job-detail>
