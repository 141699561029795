<div class="talent-contact-details-container">
  <ng-container *ngIf="contactModal === contactModalType.CONFIRMATION">
    <p>Are you sure want to see contact details?</p>
    <div class="group-action-button">
      <button (click)="confirmContactDetails()">Yes</button>
      <button (click)="closeModal()">Cancel</button>
    </div>
  </ng-container>
  <ng-container *ngIf="contactModal === contactModalType.REQUEST_DOCUMENTED">
    <div class="request-documented-container">
      <img class="request-documented-image" src="/assets/img/checkmark-outlined.svg" alt="" />
      <p class="request-documented">Request documented</p>
    </div>
  </ng-container>
  <ng-container *ngIf="contactModal === contactModalType.REQUEST_FORBIDDEN">
    <div class="alert-permission">
      <h6>Contact details are only available once the talent is booked</h6>
      <p>Premium members can always see contact details at any stage</p>
    </div>
    <div class="group-action-button">
      <button (click)="upgradeMembership()">Upgrade Membership</button>
      <button (click)="closeModal()">Close</button>
    </div>
  </ng-container>
  <ng-container *ngIf="contactModal === contactModalType.CONTACT_DETAILS">
    <div class="contact-details-container">
      <p>{{talent.profileName}}</p>
      <p *ngIf="talent?.phonenumberWithCallingCode">
        <img src="/assets/img/mobile/talent-contact-phone.svg" alt="" />
        +{{talent?.country?.callingCode}} {{talent.phoneNumber}}
      </p>
    </div>
    <div class="group-action-button">
      <button (click)="closeModal()">Close</button>
    </div>
  </ng-container>
</div>
