import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@src/ui/ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from './pipes/pipes.module';
import {
  BaseTableComponent,
  ReviewComponent,
  SettlementsListComponent,
  TransactionsListComponent,
  UserDetailsComponent,
  VerifyMobileComponent,
  OTPComponent,
} from './components';

import { JobDetailsComponent } from './components/jobs/details/details.component';
import { SmallJobCardComponent } from './components/jobs/small-job-card/small-job-card.component';
import { ApplyComponent } from './components/jobs/apply/apply.component';
import { AcceptJobTalentConfirmComponent } from './components/jobs/confirm/confirm.component';
import { RequestRefundJobApplicationComponent } from './components/job-applications/request-refund/request-refund.component';
import { UploadAuditionVideoComponent } from './components/job-applications/upload-audition-video/upload-audition-video.component';

import { JobApplicationDetailsComponent } from './components/job-applications/details/details.component';
import { ConfirmComponent } from './components/job-applications/confirm/confirm.component';

import { NotAvailableComponent } from './components/not-available/not-available.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectiveModule } from '@src/directive/directive.module';
import { SharedMobileModule } from './mobile-components/shared-mobile.module';

const modules = [
  ReactiveFormsModule,
  RouterModule,
  UiModule,
  MatDialogModule,
  TranslateModule,
  PipesModule,
  DirectiveModule,
  SharedMobileModule,
];
const components = [
  BaseTableComponent,
  UserDetailsComponent,
  VerifyMobileComponent,
  SettlementsListComponent,
  TransactionsListComponent,
  JobDetailsComponent,
  SmallJobCardComponent,
  ApplyComponent,
  JobApplicationDetailsComponent,
  ConfirmComponent,
  NotAvailableComponent,
  AcceptJobTalentConfirmComponent,
  RequestRefundJobApplicationComponent,
  ReviewComponent,
  OTPComponent,
  UploadAuditionVideoComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, ...modules, NgbModule],
  exports: [...components, ...modules],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: '98vw', maxHeight: '90vh', hasBackdrop: true } },
  ],
})
export class SharedModule {}
