<div class="input-group">
  <div class="input-group-prepend">
    <ng-content select="[prepend]"></ng-content>
  </div>

  <ng-container *ngIf="numberFormat; else default">
    <input
      #input
      (input)="onValueChange($event.target.value)"
      *ngIf="!isReadOnly"
      [attr.maxlength]="maxlength"
      [placeholder]="placeholder"
      [type]="type"
      [step]="step"
      class="form-control"
      [attr.aria-autocomplete]="autofill ? 'both' : 'none'"
      [autocomplete]="autofill"
      [attr.role]="autofill ? 'combobox' : 'none'"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      appNumberMask />
  </ng-container>
  <ng-template #default>
    <input
      #input
      (input)="onValueChange($event.target.value)"
      *ngIf="!isReadOnly"
      [attr.maxlength]="maxlength"
      [placeholder]="placeholder"
      [type]="type"
      [step]="step"
      class="form-control"
      [attr.aria-autocomplete]="autofill ? 'both' : 'none'"
      [autocomplete]="autofill"
      [attr.role]="autofill ? 'combobox' : 'none'"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)" />
  </ng-template>

  <div *ngIf="isReadOnly" class="form-control">{{value}}</div>

  <div class="input-group-append">
    <ng-content select="[append]"></ng-content>
  </div>
</div>
