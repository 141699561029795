import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Store } from '@ngxs/store';
import { GetGeolocationByDescription } from '@core/states';
import { GeocodingResultDTO } from '@core/models';
import { GeoLocationsService } from '@core/services';
import { v4 } from 'uuid';

@Component({
  selector: 'app-geolocate-field',
  templateUrl: './geolocate-field.component.html',
  styleUrls: ['./geolocate-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeoLocateFieldComponent),
      multi: true,
    },
  ],
})
export class GeoLocateFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public placeholder = '';

  @Input()
  public maxlength: number;

  @Input()
  public isReadOnly = false;

  @Input()
  public type = 'text';

  @Input()
  public autofill: string = null;

  @Input()
  public countryCode: string = null;

  @Output()
  public blurred = new EventEmitter();

  @Output()
  public focussed = new EventEmitter();

  public geocodeResults: GeocodingResultDTO[];
  public value = null;
  private sessionKey = v4();

  public constructor(private store: Store, private geoLocationsService: GeoLocationsService) {}

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public async onValueChange(value: string): Promise<void> {
    this.value = value;
    this.onChange(value);
    await this.getSearchResults();
  }

  public async getSearchResults(): Promise<void> {
    if (this.countryCode != null) {
      this.geocodeResults = await this.geoLocationsService
        .searchCity(this.value, this.countryCode, 'en', this.sessionKey)
        .toPromise();
    } else {
      this.geocodeResults = await this.geoLocationsService
        .searchLocation(this.value, 'en', this.sessionKey)
        .toPromise();
    }
  }

  public selectGeocodeResult(geocodeResult: GeocodingResultDTO): void {
    this.writeValue(geocodeResult.description);
    this.geocodeResults = null;
    this.store.dispatch(new GetGeolocationByDescription(geocodeResult.description));
    this.onChange(geocodeResult.description);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setFocus(): void {
    this.inputEl.nativeElement.focus();
  }

  public onFocus(e: Event): void {
    this.focussed.emit(e);
  }

  public onBlur(e: Event): void {
    this.blurred.emit(e);
  }

  private updateField(value: string): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.value = value;
  }
}
