import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-success-modal',
  templateUrl: './mobile-success-modal.component.html',
  styleUrls: ['./mobile-success-modal.component.scss'],
})
export class MobileSuccessModalComponent implements OnInit {
  @ViewChild('appSuccessModal') private modalComponent: MobileModalDialogComponent;
  constructor() {}

  ngOnInit(): void {}

  async openDialog() {
    return await this.modalComponent.open().then(() => {});
  }

  async close() {
    return await this.modalComponent.close();
  }
}
