<div *ngIf="talent" class="d-flex flex-column w-100 h-100">
  <div [ngStyle]="{ 'height.px': imageSize?.height }" class="position-relative">
    <app-image
      [image]="talent.firstPhotoAlbum?.media[0]?.thumbUrl"
      [ngStyle]="{ 'width.px': imageSize?.width, 'height.px': imageSize?.height, 'border-radius':'6px' }"
      class="cover d-block rounded-sm initial"></app-image>

    <app-gradient
      [gradientName]="hover ? 'purple-bright-transparent' : null"
      class="bottom-bar rounded-sm d-flex flex-column justify-content-end"
      gradientDirection="vertical-reverse">
      <span translate>from</span>
      <div class="d-flex flex-row">
        <app-money [amount]="talent.rate == null ? 0 :talent.rate" class="h5"></app-money>
        <span class="ml-1">p/d</span>
      </div>

      <ng-container *ngIf="talent?.user?.reviews?.length > 0">
        <app-rating [average]="talent?.averageReview" [count]="talent.totalReviews"></app-rating>
      </ng-container>
    </app-gradient>
  </div>
  <div class="footer d-flex flex-fill align-items-center mt-auto">
    <img
      *ngIf="talent.user.photo; else noPhoto"
      [src]="talent.user.photo.url"
      class="profile-image rounded-circle"
      loading="lazy" />
    <ng-template #noPhoto>
      <div class="profile-image bg-gray rounded-circle"></div>
    </ng-template>
    <div [ngStyle]="{ 'width.px': tileSize.width - 56 }" class="flex-column details">
      <div class="d-flex flex-row">
        <span>{{ talent.user.profileName }}</span>
        <app-icon *ngIf="talent.user?.hasBadge" icon="/assets/img/mobile/badge.svg" class="size-5"></app-icon>
      </div>
      <div class="text-gray">{{ talent.user.city }}, {{ talent.user.country.isoCode }}</div>
      <div class="text-gray">{{ talent.category.translateKeySingular | translate }}</div>
    </div>
  </div>
</div>
