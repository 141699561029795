<app-mobile-modal-dialog #appRequestToBookModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!isSuccess else success">
    <div class="modal-body" style="height: 300px">
      <div class="request-tobook-info">
        <h6>{{'Request to Book' | translate}}</h6>
        <p class="text-center" translate>You are about to send a booking request</p>
        <ol class="mt-3">
          <li translate>The Talent can accept or reject your booking request.</li>
          <!-- <li translate>
            If you cancel a confirmed booking within 3 days before the shoot date, the Talent may charge 50% of the fee,
            100% of the fee if canceled within 1 day before the shoot date.
          </li> -->
        </ol>
      </div>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button
          type="button"
          class="btn btn-bg-purple"
          style="border-radius: 100px; margin: auto"
          [ngStyle]="{width:isBusy?'182px':'max-content'}"
          (click)="acceptJobApplication()">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ 'Send booking request' | translate }}</span>
        </button>
      </div>
      <button type="button" (click)="close()" class="btn btn-block">{{'Cancel' | translate}}</button>
    </div>
  </ng-container>
  <ng-template #success>
    <div class="modal-body">
      <app-mobile-success [message]="'Booking request has been sent'"></app-mobile-success>
      <div class="wrap-btn mt-t">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
