<div class="w-100" [ngClass]="{ invalid: albums.controls.length == 0, 'd-flex': albums.controls.length == 0 }">
  <ng-container *ngFor="let album of albums.controls; let i = index">
    <app-album-field
      *ngIf="album.value.albumType === 'photo'"
      class="d-flex w-100"
      (delete)="deleteAlbum(i)"
      [formControl]="album"
      [user]="user"></app-album-field>
  </ng-container>

  <app-button (click)="addAlbum('photo')" class="my-2 mx-auto" *ngIf="noPhotoAlbum">
    <span class="py-1 px-4" translate>Add photo album</span>
  </app-button>

  <ng-container *ngFor="let album of albums.controls; let i = index">
    <app-album-field
      *ngIf="album.value.albumType === 'video'"
      class="d-flex w-100 mt-3"
      (delete)="deleteAlbum(i)"
      [formControl]="album"
      [user]="user"></app-album-field>
  </ng-container>

  <app-button (click)="addAlbum('video')" class="my-2 mx-auto" *ngIf="noVideoAlbum">
    <span class="py-1 px-4" translate>Add video album</span>
  </app-button>
</div>
