<app-mobile-modal-dialog #appRemoveInvitaionModal>
  <div class="mobile-invitation-remove-container">
    <div class="modal-body">
      <p>Are you sure want to remove your recomendation ?</p>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="removeInvitation()" class="btn btn-bg-purple">{{'Remove' | translate}}</button>
      </div>
      <div class="wrap-btn mb-2">
        <button type="button" (click)="closeDialog()" class="btn btn-block">{{'Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</app-mobile-modal-dialog>
