import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  public constructor(private http: HttpClient) {}

  public getProfileRecomendation(userId: string): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/referral/recommendation?userID=${userId}`);
  }
  public getRecomendationList(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/referral`);
  }
  public sendInvitation(email: string): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/referral`, {
      email,
    });
  }
  public acceptInvitation(referralId: string): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}/referral/accept`, {
      referralCode: referralId,
    });
  }
  public removeRecomendation(referralId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        referralID: referralId,
      },
    };
    return this.http.delete(`${environment.apiBaseUrl}/referral`, options);
  }
}
