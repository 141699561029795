<app-gradient
  *ngIf="job"
  class="rounded text-white px-2 px-md-5 py-4 d-flex"
  gradientDirection="horizontal"
  [gradientName]="job.promoted ? themes[theme] : 'purple-bright'">
  <div class="row mx-0 w-100">
    <div class="col-12 col-lg-4 mb-4 mb-lg-0 cursor-pointer" (click)="openModal()">
      <h4 class="h4 pb-2">{{ job.title }}</h4>
      <p>{{ job.shortDescription | truncate: 170:true }}</p>
      <div class="flex-grow-1"></div>
      <div class="footer d-flex">
        <img src="/assets/img/caster-default.png" class="profile-image rounded-circle mr-3" />
        <div class="flex-column details">
          <div>Caster</div>
          <div>{{ job.user.country?.isoCode }}</div>
          <div class="text-gray"></div>
        </div>
      </div>
    </div>
    <div class="col-1 d-lg-block d-none"></div>
    <div class="col-5 col-md-2 text-center">
      <div class="h-50 mb-3 align-items-center border border-white rounded d-flex p-2">
        <span *ngIf="job.feeType.slug !== 'fee'" class="h2 flex-grow-1 text-break" translate>
          {{ job.feeType.translateKey }}
        </span>
        <ng-container *ngIf="job.feeType.slug === 'fee'">
          <span *ngIf="job.hideRate" class="h2 flex-grow-1 text-break" translate>{{ job.hideRateFee }}</span>
          <app-money *ngIf="!job.hideRate" [amount]="job.totalRate" class="flex-grow-1 text-break"></app-money>
        </ng-container>
      </div>
      <div class="h-50 mt-3 align-items-center border border-white rounded d-flex p-2">
        <div class="flex-grow-1" translate>
          <app-icon icon="/assets/img/briefcase.svg" class="size-6 mx-auto mb-2" fill="#ffffff"></app-icon>
          {{ job.category.translateKey }}
        </div>
      </div>
    </div>
    <div class="col-7 col-md-5 col-lg-2 text-center">
      <div class="h-50 mb-3 align-items-center border border-white rounded d-flex p-2">
        <div class="flex-grow-1">
          <app-icon icon="/assets/img/location.svg" class="size-6 mx-auto mb-2" fill="#ffffff"></app-icon>
          <ng-container *ngIf="job.countries.length > 0">
            <ng-container *ngIf="job.countries[0].city">{{ job.countries[0].city }},</ng-container>
            {{ job.countries[0].country.isoCode }}
          </ng-container>
        </div>
      </div>
      <div class="h-50 mt-3 align-items-center border border-white rounded d-flex p-2">
        <div class="flex-grow-1">
          <app-icon icon="/assets/img/clock.svg" class="size-6 mx-auto mb-2" fill="#ffffff"></app-icon>
          <ng-container *ngIf="job.firstDate">
            {{ job.firstDate | dateRange: { exact: job.exactDate, withTime: false, dateFormat: "DD MMM 'YY" } }}
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5 col-lg-3 mt-4 mt-md-0">
      <div class="d-block align-middle w-100 w-md-auto" *ngIf="job.media.length == 0">
        <app-image image="/assets/img/placeholders/job-default-image.png" class="cover d-block rounded-sm"></app-image>
      </div>
      <app-carousel [items]="job.media" [options]="options" *ngIf="job.media.length > 0">
        <ng-template #carouselSlideTpl let-item="item">
          <app-image [image]="item.url" class="cover d-block rounded-sm"></app-image>
        </ng-template>
      </app-carousel>
    </div>
  </div>
</app-gradient>
