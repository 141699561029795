import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { PaginatedResults, UserProfile } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class UserProfilesService {
  public constructor(private http: HttpClient) {}

  public findAll(
    page: number = 0,
    limit: number = 21,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
    filters: any,
  ): Observable<PaginatedResults<UserProfile>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
      ...filters,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/profiles`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<UserProfile>) => {
          data.results = data.results.map((item: UserProfile) => plainToClass(UserProfile, item));
          return data;
        }),
      );
  }

  public getById(id: string): Observable<UserProfile> {
    return this.http
      .get(`${environment.apiBaseUrl}/profiles/${id}`)
      .pipe(map((data: UserProfile) => plainToClass(UserProfile, data)));
  }

  public getEditProfileById(id: string): Observable<UserProfile> {
    return this.http
      .get(`${environment.apiBaseUrl}/profiles/edit/${id}`)
      .pipe(map((data: UserProfile) => plainToClass(UserProfile, data)));
  }

  public getByUserId(id: string): Observable<UserProfile[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/profiles/user/${id}`)
      .pipe(map((data: UserProfile[]) => plainToClass(UserProfile, data)));
  }

  public createNewProfile(id: string, formData: any): Observable<UserProfile> {
    return this.http
      .post(`${environment.apiBaseUrl}/profiles/${id}`, formData)
      .pipe(map((data: UserProfile) => plainToClass(UserProfile, data)));
  }

  public saveProfile(profileId: string, profileData: FormData): Observable<UserProfile> {
    return this.http
      .put(`${environment.apiBaseUrl}/profiles/${profileId}`, profileData)
      .pipe(map((data: UserProfile) => plainToClass(UserProfile, data)));
  }

  public deleteProfile(profileId: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/profiles/${profileId}`);
  }

  public getByProfileName(profileName: string): Observable<UserProfile[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/profiles/${profileName}/details`)
      .pipe(map((data: UserProfile[]) => data.map((userProfile) => plainToClass(UserProfile, userProfile))));
  }
}
