import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private KEY_NAME = 'access_token';
  private REFERRAL = 'referral_key';
  private AGENT_CODE = 'agent_code';
  private AGENT_EMAIL = 'agent_email';

  public get(): string {
    return localStorage.getItem(this.KEY_NAME);
  }

  public set(token: string): void {
    localStorage.setItem(this.KEY_NAME, token);
  }

  public remove(): void {
    localStorage.removeItem(this.KEY_NAME);
  }

  public getReferral(): string {
    return localStorage.getItem(this.REFERRAL);
  }

  public setReferral(referralId: string): void {
    localStorage.setItem(this.REFERRAL, referralId);
  }

  public removeReferral(): void {
    localStorage.removeItem(this.REFERRAL);
  }

  public getAgentCode(): string {
    return localStorage.getItem(this.AGENT_CODE);
  }

  public setAgentCode(referralId: string): void {
    localStorage.setItem(this.AGENT_CODE, referralId);
  }

  public removeAgentCode(): void {
    localStorage.removeItem(this.AGENT_CODE);
  }

  public getAgentEmail(): string {
    return localStorage.getItem(this.AGENT_EMAIL);
  }

  public setAgentEmail(value: string): void {
    localStorage.setItem(this.AGENT_EMAIL, value);
  }

  public removeAgentEmail(): void {
    localStorage.removeItem(this.AGENT_EMAIL);
  }
}
