import { Component, Inject, Input, OnInit, Optional } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreditPackage, User, UserProfile } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@core/states';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { PaymentsService } from '@core/services';

@Component({
  selector: 'app-credit-confirmation',
  templateUrl: './credit-confirmation-modal.component.html',
  styleUrls: ['./credit-confirmation-modal.component.scss'],
})
export class CreditConfirmationModalComponent implements OnInit {
  @Input()
  public creditPackage: CreditPackage;

  @Select(UserState.profiles)
  public profiles$: Observable<UserProfile[]>;

  public isBusy = false;

  public user: User;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { creditPackage: CreditPackage },
    @Optional() private dialogRef: MatDialogRef<CreditConfirmationModalComponent>,
    private store: Store,
    private paymentService: PaymentsService,
  ) {
    this.creditPackage = this.data.creditPackage;
  }

  public async ngOnInit(): Promise<void> {
    this.user = this.store.selectSnapshot(UserState.user);
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public async accept(): Promise<void> {
    this.isBusy = true;

    // hide temporary for midtrans
    const paymentResult = await this.paymentService
      .createCreditPayment(this.creditPackage.id, `${environment.baseUrl}/account/details`)
      .toPromise();

    this.isBusy = false;

    if (!!paymentResult) {
      window.location.href = paymentResult.transaction.redirect_url;
    }
  }
}
