<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #appMobileTalentContactModal>
  <div class="modal-body text-center mobile-talent-contact-modal-container">
    <ng-container *ngIf="authService?.getJwtToken()?.access_token !== null; else askForLogin">
      <ng-container *ngIf="contactModal === contactModalType.CONFIRMATION">
        <h4>Are you sure want to see contact details</h4>
        <div class="group-action-button">
          <button>Cancel</button>
          <button (click)="confirmContactDetails()">Yes</button>
        </div>
      </ng-container>
      <ng-container *ngIf="contactModal === contactModalType.REQUEST_DOCUMENTED">
        <div class="request-documented-container">
          <img class="request-documented-image" src="/assets/img/mobile/documented.svg" alt="" />
          <p class="request-documented">Request documented</p>
        </div>
      </ng-container>
      <ng-container *ngIf="contactModal === contactModalType.REQUEST_FORBIDDEN">
        <div class="alert-permission">
          <h6>Contact details are only available once the talent is booked</h6>
          <p>Premium members can always see contact details at any stage</p>
        </div>
        <div class="group-action-button">
          <button (click)="upgradeMembership()" style="width: max-content !important">Upgrade Membership</button>
          <button (click)="closeModal()">Close</button>
        </div>
      </ng-container>
      <ng-container *ngIf="contactModal === contactModalType.CONTACT_DETAILS">
        <div class="contact-details-container">
          <p>{{talent.profileName}}</p>
          <p *ngIf="talent?.phonenumberWithCallingCode">
            <img src="/assets/img/mobile/talent-contact-phone.svg" alt="" />
            +{{talent?.country?.callingCode}} {{talent.phoneNumber}}
          </p>
          <!-- <p>
            <img src="/assets/img/mobile/talent-contact-email.svg" alt="" />
            -
            blabalbla@gmail.com
          </p> -->
          <!-- <p>
            <img src="/assets/img/mobile/talent-contact-insta.svg" alt="">
            Insagram link
          </p> -->
        </div>
      </ng-container>
    </ng-container>
    <ng-template #askForLogin>
      <h4>Log in to see contact details or to offer a job</h4>
    </ng-template>
  </div>
</app-mobile-modal-dialog>
