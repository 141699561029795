import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { ButtonsModule } from '../buttons/buttons.module';

import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { IconsModule } from '../icons/icons.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { LabelComponent } from './components/label/label.component';
import { DateFieldComponent } from './components/date-field/date-field.component';
import { DateRangeFieldComponent } from './components/daterange-field/daterange-field.component';
import { TimeFieldComponent } from './components/time-field/time-field.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectComponent } from './components/select/select.component';
import { OptionComponent } from './components/option/option.component';
import { FileFieldComponent } from './components/file-field/file-field.component';
import { SliderComponent } from './components/slider/slider.component';
import { MoneySliderComponent } from './components/money-slider/money-slider.component';
import { WeightSliderComponent } from './components/weight-slider/weight-slider.component';
import { MetricSliderComponent } from './components/metric-slider/metric-slider.component';
import { ColorSliderComponent } from './components/color-slider/color-slider.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { MediaFieldComponent } from './components/media-field/media-field.component';
import { GeoLocateFieldComponent } from './components/geolocate-field/geolocate-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '@src/directive/directive.module';

const components = [
  CheckboxComponent,
  CheckboxGroupComponent,
  ColorSliderComponent,
  DateFieldComponent,
  DateRangeFieldComponent,
  FileFieldComponent,
  GeoLocateFieldComponent,
  LabelComponent,
  MediaFieldComponent,
  MetricSliderComponent,
  MoneySliderComponent,
  OptionComponent,
  PasswordFieldComponent,
  RadioComponent,
  SelectComponent,
  SliderComponent,
  TextFieldComponent,
  TextareaComponent,
  TimeFieldComponent,
  WeightSliderComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    ButtonsModule,
    IconsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxSliderModule,
    TranslateModule,
    DirectiveModule,
  ],
  exports: [...components, ReactiveFormsModule],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useFactory: () => ({
        display: {
          dateInput: 'DD-MM-YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }),
    },
  ],
})
export class FormsModule {}
