import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { JobApplication, Media, PaginatedResults, TravelExpense } from '@core/models';
import { JobApplicationStatus } from '@core/interfaces/job-application';
import { IMedia } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class JobApplicationsService {
  public constructor(private http: HttpClient) {}

  public getJobApplicationsForUser(
    userId: string,
    jobApplicationStatus: JobApplicationStatus,
  ): Observable<PaginatedResults<JobApplication>> {
    return this.http
      .get(`${environment.apiBaseUrl}/job-applications?talentId=${userId}&jobApplicationStatus=${jobApplicationStatus}`)
      .pipe(
        map((data: PaginatedResults<JobApplication>) => {
          data.results = data.results.map((item: JobApplication) => plainToClass(JobApplication, item));
          return data;
        }),
      );
  }

  public getAllJobApplicationsForUser(userId: string): Observable<PaginatedResults<JobApplication>> {
    return this.http.get(`${environment.apiBaseUrl}/job-applications?talentId=${userId}`).pipe(
      map((data: PaginatedResults<JobApplication>) => {
        data.results = data.results.map((item: JobApplication) => plainToClass(JobApplication, item));
        return data;
      }),
    );
  }

  public applyToJob(userId: string, jobId: string, media?: IMedia): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/job-applications/${jobId}/apply/${userId}`, { media })
      .pipe(map((item: JobApplication) => plainToClass(JobApplication, item)));
  }

  public requestTalentVideoForJobApplication(jobApplicationId: string): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/job-applications/${jobApplicationId}/requestVideo`, {})
      .pipe(map((item: JobApplication) => plainToClass(JobApplication, item)));
  }

  public uploadJobApplicationVideo(jobApplicationId: string, media?: IMedia): Observable<any> {
    return this.http
      .put(`${environment.apiBaseUrl}/job-applications/${jobApplicationId}/addVideo`, { media })
      .pipe(map((item: JobApplication) => plainToClass(JobApplication, item)));
  }

  public updateJobApplicationState(
    jobId: string,
    jobApplicationId: string,
    jobApplicationStatus: JobApplicationStatus,
    reason?: string,
  ): Observable<any> {
    return this.http.post(
      `${environment.apiBaseUrl}/job-applications/${jobApplicationId}/job/${jobId}/${jobApplicationStatus}`,
      { reason },
    );
  }

  public saveJobApplicationTravelExpenses(
    id: string,
    travelExpenses?: TravelExpense[],
    travelReceipts?: Partial<Media>[],
  ): Observable<JobApplication> {
    return this.http
      .put(`${environment.apiBaseUrl}/job-applications/${id}`, { travelExpenses, travelReceipts })
      .pipe(map((item: JobApplication) => plainToClass(JobApplication, item)));
  }

  public getJobApplication(jobApplicationId: string): Observable<JobApplication> {
    return this.http
      .get(`${environment.apiBaseUrl}/job-applications/${jobApplicationId}`)
      .pipe(map((item: JobApplication) => plainToClass(JobApplication, item)));
  }

  public confirmJobApplication(hash: string): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/job-applications/${hash}/confirmed`, {});
  }

  public requestTravelCosts(jobApplicationId: string): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/job-applications/${jobApplicationId}/request-travel-costs`, {});
  }

  public getJobApplicantionDetails(jobId: string, ids: string[]): Observable<JobApplication[]> {
    return this.http.post(`${environment.apiBaseUrl}/job-applications/${jobId}/details`, { ids }).pipe(
      map((data: JobApplication[]) => {
        return data.map((item: JobApplication) => plainToClass(JobApplication, item));
      }),
    );
  }
}
