import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Category } from '@core/models';

@Component({
  selector: 'app-mobile-category',
  templateUrl: './mobile-category.component.html',
  styleUrls: ['./mobile-category.component.scss'],
})
export class MobileCategoryComponent implements OnInit, OnChanges {
  @Output()
  public activeCategory = new EventEmitter<Category>();

  @Input()
  categories: Category[] = [];
  public includeCategorySlugs: string[] = ['models', 'actors', 'influencers', 'host-and-promotors'];
  public selectedCategory: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('categories' in changes && this.categories != null) {
      this.categories = this.categories
        .filter((c) => this.includeCategorySlugs.includes(c.slug))
        .sort((a, b) => {
          return this.includeCategorySlugs.indexOf(a.slug) - this.includeCategorySlugs.indexOf(b.slug);
        });
    }
  }

  public transformCategoryKey(translateKey: string): string {
    switch (translateKey) {
      case 'CATEGORY.make-up-artists':
        return 'CATEGORY.make-up-artists.mobile';
      default:
        return translateKey;
    }
  }

  public setActiveCategory(category: Category) {
    this.activeCategory.emit(category);
    this.selectedCategory = category.slug;
  }
}
