import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserProfilesService, UsersService } from '@core/services';

import {
  AddProfileToUser,
  FetchCurrentUser,
  FetchUserDetails,
  SaveUser,
  UpdateUserDetails,
  ResetCurrentUser,
  UpdateUserRoles,
  FetchAllCasters,
  SetActiveJobAlert,
  GetJobQuota,
  GetUserDetails,
} from './user.actions';
import { UserStateModel } from './user.state-model';
import { IUserProfile } from '@core/interfaces/user-profile';
import { AuthService } from '@auth/services';
import { User } from '@core/models';
import { JobQuotaDTO } from '@core/dto';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    casters: [],
    profiles: [],
    isProfilesLoaded: false,
    userDetails: null,
    jobQuota: {
      fromCredit: 0,
      fromMembership: 0,
      available: 0,
      total: 0,
      unlimited: false,
    },
  },
})
@Injectable()
export class UserState {
  public constructor(
    private usersService: UsersService,
    private userProfilesService: UserProfilesService,
    private authService: AuthService,
  ) {}

  @Selector()
  static user(state: UserStateModel): User {
    return state.user;
  }

  @Selector()
  static casters(state: UserStateModel): User[] {
    return state.casters;
  }

  @Selector()
  static profiles(state: UserStateModel): IUserProfile[] {
    return state.profiles;
  }

  @Selector()
  static isProfilesLoaded(state: UserStateModel): boolean {
    return state.isProfilesLoaded;
  }

  @Selector()
  static jobQuota(state: UserStateModel): JobQuotaDTO {
    return state.jobQuota;
  }

  @Selector()
  static userDetails(state: UserStateModel): User {
    return state.userDetails;
  }

  @Action(FetchCurrentUser)
  public async fetchCurrentUser(ctx: StateContext<UserStateModel>): Promise<void> {
    const userId = this.authService.getJwtToken().getClaim('sub');
    const user = await this.usersService.getById(userId).toPromise();
    ctx.dispatch(new UpdateUserDetails(user));
  }

  @Action(FetchAllCasters)
  public async fetchAllCasters(ctx: StateContext<UserStateModel>): Promise<void> {
    const casters: User[] = await this.usersService.getAllCasters().toPromise();
    ctx.patchState({ casters });
  }

  @Action(ResetCurrentUser)
  public async resetCurrentUser(ctx: StateContext<UserStateModel>): Promise<void> {
    ctx.patchState({ user: null });
  }

  @Action(FetchUserDetails)
  public async fetchUserDetails(ctx: StateContext<UserStateModel>, { id }: FetchUserDetails): Promise<void> {
    const user = await this.usersService.getById(id).toPromise();
    ctx.dispatch(new UpdateUserDetails(user));
  }

  @Action(UpdateUserDetails)
  public async updateUserDetails(ctx: StateContext<UserStateModel>, { user }: UpdateUserDetails): Promise<void> {
    const profiles = await this.userProfilesService.getByUserId(user.id).toPromise();
    const fetchedUser = await this.usersService.getById(user.id).toPromise();
    ctx.patchState({ user: fetchedUser, profiles, isProfilesLoaded: true });
  }

  @Action(UpdateUserRoles)
  public async updateUserRoles(ctx: StateContext<UserStateModel>, { userId, roles }: UpdateUserRoles): Promise<void> {
    const user = await this.usersService.updateRoles(userId, roles).toPromise();
    ctx.patchState({ user });
  }

  @Action(AddProfileToUser)
  public async addProfileToUser(ctx: StateContext<UserStateModel>, { userProfile }: AddProfileToUser): Promise<void> {
    const state = ctx.getState();
    const profiles = [...state.profiles];
    profiles.push(userProfile);
    ctx.patchState({ profiles });
  }

  @Action(SaveUser)
  public async saveTalent(ctx: StateContext<UserStateModel>, { id, userData }: SaveUser): Promise<void> {
    const user = await this.usersService.saveUser(id, userData).toPromise();
    ctx.patchState({ user });
  }

  @Action(SetActiveJobAlert)
  public async setActiveJobAlert(
    ctx: StateContext<UserStateModel>,
    { userId, activeJobAlert }: SetActiveJobAlert,
  ): Promise<void> {
    const user = await this.usersService.setActiveJobAlert(userId, activeJobAlert).toPromise();
    ctx.patchState({ user });
  }

  @Action(GetJobQuota)
  public async getJobQuota(ctx: StateContext<UserStateModel>): Promise<void> {
    const jobQuota = await this.usersService.fetchJobQuota().toPromise();
    ctx.patchState({ jobQuota });
  }

  @Action(GetUserDetails)
  public async getUserDetails(ctx: StateContext<UserStateModel>, { userId }: GetUserDetails): Promise<void> {
    const userDetails = await this.usersService.fetchUserDetails(userId).toPromise();
    ctx.patchState({ userDetails });
  }
}
