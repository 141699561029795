import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Unleash } from 'src/unleash';

@Directive({
  selector: '[appUnleash]',
})
export class UnleashDirective implements OnInit {
  flag: string;

  @Input() set appUnleash(flag: string) {
    this.flag = flag;
  }

  constructor(private viewRef: ViewContainerRef, private templateRef: TemplateRef<any>, private unleash: Unleash) {}

  ngOnInit() {
    this.viewRef.clear();
    if (this.unleash.isFlag(this.flag)) {
      this.viewRef.createEmbeddedView(this.templateRef);
    }
  }
}
