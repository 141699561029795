<div *ngIf="talent && profiles && profile">
  <div class="text-center">
    <div class="pb-3">
      <app-image
        *ngIf="talent.photo; else noPhoto"
        [image]="talent.photo.url"
        class="profile-pic d-inline-block"
        [lazy]="false"></app-image>
      <ng-template #noPhoto>
        <div class="profile-pic d-inline-block bg-gray"></div>
      </ng-template>
    </div>
    <div class="w-100 pb-2 flex-row d-flex align-items-center justify-content-center">
      <h2>{{ talent.profileName }}</h2>
      <app-icon *ngIf="talent.hasBadge" icon="/assets/img/memberships-badge.svg" class="size-5 ml-1"></app-icon>
    </div>

    <ng-container *ngIf="profile.user.reviews?.length > 0">
      <app-rating [average]="profile.averageReview" [count]="profile.totalReviews" class="rating pb-3"></app-rating>
    </ng-container>

    <div class="roles pb-2">
      <ng-container *ngFor="let profile of profiles; let i = index">
        <span *ngIf="i > 0">,&nbsp;</span>
        <span>{{ profile.category.translateKeySingular | translate }}</span>
      </ng-container>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <app-icon class="mr-1 d-inline-block" fill="white" icon="/assets/img/location.svg"></app-icon>
      <h5 class="d-inline-block font-weight-light">{{ talent.address }}</h5>
    </div>
    <h3 class="price">
      <app-money [amount]="profile.rate"></app-money>
    </h3>
    <!-- <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-between px-5">
        <app-add-to-deck-button [userProfile]="profile" class="ml-3 d-flex align-items-center">
          <span translate>Add to deck</span>
        </app-add-to-deck-button>
      </div>
    </div> -->
  </div>
  <hr />

  <ng-container *ngIf="referralList?.length >= 1">
    <p style="margin-bottom: 5px">Rekomendasi oleh:</p>
    <div class="recomendation-list">
      <div (click)="goToProfileDetail(referral)" *ngFor="let referral of referralList" class="recomendation-contact">
        <img
          [src]="referral.user.photo?.id ? getUserPhoto(referral.user.photo?.id) : '/assets/img/mobile/profile.svg'"
          alt="" />
      </div>
    </div>
    <hr />
  </ng-container>
  <!-- <div class="row mb-4">
    <div class="col-4 d-flex flex-column text-center">
      <app-icon class="mx-auto mb-2 size-5" icon="/assets/img/mobile.svg" stroke="white" strokeWidth="2"></app-icon>
      <span class="d-block my-auto" translate>Mobile Verification</span>
      <app-icon
        [stroke]="talent.mobileVerification ? '#00cb56' : '#000044'"
        class="size-5 mx-auto mt-2"
        icon="/assets/img/checkmark.svg"></app-icon>
    </div>
    <div class="col-4 d-flex flex-column text-center">
      <app-icon class="mx-auto mb-2 size-5" icon="/assets/img/email.svg" stroke="white"></app-icon>
      <span class="d-block my-auto" translate>E-mail Verification</span>
      <app-icon
        [stroke]="talent.emailVerification ? '#00cb56' : '#000044'"
        class="size-5 mx-auto mt-2"
        icon="/assets/img/checkmark.svg"></app-icon>
    </div>
    <div class="col-4 d-flex flex-column text-center">
      <app-icon class="mx-auto mb-2 size-5" fill="#fff" icon="/assets/img/profile.svg"></app-icon>
      <span class="d-block my-auto" translate>ID Verification</span>
      <app-icon
        [stroke]="talent.idVerification ? '#00cb56' : '#000044'"
        class="size-5 mx-auto mt-2"
        icon="/assets/img/checkmark.svg"></app-icon>
    </div>
  </div> -->
  <!--<div *ngIf="user && user.role === 'caster'" class="row mb-4">-->

  <div *ngIf="!user">
    <app-button
      style="margin-bottom: 10px"
      *ngIf="!user || (user && user.isCaster)"
      (click)="onOfferJobClicked()"
      buttonStyle="purple"
      id="offerJob">
      <div class="px-2" translate *ngIf="!user">Login to offer job</div>
    </app-button>
    <app-button buttonStyle="white-outline" *ngIf="!user" (click)="openLogin()">
      <div translate>Login to see contact details</div>
    </app-button>
  </div>
  <div *ngIf="user">
    <app-button style="margin-bottom: 10px" buttonStyle="purple" *ngIf="user && user.isAdmin" (click)="exportToPDF()">
      <div translate>Export to PDF</div>
    </app-button>
    <app-button
      *ngIf="user && (user && user.isCaster)"
      (click)="onOfferJobClicked()"
      buttonStyle="purple"
      id="offerJob">
      <div class="px-2" translate *ngIf="user && user.idVerification">Offer Job</div>
      <div class="px-2" translate *ngIf="user && !user.idVerification">Offer job requires ID verification</div>
    </app-button>
  </div>
  <div>
    <app-button
      style="margin-top: 10px"
      *ngIf="user && user.isCaster"
      (click)="seeContactDetail()"
      buttonStyle="white-outline"
      id="offerJob">
      <div class="px-2" translate>See contact detail</div>
    </app-button>
  </div>
  <hr />
  <p class="bold-text" translate>About Me</p>
  <p class="newlines">{{ profile.bio }}</p>
  <hr />

  <p translate>
    <app-icon
      class="d-inline-block size-4 mr-2"
      icon="/assets/img/language.svg"
      stroke="white"
      strokeWidth="2"></app-icon>

    <ng-container *ngFor="let language of talent.languages; let i = index">
      <span>
        {{ language.translateKey | translate }}
        <span>{{ language.native ? ' (Native)' : null }}</span>
        <span>{{(talent.languages.length>1 && talent.languages.length !== (i+1)) ?', ':''}}</span>
      </span>
    </ng-container>
  </p>

  <p>
    <app-icon
      class="d-inline-block size-4 mr-2"
      icon="/assets/img/briefcase.svg"
      stroke="white"
      strokeWidth="2"></app-icon>
    <span>Casterbee job: {{ profile.totalJobs }}</span>
  </p>
  <hr />

  <div class="row">
    <div class="col-12 d-flex flex-column">
      <div *ngIf="showDetails">
        <div class="row px-3 pb-2">
          <div class="col-8" translate>Years experience</div>
          <div class="col-4 text-right">{{ profile.yearsExperience }}</div>
        </div>

        <div class="row px-3" *ngFor="let category of profile.talentCategories; let i = index">
          <div class="col-6 col-sm-8" translate>
            <ng-container *ngIf="i == 0">Talent categories</ng-container>
          </div>
          <div class="col-6 col-sm-4 text-right" translate>{{ category.translateKey }}</div>
        </div>

        <div *ngFor="let detail of details" class="row px-3 py-2">
          <div class="col-6" translate>{{ detail.translateKey }}</div>
          <div class="col-6 text-right">
            <ng-container *ngIf="detail.type !== 'color'">
              {{ detail.values.join(', ') }} {{ detail.unit }}
            </ng-container>
          </div>
        </div>
      </div>

      <app-button
        (click)="showDetails = !showDetails"
        *ngIf="!showDetails"
        buttonStyle="white-outline"
        class="d-inline-block px-3 w-full mx-auto">
        <span translate>Show Details</span>
      </app-button>

      <a
        (click)="showDetails = !showDetails"
        *ngIf="showDetails"
        class="d-inline-block rounded-circle p-1 border mx-auto">
        <app-icon class="size-3" fill="white" icon="/assets/img/arrow-up.svg"></app-icon>
      </a>
    </div>
  </div>
</div>
