import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { ICategory, ICountry, IFilter, IMedia, IUser, IUserAlbum, IUserProfile } from '@core/interfaces';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 } from 'uuid';
import { plainToClass } from 'class-transformer';
import { Media } from '@core/models';
import { SaveUserProfile } from '@core/states';
import { Store } from '@ngxs/store';
import { cloneDeep, isEqual } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { MobileMembershipModalComponent } from '../mobile-membership-modal/mobile-membership-modal.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-account-profile-edit-modal',
  templateUrl: './mobile-account-profile-edit-modal.component.html',
  styleUrls: ['./mobile-account-profile-edit-modal.component.scss'],
})
export class MobileAccountProfileEditModalComponent implements OnChanges {
  @ViewChild('mobileAccountProfileEditModal') public modalComponent: MobileModalDialogComponent;

  @Output()
  public refreshProfile = new EventEmitter();

  @Input()
  public talentProfile: IUserProfile;

  @Input()
  public modalType = '';

  @Input()
  public category: ICategory;

  @Input()
  public user: IUser;

  @Input()
  public countries: ICountry[];

  public message: { message: string; type: string };

  public form: FormGroup;

  public isBusy = false;

  public refreshState = false;

  public filterInitialize = false;

  public filters: IFilter[] = [];

  public requiredFilters: IFilter[] = [];

  public isEditProfileSuccess = false;

  private formValueChanges: Subscription = new Subscription();

  public constructor(
    private store: Store,
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
  ) {}

  public get albums(): FormArray {
    return this.form.get('albums') as FormArray;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('category' in changes || 'talentProfile' in changes) {
      const categoryChanged = !isEqual(changes.category?.previousValue, changes.category?.currentValue);
      const talentProfileChanged = !isEqual(changes.talentProfile?.previousValue, changes.talentProfile?.currentValue);

      if (categoryChanged || talentProfileChanged) {
        this.createForm();
      }
    }
    if ('modalType' in changes) {
      const modalTypeChanged = !isEqual(changes.modalType?.previousValue, changes.modalType?.currentValue);
      if (modalTypeChanged) {
        this.resubscribeFormChages();
      }
    }
  }

  public resubscribeFormChages() {
    this.formValueChanges.unsubscribe();
    this.formValueChanges = this.form.valueChanges
      .pipe(
        debounceTime(500),
        takeWhile(() => !this.isBusy),
      )
      .subscribe((value) => {
        if (!this.isBusy && (this.modalType === 'photo' || this.modalType === 'video')) {
          this.onSubmit();
        }
      });
  }

  public async openDialog() {
    return await this.modalComponent.open().then(() => {
      if (this.refreshState) {
        this.refreshProfile.emit(true);
      }
    });
  }

  public clearValue(slug: string): void {
    (this.form.get(slug) as FormControl).setValue(null);
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.isBusy || this.form.invalid) {
        return;
      }

      this.isBusy = true;
      this.message = null;

      const categoryFilters = this.category.filters;
      const data: any = {};

      let filters = [];
      for (const slug of Object.keys(this.form.value)) {
        const formValue = this.form.value[slug];
        const categoryFilter = categoryFilters.find((f) => f.slug === slug);
        if (categoryFilter?.type === 'checkbox') {
          const talentProfileFilters = formValue
            .filter((c: any) => c.checked)
            .map((c: any) => ({
              id: v4(),
              filterId: categoryFilter.id,
              filterOptionId: c.value,
              userProfileId: this.talentProfile.id,
              value: null,
            }));

          filters = filters.concat(talentProfileFilters);
          continue;
        }

        if (['slider', 'color', 'number', 'text'].includes(categoryFilter?.type)) {
          if (formValue !== null && formValue !== '') {
            filters.push({
              id: v4(),
              filterId: categoryFilter.id,
              filterOptionId: null,
              userProfileId: this.talentProfile.id,
              value: formValue,
            });
          }
          continue;
        }

        data[slug] = formValue;
      }

      data.filters = filters;
      data.talentCategories = data.talentCategories.filter((c: any) => c.checked).map((c: any) => ({ id: c.value }));

      const formData = { ...data };
      formData.albums = formData.albums
        .filter((a) => a.media.length > 0)
        .map((a: IUserAlbum) => {
          a.media = a.media.map((m: IMedia) =>
            plainToClass(Media, {
              id: m.id,
              mediaType: m.mediaType,
              link: m.link,
              externalThumbUrl: m.externalThumbUrl,
              userId: m.userId,
              processed: m.processed,
              uploaded: undefined,
              order: m.order,
            }),
          );
          return a;
        });

      await this.store.dispatch(new SaveUserProfile(data.id, formData)).toPromise();
      this.resubscribeFormChages();

      this.refreshState = true;
      this.message = {
        message: await this.translate.get('Uw gegevens zijn opgeslagen').toPromise(),
        type: 'success',
      };
      this.isBusy = false;

      if (this.modalType === 'about') {
        this.isEditProfileSuccess = true;
      }
    } catch (e) {
      this.message = {
        message: await this.translate.get('Er is een onbekende fout opgetreden').toPromise(),
        type: 'error',
      };
    }
  }

  public editRole(): void {
    this.router.navigateByUrl(`/account/roles/${this.talentProfile.id}#modal=mobile-edit-profile`);
    this.modalComponent.close();
  }

  private createForm(): void {
    if (!this.talentProfile) {
      return;
    }
    this.filterInitialize = false;
    this.filters = this.category.filters.filter((filter) => !filter.required);
    this.requiredFilters = this.category.filters.filter((filter) => filter.required);

    const talentCategories = this.talentProfile.talentCategories.map((c) => ({ value: c.id, checked: true }));
    this.form = new FormGroup({
      id: new FormControl(this.talentProfile.id),
      userId: new FormControl(this.user?.id),
      bio: new FormControl(this.talentProfile.bio, [Validators.required]),
      rate: new FormControl(this.talentProfile.rate, [Validators.required]),
      yearsExperience: new FormControl(this.talentProfile.yearsExperience, [Validators.required]),
      completed: new FormControl(true),
      category: new FormControl({ id: this.category.id, slug: this.category.slug, label: this.category.label }),
      talentCategories: new FormControl(talentCategories),
      albums: this.fb.control(cloneDeep(this.talentProfile.albums), [Validators.required, Validators.min(1)]),
    });

    for (const filter of this.category.filters) {
      this.addCategoryFilterFormGroup(filter, this.talentProfile);
    }

    this.filterInitialize = true;

    this.resubscribeFormChages();
  }

  private addCategoryFilterFormGroup(filter: IFilter, talentProfile: IUserProfile): void {
    const talentProfileFilters = talentProfile.filters.filter((f: any) => f.filterId === filter.id);
    const formControl = new FormControl();

    if (filter.type === 'checkbox') {
      const value = [];
      for (const talentProfileFilter of talentProfileFilters) {
        value.push({
          value: talentProfileFilter.filterOption?.id,
          checked: true,
        });
      }

      formControl.setValue(value);
    }

    if (['slider', 'color', 'number', 'text'].includes(filter.type)) {
      const value =
        filter.type === 'number' ? talentProfileFilters[0]?.value || null : talentProfileFilters[0]?.value || '';
      formControl.setValue(value);
    }

    this.form.addControl(filter.slug, formControl);
  }
}
