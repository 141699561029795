import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { IReferralData } from '@core/models/referral.model';

@Component({
  selector: 'app-mobile-talent-recomendation-modal',
  templateUrl: './mobile-talent-recomendation-modal.component.html',
  styleUrls: ['./mobile-talent-recomendation-modal.component.scss'],
})
export class MobileTalentRecomendationModalComponent implements OnInit {
  @Output()
  public goToProfile = new EventEmitter();

  @ViewChild('appProfileRecomendationModal') private modalComponent: MobileModalDialogComponent;

  public referralList: IReferralData[] = [];

  constructor() {}

  ngOnInit(): void {}

  public async openDialog(referralData: IReferralData[]) {
    this.referralList = referralData;

    return await this.modalComponent.open().then(() => {});
  }

  public viewUserDetail(referral: IReferralData): void {
    this.goToProfile.emit(referral);
  }

  public getUserPhoto(photoId: string) {
    return `https://api.casterbee.co.id/media/thumb/${photoId}`;
  }

  public closeDialog(): void {
    this.modalComponent.close();
  }
}
