import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselsModule } from '../generic/carousels/carousels.module';
import { GradientsModule } from '../generic/gradients/gradients.module';
import { IconsModule } from '../generic/icons/icons.module';
import { MoneyModule } from '../money/money.module';
import { RatingModule } from '../rating/rating.module';

import { TalentCardComponent } from './components/talent-card/talent-card.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { TalentPDFCardComponent } from './components/talent-pdf-card/talent-pdf-card.component';
import { ApplicantCardComponent } from './components/applicant-card/applicant-card.component';
import { ImagesModule } from '../generic/images/images.module';
import { DecksModule } from '../decks/decks.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '../generic/forms/forms.module';
import { ButtonsModule } from '../generic/buttons/buttons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '@src/directive/directive.module';

const components = [TalentCardComponent, UserCardComponent, TalentPDFCardComponent, ApplicantCardComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    CarouselsModule,
    IconsModule,
    GradientsModule,
    ImagesModule,
    RatingModule,
    MoneyModule,
    DecksModule,
    TranslateModule,
    FormsModule,
    ButtonsModule,
    FontAwesomeModule,
    DirectiveModule,
  ],
  exports: components,
})
export class CardsModule {}
