import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IUser } from '@core/interfaces';
import { UsersService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

enum contactModalType {
  CONFIRMATION = 'CONFIRMATION',
  REQUEST_DOCUMENTED = 'REQUEST_DOCUMENTED',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  REQUEST_FORBIDDEN = 'REQUEST_FORBIDDEN',
}

@Component({
  selector: 'app-talent-contact-details',
  templateUrl: './talent-contact-details.component.html',
  styleUrls: ['./talent-contact-details.component.scss'],
})
export class TalentContactDetailsComponent implements OnInit {
  public talent: IUser;
  public contactModalType = contactModalType;
  public contactModal: contactModalType = contactModalType.CONFIRMATION;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<TalentContactDetailsComponent>,
    private userService: UsersService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.contactModal = contactModalType.CONFIRMATION;
  }

  public confirmContactDetails() {
    this.contactModal = contactModalType.REQUEST_DOCUMENTED;
    setTimeout(() => {
      this.userService.getContact(this.data.userId, this.data?.applicantId).subscribe(
        (result) => {
          this.contactModal = contactModalType.CONTACT_DETAILS;
          this.talent = result;
        },
        (error) => {
          this.contactModal = contactModalType.REQUEST_FORBIDDEN;
        },
      );
    }, 2000);
  }

  public upgradeMembership(): void {
    this.closeModal();
    this.store.dispatch(new Navigate(['/account/details/membership']));
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
