<ng-container *appScreenSize="'mobile'">
  <div *ngIf="showLoading" id="loading-x" class="loading-screen talent-horizontal">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </div>
</ng-container>

<svg class="svg-styles">
  <defs>
    <linearGradient id="orange-bright-svg-gradient-x">
      <stop offset="5%" stop-color="#f0493c" />
      <stop offset="95%" stop-color="#f6921e" />
    </linearGradient>
    <linearGradient id="purple-bright-svg-gradient-x">
      <stop offset="5%" stop-color="#5f43f0" />
      <stop offset="95%" stop-color="#8168f6" />
    </linearGradient>
  </defs>
</svg>

<ng-container *ngIf="regionAvailable; else unavailableInRegion">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #unavailableInRegion>
  <app-not-available isoCode="{{(region$ | async)?.isoCode}}"></app-not-available>
</ng-template>
