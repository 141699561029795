import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumberMask]',
})
export class NumberMaskDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const input = event.target;
    const inputValue = input.value.toString();

    // Remove non-digit characters
    const numericValue = inputValue.replace(/\D/g, '');

    // Format the numeric value as desired (e.g., add commas for thousands)
    const formattedValue = this.formatNumber(numericValue);

    // Update the input value with the formatted value without affecting form control value
    this.renderer.setProperty(input, 'value', formattedValue);

    // Set the form control value to the numeric value
    const numericFormControlValue = parseInt(numericValue, 10);
    if (!isNaN(numericFormControlValue)) {
      input.setAttribute('data-value', numericFormControlValue.toString());
    }
  }

  private formatNumber(value: string): string {
    // Format number as desired (e.g., add commas for thousands)
    // Here, we add commas for thousands
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
