<ng-container *ngIf="applicants.length > 0">
  <div class="section-title">
    <p>{{title}}</p>
    <div class="panel" *ngIf="!job.completed">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input custom-control"
          [checked]="statusCheckedAllApplicant(applicants)"
          (change)="applicantCheckedAll($event,applicants)"
          id="{{title}}" />
        <label class="custom-control-label" for="{{title}}">Select All</label>
      </div>
    </div>
  </div>
  <div class="talent">
    <div class="row">
      <ng-container *ngFor="let applicant of applicants">
        <div class="col-6">
          <div
            class="wrap-applicant"
            [ngClass]="title === 'Canceled or rejected' ? 'backdrop' : '' || applicant.status == 'castercancelbooking' ? 'backdrop' : ''">
            <div class="notification">
              <div *ngIf="haveUrgentMessage(applicant)" class="notif-badge notif-badge-danger"></div>
              <p *ngIf="haveUrgentMessage(applicant)">{{ urgentMessage(applicant) | translate | truncate:25}}</p>
            </div>
            <div class="card-talent-applicant">
              <div class="panel" *ngIf="!job.completed">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    (change)="applicantChecked($event,applicant)"
                    [checked]="statusCheckedPerApplicant(applicant)"
                    class="custom-control-input custom-control"
                    id="{{applicant.user.id}}" />
                  <label class="custom-control-label" for="{{applicant.user.id}}"></label>
                </div>
              </div>
              <div class="info">
                <div class="wrap-info">
                  <div class="name">
                    <p class="text-bold">
                      {{applicant.user?.profileName}}
                      <img *ngIf="applicant.user?.hasBadge" src="/assets/img/mobile/badge.svg" alt="badge" />
                    </p>
                  </div>
                  <div class="ratting" *ngIf="applicant.user.averageReview > 0">
                    <p>
                      <img src="/assets/img/mobile/ratting.svg" alt="ratting" />
                      <span class="rate-number">{{applicant.user.averageReview}}</span>
                      ({{applicant.user.totalReviews}})
                    </p>
                  </div>
                  <small>
                    {{'Native language' | translate}}: {{ nativeLanguage(applicant)?.translateKey | translate }}
                  </small>
                </div>
                <!-- request video -->
                <button
                  class="btn btn-video"
                  *ngIf="showRequestVideoButton(applicant)"
                  (click)="requestTalentVideo(applicant)">
                  <img src="/assets/img/mobile/play.svg" alt="" />
                  {{'Request video' | translate}}
                </button>
                <!-- watch video -->
                <button class="btn btn-video" *ngIf="showVideoButton(applicant)" (click)="viewTalentVideo(applicant)">
                  <img src="/assets/img/mobile/play.svg" alt="" />
                  {{'Watch Video' | translate}}
                </button>
                <!-- video requested -->
                <button
                  class="btn btn-video"
                  *ngIf="showVideoRequestedInfo(applicant)"
                  (click)="showVideoRequestedInfo(applicant)">
                  <img src="/assets/img/mobile/play.svg" alt="" />
                  {{'Video requested' | translate | truncate:10:false}}
                </button>
                <div class="addtional">
                  <!-- Profile -->
                  <button class="btn btn-bg-white" (click)="userDetail(applicant)">View Profile</button>
                  <!-- View contact detail -->
                  <button class="btn btn-bg-white" (click)="viewContactDetail(applicant)">See contact details</button>
                  <!-- Message -->
                  <button *appUnleash="'messages'" class="btn btn-bg-transparent" (click)="openChatModal(applicant)">
                    {{'Message' | translate}}
                  </button>
                  <!-- Request travel costs -->
                  <button
                    class="btn btn-bg-purple"
                    *ngIf="showSendTravelCostsRequest(applicant)"
                    (click)="requestTravelCosts(applicant)">
                    {{'Request travel costs' | translate | truncate:18:false}}
                  </button>
                  <ng-container *appUnleash="'travel-cost'">
                    <!-- Travel costs requested -->
                    <button class="btn btn-clear" *ngIf="showRequestedTravelCosts(applicant)">
                      {{'Travel costs requested' | translate | truncate:18:false}}
                    </button>
                    <!-- Travel costs rejected -->
                    <button class="btn btn-clear" *ngIf="applicant.status === JobApplicationStatus.travelCostRejected">
                      {{'Travel costs rejected' | translate | truncate:18:false}}
                    </button>
                    <!-- Travel costs approved -->
                    <button class="btn btn-clear" *ngIf="applicant.status === JobApplicationStatus.travelCostApproved">
                      {{'Travel costs approved' | translate | truncate:18:false}}
                    </button>
                    <!-- See travel cost -->
                    <button
                      class="btn btn-bg-purple"
                      *ngIf="applicant.status === JobApplicationStatus.travelCostPending"
                      (click)="travelCosts(applicant)">
                      {{'See travel cost' | translate | truncate:18:false}}
                    </button>
                  </ng-container>
                  <!-- Request to book -->
                  <button
                    class="btn btn-bg-purple"
                    *ngIf="showRequestToBookWithPaymentButton(applicant) && !showSendTravelCostsRequest(applicant)"
                    (click)="requestToBookAndCreatePayment(applicant)">
                    {{'Request to book' | translate | truncate:18:false}}
                  </button>
                  <button
                    class="btn btn-bg-purple"
                    *ngIf="showRequestToBookBypassPaymentButton(applicant) && !showSendTravelCostsRequest(applicant)"
                    (click)="requestToBook(applicant)">
                    {{'Request to book' | translate | truncate:18:false}}
                  </button>
                  <!-- Complete Payment -->
                  <!-- <button
                    class="btn btn-bg-purple"
                    *ngIf="showCreatePaymentButton(applicant)"
                    (click)="createPayment(applicant)">
                    {{'Complete Payment' | translate}}
                  </button> -->
                  <!-- Release payment -->
                  <button
                    class="btn btn-bg-purple"
                    *ngIf="showReleasePaymentButton(applicant)"
                    (click)="releasePayment(applicant,0)">
                    {{'Release payment' | translate}}
                  </button>
                  <!-- Request refund -->
                  <button
                    class="btn btn-bg-purple"
                    *ngIf="showRequestRefundButton(applicant)"
                    (click)="requestRefundPayment(applicant)">
                    {{'Request refund' | translate}}
                  </button>
                  <!-- Reject -->
                  <button
                    class="btn btn-bg-gray"
                    *ngIf="showRejectApplicantButton(applicant)"
                    (click)="reject(applicant)">
                    {{'Reject' | translate}}
                  </button>
                  <!-- Cancel booking -->
                  <button
                    class="btn btn-bg-gray"
                    *ngIf="showCancelBookingButton(applicant)"
                    (click)="cancelBooking(applicant)">
                    {{'Cancel Booking' | translate}}
                  </button>
                  <!-- Cancel booking request -->
                  <button
                    class="btn btn-bg-gray"
                    *ngIf="showCancelRequestBookingButton(applicant)"
                    (click)="cancelBooking(applicant)">
                    {{'Cancel booking request' | translate | truncate:18:false}}
                  </button>
                </div>
              </div>
              <div class="photo" style="background-image: url({{applicant.user?.photo?.thumbUrl}});"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="talent-separator"></div>
  </div>
  <app-mobile-request-book-modal #requestToBookModal></app-mobile-request-book-modal>
  <app-mobile-cancel-booking-modal #cancelBookingModal [title]="cancelBookingTitle"></app-mobile-cancel-booking-modal>
  <app-mobile-cancel-booking-modal
    #rejectApplicant
    [title]="'Are you sure want to reject this talents?'"
    [jobDetail]="job"></app-mobile-cancel-booking-modal>
  <app-mobile-detail-travel-cost-modal #travelCostDetail></app-mobile-detail-travel-cost-modal>
  <app-mobile-request-video-modal #requestVideoModal></app-mobile-request-video-modal>
  <app-mobile-success-modal #requestTravelCost>
    <app-mobile-success [message]="'Travel cost request has been sent'"></app-mobile-success>
  </app-mobile-success-modal>
  <app-mobile-talent-contact-modal
    [talent]="applicantDetail?.user"
    [applicantId]="applicantDetail?.id"
    #contactModal></app-mobile-talent-contact-modal>
</ng-container>
