<section class="feature">
  <div class="swiper-feature">
    <div class="wrap-scroll">
      <ul>
        <li class="feature-prev" translate>For Casters</li>
        <li class="feature-next" translate>For Talents</li>
      </ul>
      <div class="swiper-scrollbar"></div>
    </div>
    <div class="swiper-wrapper">
      <div class="card-feature swiper-slide">
        <div class="header">
          <img src="/assets/img/mobile/feature/feature-2.png" alt="" />
          <div class="info">
            <h6 translate>How it works</h6>
            <p translate>Directly in touch with Talents</p>
          </div>
        </div>
        <ol>
          <li translate>Directly in touch with Talents</li>
          <li translate>You are in control. Casterbee offer you the tools</li>
          <li translate>Streamline your work process and avoid time-consuming manual processes</li>
          <li translate>See all info, including contact details instantly</li>
          <li translate>Complete days of works in literally minutes</li>
        </ol>
        <button class="btn" (click)="signUp('caster')" type="button">{{'Place job' | translate}}</button>
      </div>
      <div class="card-feature swiper-slide">
        <div class="header">
          <img src="/assets/img/mobile/feature/feature-1.png" alt="" />
          <div class="info">
            <h6 translate>How it works</h6>
            <p translate>Get visible for Casters and get booked</p>
          </div>
        </div>
        <ol>
          <li translate>Get visible for Casters and get booked</li>
          <li translate>Increase your visibility for free with photos and videos</li>
          <li translate>Get immediate response as you are in direct contact with clients</li>
          <li translate>Respond to posted jobs or receive direct job offers</li>
          <li translate>Casterbee charges 0% commission</li>
        </ol>
        <button class="btn" (click)="signUp('talent')" type="button" translate>{{'Get booked' | translate}}</button>
      </div>
    </div>
  </div>
</section>
<app-mobile-auth-modal #loginModal></app-mobile-auth-modal>
