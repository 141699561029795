<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #mobileApplyJob>
  <ng-container *ngIf="!isSuccess; else success">
    <ng-container *ngIf="!isAllowedToApply">
      <div class="modal-body">
        <div class="request-tobook-info">
          <h6>{{'Applying is not possible' | translate}}</h6>
          <p class="text-center" translate>Your profile falls outside the criteria. Possible reasons:</p>
          <ol class="mt-3">
            <li translate>Caster is looking for a different gender</li>
            <li translate>For example, the job is for models, but you are an actor</li>
            <li translate>You are outside the age category</li>
          </ol>
        </div>
      </div>
      <div class="modal-footer">
        <div class="wrap-btn mb-2">
          <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(data !== null || data !== undefined) && isAllowedToApply">
      <ng-container *ngIf="jobQuota !== undefined">
        <ng-container *ngIf="jobQuota.available > 0 || jobQuota.unlimited; else upgradeMembership">
          <div class="modal-body">
            <div class="text-center">
              <h6 class="text-bold mb-4" style="font-size: 26px" translate>APPLY TO JOB?</h6>
              <p translate>Great, you almost applied! The Caster can book, reject or send you a message.</p>
              <p translate>
                When accepted, you have a binding agreement with the Caster about this specific assignment.
              </p>
              <p translate>When applied, the job shows at your applied jobs in the dashboard</p>
              <p class="mt-5" *ngIf="!jobQuota.unlimited">
                <span class="text-bold">
                  {{'You still have' | translate}} {{jobQuota.available}} {{'jobapplications left.' | translate}}
                </span>
                <br />
                {{'Please use them wisely.' | translate}}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-bg-purple"
              (click)="applyJob()"
              style="border-radius: 100px; width: 182px; margin: auto">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">{{ 'Apply' | translate }}</span>
            </button>
            <button type="button" class="btn btn-block" (click)="close()">{{'Cancel' | translate}}</button>
          </div>
        </ng-container>
        <ng-template #upgradeMembership>
          <div class="modal-body">
            <h6 class="text-bold text-center mb-3" style="font-size: 26px" translate>
              Oh no! You've run out of credits.
            </h6>
            <p class="text-center" style="font-style: italic" translate>
              You need 1 credit to apply to this job. Quickly buy credits or get a membership
            </p>
            <div class="text-center">
              <ng-container *ngIf="creditPackages$ | async as creditPackages">
                <button
                  *ngFor="let creditPackage of creditPackages;"
                  type="button"
                  (click)="buyCredit(creditPackage)"
                  class="btn-extra btn-solid-white mb-3"
                  translate>
                  {{'Buy'|translate}} {{creditPackage.amount}} {{'credits'|translate}} Rp {{creditPackage.price}}
                </button>
              </ng-container>
            </div>
            <div class="text-center">
              <button type="button" (click)="directToMembership()" class="btn-extra btn-bg-purple" translate>
                Upgrade membership
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <div class="wrap-btn mb-2">
              <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #success>
    <div class="modal-body">
      <app-mobile-success [message]="'You applied to the job.'">
        <p class="text-center" translate>When the Caster accepts your application you will get a notification</p>
      </app-mobile-success>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
<app-mobile-upload-video-modal #uploadVideoModal></app-mobile-upload-video-modal>
