import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUserProfile, MediaType } from '@core/interfaces';
import { ICategory } from '@core/interfaces/category';
import { Language, UserProfile, UserProfileFilter } from '@core/models';
import { UserProfilesService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import SwiperCore from 'swiper';
@Component({
  selector: 'app-mobile-talent-slide',
  templateUrl: './mobile-talent-slide.component.html',
  styleUrls: ['./mobile-talent-slide.component.scss'],
})
export class MobileTalentSlideComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('swiperRef', { static: false }) sliderRef?: MobileTalentSlideComponent;

  @Input()
  public category: ICategory;
  public swiper: SwiperCore;
  public talents: Array<UserProfile> = [];
  public isProcess = true;
  private talentModelIDs = [
    '18927223-cd3f-48f1-8cc8-c94d083ee1c3',
    'c9b39228-0e18-4aaf-a172-38c05c38024e',
    '94e1c9db-9b94-4537-9b18-3edb720ecee7',
    '2d44e564-ac2d-4149-882d-54c41899a107',
    '475f2cfa-d16f-400c-8521-95f07f0e09bd',
  ];
  private talentActorIDs = [
    'db4c11e2-8b6c-4457-b475-860e9e0617aa',
    '4e55084a-4ef5-42a0-ac86-7766c366b537',
    'fd2611df-90be-4642-a354-1dd09a0acc86',
  ];
  private talentInfluencersIDs = ['c96cdfbc-e7e2-430b-87c3-2efa3a1238d6'];
  private talentPresenterIDs = ['6ca91451-3365-452c-864a-5582ab99b804'];
  constructor(private userProfilesService: UserProfilesService, private route: ActivatedRoute, private store: Store) {}
  ngOnInit(): void {
    this.loadTalents();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('category' in changes) {
      this.loadTalents();
    }
  }

  public tallBody(talent: UserProfile): UserProfileFilter {
    return talent.filters.filter((res) => {
      if (res.filter.slug === 'height') {
        return res;
      }
    })?.[0];
  }

  public onClick(talent: UserProfile): void {
    this.store.dispatch(
      new Navigate(['profile', talent.user.id, talent.id], this.route.snapshot.queryParams, {
        relativeTo: this.route,
      }),
    );
  }

  public nativeLanguage(userProfile: IUserProfile): Language {
    return userProfile.user.languages.filter((userLanguage) => userLanguage.native)?.[0];
  }

  public getProfilePic(userProfile: IUserProfile) {
    const photoList = userProfile.albums.find((obj) => obj.albumType === MediaType.photo);
    if (photoList.media[0]) {
      return `url('${photoList.media[0].thumbUrl}')`;
    } else {
      return `url('${userProfile.user?.photo?.thumbUrl}')`;
    }
  }

  private async loadTalents(): Promise<void> {
    this.talents = [];
    let talentIDs = this.talentActorIDs;

    if (this.category != null) {
      switch (this.category.slug) {
        case 'models':
          talentIDs = this.talentModelIDs;
          break;
        case 'actors':
          talentIDs = this.talentActorIDs;
          break;
        case 'influencers':
          talentIDs = this.talentInfluencersIDs;
          break;
        case 'host-and-promotors':
          talentIDs = this.talentPresenterIDs;
          break;
      }
    }

    const profileDataPromises = [];
    this.isProcess = true;

    talentIDs.forEach((id: string) => {
      profileDataPromises.push(this.userProfilesService.getById(id).toPromise());
    });

    try {
      const result = await Promise.all(profileDataPromises);
      result.forEach((profileData) => {
        if (!!profileData && !this.talents.find((value) => value.id === profileData.id)) {
          this.talents.push(profileData);
        }
      });

      setTimeout(() => {
        this.swiper = new SwiperCore('.swiper-talent', {
          slidesPerView: 'auto',
          observer: true,
        });
      }, 500);

      this.isProcess = false;
    } catch (error) {
      // console.log("Failed to load profile data", error)
    }
  }
}
