import { Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IMedia } from '@core/interfaces';
import { User, UserProfile } from '@core/models';
import { RouterHelperService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-talent-photoalbum-overview',
  templateUrl: './talent-photoalbum-overview.component.html',
  styleUrls: ['./talent-photoalbum-overview.component.scss'],
})
export class TalentPhotoalbumOverviewComponent implements OnChanges {
  @Input()
  public talent: User;

  @Input()
  public profiles: UserProfile[];

  @Input()
  public profile: UserProfile;

  @ViewChildren('photoContainer') photoContainer: QueryList<ElementRef>;

  public mediaTypes = [
    { title: 'Photo', key: 'photo', icon: '/assets/img/photo.svg' },
    { title: 'Video', key: 'video', icon: '/assets/img/video.svg' },
  ];

  public selectedMediaType = this.mediaTypes[0];
  public photoalbumContainerHeight = 500;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private routerHelper: RouterHelperService,
    private dialog: MatDialog,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('profile' in changes) {
      this.getMasonryLayoutHeightContainer();
    }
  }

  public openMedia(media: IMedia): void {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        media,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async selectProfile(profile: UserProfile): Promise<void> {
    const route = await this.routerHelper.activeRoute$.pipe(first()).toPromise();
    this.store.dispatch(
      new Navigate(['profile', this.talent.id, profile.id], this.route.snapshot.queryParams, {
        relativeTo: route.parent,
      }),
    );
  }

  public hasAlbumType(key: any): boolean {
    return this.profile.albums.some((a) => a.albumType === key);
  }

  public getMasonryLayoutHeightContainer() {
    // Set the flag to true to indicate that the code has been processed
    setTimeout(async () => {
      const leftImageList: number[] = [];
      const rightImageList: number[] = [];

      // Create an array of promises for each child element's asynchronous task
      const promises = this.photoContainer.map(async (child, index) => {
        const childHeight = child.nativeElement.offsetHeight;
        if (index % 2 === 0) {
          leftImageList.push(childHeight + 12);
        } else {
          rightImageList.push(childHeight + 12);
        }
      });

      // Wait for all promises to be resolved
      await Promise.all(promises);
      if (!leftImageList.some((value) => value < 40) && !rightImageList.some((value) => value < 40)) {
        const leftImageTotalHeight = leftImageList.reduce((accumulator, currentNumber) => {
          const adjustedNumber = currentNumber < 50 ? 50 : currentNumber;
          return accumulator + adjustedNumber;
        }, 0);
        const rightImageTotalHeight = rightImageList.reduce((accumulator, currentNumber) => {
          const adjustedNumber = currentNumber < 50 ? 50 : currentNumber;
          return accumulator + adjustedNumber;
        }, 0);

        const containerHeight: number = Math.max(leftImageTotalHeight, rightImageTotalHeight);
        this.photoalbumContainerHeight = containerHeight;
      }
    }, 5000);
  }
}
