<div *ngIf="profile" class="mobile-talent-about-container">
  <p>
    <label translate>Bio</label>
    <span>{{ profile.bio }}</span>
  </p>
  <p>
    <label translate>Years experience</label>
    <span>{{ profile.yearsExperience }}</span>
  </p>
  <p *ngFor="let category of profile.talentCategories; let i = index">
    <label translate>Talent categories</label>
    <span translate>{{ category?.translateKey }}</span>
  </p>
  <p *ngFor="let detail of details">
    <label translate>{{ detail.translateKey }}</label>
    <span *ngIf="detail.type !== 'color'">{{ detail.values.join(', ') }} {{ detail.unit }}</span>
  </p>
</div>
