export const environment = {
  production: true,
  flavor: 'idprd',
  baseUrl: 'https://casterbee.co.id',
  apiBaseUrl: 'https://api.casterbee.co.id',
  cookieDomain: 'www.casterbee.co.id',
  vat: 0.21,
  unleashKey: 'default:production.2a2b8a1e-ceb5-4a8b-820e-daaf3d409239',
  currency: 'Rp',
  locale: 'ID',
};
