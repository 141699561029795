import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { JobApplication, Media } from '@core/models';
import { IJob, MediaType } from '@core/interfaces';
import { v4 } from 'uuid';
import { Store } from '@ngxs/store';
import { ApplyToJob, UploadVideoForJobApplication } from '@core/states';
import { EmbedVideoService } from '@core/services';
import { FileService } from '@src/ui/generic/images/services/file.service';
import { DomSanitizer } from '@angular/platform-browser';

interface MobileUploadVideo {
  jobApplication?: JobApplication;
  userID?: string;
  job: IJob;
}

@Component({
  selector: 'app-mobile-upload-video-modal',
  templateUrl: './mobile-upload-video-modal.component.html',
  styleUrls: ['./mobile-upload-video-modal.component.scss'],
})
export class MobileUploadVideoModalComponent implements OnInit {
  @ViewChild('mobileUploadVideo') public modalComponent: MobileModalDialogComponent;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('linkInput') linkInput!: ElementRef;

  public isSuccess: boolean;
  public isBusy: boolean;
  public isApply: boolean;
  public data?: MobileUploadVideo;
  public video?: {
    id: string;
    link?: string;
    thumbUrl?: string;
    fileType?: string;
    type: 'url' | 'upload';
  };

  constructor(
    private store: Store,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private embedVideoService: EmbedVideoService,
  ) {}

  ngOnInit(): void {}

  public async openDialog(data: MobileUploadVideo) {
    this.data = data;

    if (this.data.userID === undefined) {
      this.isApply = false;
    } else {
      this.isApply = true;
    }

    return await this.modalComponent.open().then(() => {
      this.video = null;
      this.isBusy = false;
      this.data = null;
      if (this.isSuccess && data.userID !== '') {
        window.location.reload();
        this.isSuccess = false;
      }
    });
  }

  public async setValue(ev: any, type: 'url' | 'upload') {
    const id: string = v4();
    if (type === 'url') {
      let link: string = ev.target.value;
      if (ev as ClipboardEvent) {
        link = ev.clipboardData?.getData('text');
      }

      const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
      const regex = new RegExp(expression);
      if (link !== undefined && link !== null && regex.test(link)) {
        this.fileInput.nativeElement.value = '';
        const imageUrl = await this.embedVideoService.embedImage(link);
        if (!!imageUrl) {
          this.video = {
            id,
            link: ev.target.value,
            thumbUrl: imageUrl.link,
            type,
          };
        }
      }
    } else {
      this.isBusy = true;
      this.linkInput.nativeElement.value = '';
      const file = ev.target.files[0] as File;
      const fileURL = URL.createObjectURL(file);
      this.video = {
        id,
        fileType: file.type,
        link: fileURL,
        type,
      };
      await this.fileService
        .upload({
          id,
          name: file.name,
          type: file.type,
          size: file.size,
          target: {
            type: 'jobApplication',
            payload: this.data.job.id,
          },
          data: await this.fileService.readFile(file),
        })
        .toPromise();
      this.isBusy = false;
    }
  }

  public removeVideo() {
    this.linkInput.nativeElement.value = '';
    this.fileInput.nativeElement.value = '';
    this.video = null;
  }

  public openMedia() {
    this.fileInput.nativeElement.click();
  }
  public close() {
    this.modalComponent.close();
  }

  public submit(): void {
    if (this.video === null || this.video === undefined) {
      return;
    }

    this.isBusy = true;

    const payload: Media = {
      id: this.video.id,
      mediaType: MediaType.video,
      processed: false,
      uploaded: true,
      link: this.video?.link,
      thumbUrl: '',
      url: '',
    };

    if (this.video !== null && this.video !== undefined) {
      if (this.data.jobApplication === null || this.data.jobApplication === undefined) {
        this.store.dispatch(new ApplyToJob(this.data.userID, this.data.job.id, payload));
      } else {
        this.store.dispatch(new UploadVideoForJobApplication(this.data.jobApplication.id, payload));
      }
      this.isBusy = false;
      this.isSuccess = true;
    }
  }

  public applyWithVideo() {
    this.isApply = false;
  }

  public skipAndApply(): void {
    this.isApply = false;
    this.isBusy = true;
    this.store.dispatch(new ApplyToJob(this.data.userID, this.data.job.id));
    this.isBusy = false;
    this.isSuccess = true;
  }
}
