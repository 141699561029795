<div class="job-request-card d-flex align-items-center flex-column" *ngIf="jobApplication">
  <ng-container *ngIf="!isSuccess else successMessage">
    <div class="title" translate>Request to book</div>
    <div class="desc text-center" translate>You are about to send a booking request</div>

    <ul style="color: #fff; margin-top: 20px">
      <li translate>The Talent can accept or reject your booking request.</li>
    </ul>

    <div class="close-btn">
      <app-solid-button
        (btnClick)="acceptJobApplication()"
        [isDisabled]="isBusy"
        [isBusy]="isBusy"
        type="button"
        buttonStyle="purple-blue">
        {{ "Send booking request" | translate }}
      </app-solid-button>
    </div>
    <div class="close-btn">
      <app-solid-button (btnClick)="closeModal()" [isDisabled]="isBusy" type="button" buttonStyle="transparent-white">
        {{ "Close" | translate }}
      </app-solid-button>
    </div>
  </ng-container>

  <ng-template #successMessage>
    <div class="success-title" translate>Booking request has been sent</div>
    <div class="success-img mt-2">
      <img src="assets/img/checkmark-white.svg" alt="Success" />
    </div>

    <div class="close-btn">
      <app-solid-button (btnClick)="closeModal()" type="button" buttonStyle="purple-blue">
        {{ "Close" | translate }}
      </app-solid-button>
    </div>
  </ng-template>
</div>

<button mat-button mat-dialog-close>&times;</button>
