import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { JobApplication, LocalFile, Media, TravelExpense } from '@core/models';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { FetchJobApplication, JobsState, SaveJobApplicationTravelExpenses } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';
import { TravelExpenseType } from '@core/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@src/ui/generic/images/services/file.service';
import { JobApplicationsService } from '@core/services';

@Component({
  selector: 'app-mobile-post-travel-cost-modal',
  templateUrl: './mobile-post-travel-cost-modal.component.html',
  styleUrls: ['./mobile-post-travel-cost-modal.component.scss'],
})
export class MobilePostTravelCostModalComponent implements OnInit {
  @ViewChild('appPostTravelCostModal') private modalComponent: MobileModalDialogComponent;

  public jobApplication: JobApplication;
  public isSuccess: boolean;
  public isBusy: boolean;
  public form: FormGroup = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    travelExpenses: new FormArray([], [Validators.required]),
    travelReceipts: new FormArray([]),
  });

  constructor(
    private store: Store,
    private fileService: FileService,
    private jobApplicationService: JobApplicationsService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  async openDialog(data: JobApplication): Promise<void> {
    this.jobApplicationService.getJobApplication(data.id).subscribe((res) => {
      if (res !== null) {
        this.form.patchValue(res);
        res?.travelExpenses.forEach((te: TravelExpense) =>
          this.travelExpenses.push(
            new FormGroup({
              id: new FormControl(te.id, [Validators.required]),
              type: new FormControl(te.type, [Validators.required]),
              description: new FormControl(te.description),
              distance: new FormControl(te.distance),
              time: new FormControl(te.time),
              price: new FormControl(te.price, [Validators.required]),
            }),
          ),
        );
        res?.travelReceipts.forEach((receipt: Media) =>
          this.travelReceipts.push(
            new FormGroup({
              id: new FormControl(receipt.id, [Validators.required]),
              name: new FormControl(receipt.id, [Validators.required]),
              url: new FormControl(receipt.url, null),
            }),
          ),
        );
        if (res?.travelExpenses?.length <= 0) {
          this.addTravelExpense();
        }

        this.jobApplication = res;
      }
    });

    return await this.modalComponent.open().then(() => {
      this.isBusy = false;
      this.isSuccess = false;
      this.jobApplication = null;
      this.form.reset();
      (this.form.controls['travelReceipts'] as FormArray).clear();
      (this.form.controls['travelExpenses'] as FormArray).clear();
    });
  }

  public get travelExpenses(): FormArray {
    return this.form.get('travelExpenses') as FormArray;
  }

  public get travelReceipts(): FormArray {
    return this.form.get('travelReceipts') as FormArray;
  }

  public addTravelExpense(): void {
    this.travelExpenses.push(
      new FormGroup({
        id: new FormControl(v4(), [Validators.required]),
        type: new FormControl(TravelExpenseType.kilometers, [Validators.required]),
        description: new FormControl(null),
        distance: new FormControl(null),
        time: new FormControl(null),
        price: new FormControl(null, [Validators.required]),
      }),
    );
    this.calculationPrice(this.travelExpenses.length - 1);
  }

  public removeTravelExpense(index: number): void {
    this.travelExpenses.removeAt(index);
  }

  public removeReceipt(index: number): void {
    this.travelReceipts.removeAt(index);
  }

  public calculationPrice(i: number) {
    const formGroup = this.travelExpenses.controls[i];

    formGroup.get('distance').clearValidators();
    formGroup.get('time').clearValidators();
    formGroup.get('distance').clearAsyncValidators();
    formGroup.get('time').clearAsyncValidators();

    formGroup.patchValue({
      price: null,
      time: null,
      distance: null,
    });

    switch (formGroup.get('type').value) {
      case TravelExpenseType.kilometers:
        formGroup.get('distance').setValidators([Validators.required]);
        formGroup
          .get('price')
          .setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(this.jobApplication?.job?.maxTravelingCost),
          ]);
        break;
      case TravelExpenseType.parking:
        formGroup.get('time').setValidators([Validators.required]);
        formGroup
          .get('price')
          .setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(this.jobApplication?.job?.maxParkingCost),
          ]);
        break;
    }

    formGroup.get('distance').valueChanges.subscribe((distance: number): void => {
      if (distance === null) {
        formGroup.patchValue({
          price: null,
        });
      } else {
        const max: number = Math.round(distance * this.jobApplication.job.travelingCost * 100) / 100;
        formGroup.patchValue({
          price: Math.min(max, this.jobApplication?.job?.maxTravelingCost ?? max),
        });
      }
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public addReceipt(): void {
    this.travelReceipts.push(
      new FormGroup({
        id: new FormControl(v4(), [Validators.required]),
        name: new FormControl(null, [Validators.required]),
        url: new FormControl(null, []),
      }),
    );
  }

  public async uploadReceipt(files: File[], id: string, index: number): Promise<void> {
    const receipt: File = files[0];

    if (!receipt) {
      return;
    }

    this.isBusy = true;

    const fileResult: LocalFile = await this.fileService
      .uploadAndResize(
        {
          id,
          name: receipt.name,
          type: receipt.type,
          size: receipt.size,
          target: {
            type: 'receipt',
            payload: id,
          },
          data: await this.fileService.readFile(receipt),
        },
        1000,
      )
      .toPromise();

    this.isBusy = false;

    this.travelReceipts.at(index).patchValue({
      id,
      name: fileResult.name,
      url: await this.fileService.readFile(receipt),
    });
  }

  public async submit(): Promise<void> {
    const translation = await this.translate
      .get('Are you sure you want to submit your travel costs? This can not be undone.')
      .toPromise();
    if (confirm(translation) === false) {
      return;
    }

    this.isBusy = true;
    const jobApplication: JobApplication = this.form.value;
    this.store.dispatch(
      new SaveJobApplicationTravelExpenses(
        jobApplication.id,
        jobApplication.travelExpenses,
        jobApplication.travelReceipts.map((item: Media): Partial<Media> => ({ id: item.id })),
      ),
    );

    this.isSuccess = true;
    this.isBusy = false;
  }
}
