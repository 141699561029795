import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-invitation-confirmation-modal',
  templateUrl: './mobile-invitation-confirmation-modal.component.html',
  styleUrls: ['./mobile-invitation-confirmation-modal.component.scss'],
})
export class MobileInvitationConfirmationModalComponent implements OnInit {
  @Output() public userAction = new EventEmitter();
  @ViewChild('appRemoveInvitaionModal') private modalComponent: MobileModalDialogComponent;

  constructor() {}

  ngOnInit(): void {}

  async openDialog(): Promise<void> {
    return await this.modalComponent.open().then(() => {});
  }

  public selectUserAction(isNeedSignup: boolean): void {
    this.userAction.emit(isNeedSignup);
  }

  public closeDialog(): void {
    this.modalComponent.close().then(() => {});
  }
}
