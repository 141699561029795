<ng-container *appScreenSize="'dekstop'">
  <section class="container p-3" *ngIf="!isLoading">
    <div *ngIf="profile$ | async as profile; else notFound">
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="row px-2 mb-4">
            <div class="w-full content rounded-sm panel">
              <app-about-talent
                (offerJobClicked)="openOfferJobModal()"
                [profile]="profile"
                [profiles]="profiles$ | async"
                [talent]="profile.user"
                [user]="user$ | async"></app-about-talent>
            </div>
          </div>

          <div class="row px-2" *ngIf="profile.user.reviews?.length > 0">
            <div class="content w-100 rounded-sm panel mb-4">
              <app-talent-ratings [talent]="profile.user"></app-talent-ratings>
            </div>
          </div>

          <div class="row px-2">
            <div class="content w-100 rounded-sm panel mb-4">
              <app-talent-action-buttons [talent]="profile.user"></app-talent-action-buttons>
            </div>
          </div>

          <div *ngIf="(user$ | async)?.role?.includes('caster')" class="row px-2">
            <div class="content w-100 rounded-sm panel mb-4">
              <app-talent-personal-notes [talent]="profile.user"></app-talent-personal-notes>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="row px-2 mb-4">
            <div class="content rounded-sm w-100">
              <app-talent-photoalbum-overview
                [profile]="profile"
                [profiles]="profiles$ | async"
                [talent]="profile.user"></app-talent-photoalbum-overview>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-similar-talents-overview></app-similar-talents-overview>
        </div>
      </div>
    </div>
  </section>

  <button mat-button mat-dialog-close>&times;</button>

  <div class="spinner-overlay d-flex align-items-center justify-content-center" *ngIf="isLoading">
    <app-spinner></app-spinner>
    <!-- <span translate>The profile is loaded</span> -->
  </div>

  <ng-template #notFound translate>The talent could not be found or doesn't have any profiles</ng-template>
</ng-container>
