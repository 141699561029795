import { IMedia, JobApplicationStatus } from '@core/interfaces';
import { Media, TravelExpense } from '@core/models';

export class FetchJobs {
  public static readonly type = '[Jobs] Fetch jobs for user';

  public constructor(
    public userId: string,
    public status: string,
    public page: number = 1,
    public pageSize: number = 25,
    public infintyScrollMode: boolean = false,
  ) {}
}

export class FetchJobsAdmin {
  public static readonly type = '[Jobs] Fetch jobs for admin';

  public constructor(
    public query: string,
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'desc',
  ) {}
}

export class FetchEditJob {
  public static readonly type = '[Jobs] Fetch job for edit';

  public constructor(public jobId: string) {}
}

export class FetchJobDetails {
  public static readonly type = '[Jobs] Fetch job details';

  public constructor(public jobId: string, public updateInList: boolean = false) {}
}

export class ClearDetailsJob {
  public static readonly type = '[Jobs] Clear job details for listview';

  public constructor(public jobId: string) {}
}

export class FetchJob {
  public static readonly type = '[Jobs] Fetch job';

  public constructor(public jobId: string) {}
}

export class ClearJob {
  public static readonly type = '[Jobs] Clear job';

  public constructor() {}
}

export class ApplyToJob {
  public static readonly type = '[Jobs] Apply to job';

  public constructor(public userId: string, public jobId: string, public media?: IMedia) {}
}

export class UploadVideoForJobApplication {
  public static readonly type = '[Jobs] Upload video for job-application';

  public constructor(public jobApplicationId: string, public media?: IMedia) {}
}

export class RequestTalentVideoForJobApplication {
  public static readonly type = '[Jobs] Request video for job-application';

  public constructor(public jobApplicationId: string) {}
}

export class AcceptJobApplication {
  public static readonly type = '[Jobs] Accept job application';

  public constructor(public userId: string, public jobId: string, public jobApplicationId: string) {}
}

export class ConfirmJobApplication {
  public static readonly type = '[Jobs] Confirm job application';

  public constructor(public userId: string, public jobId: string, public jobApplicationId: string) {}
}

export class CancelJobApplication {
  public static readonly type = '[Jobs] Cancel job application';

  public constructor(public userId: string, public jobId: string, public jobApplicationId: string) {}
}

export class RequestToBookJobApplication {
  public static readonly type = '[Jobs] Request to book job application';

  public constructor(public userId: string, public jobId: string, public jobApplicationId: string) {}
}

export class CancelRequestToBookJobApplication {
  public static readonly type = '[Jobs] Cancel request to book job application';

  public constructor(public userId: string, public jobId: string, public jobApplicationId: string) {}
}

export class AcceptJobApplicationAndCreatePayment {
  public static readonly type = '[Jobs] Accept job application and create payment';

  public constructor(
    public userId: string,
    public jobUserId: string,
    public jobId: string,
    public jobApplicationId: string,
    public returnUrl: string,
    public directPayment: boolean,
  ) {}
}

export class RejectJobApplication {
  public static readonly type = '[Jobs] Reject job application';

  public constructor(public userId: string, public jobId: string, public jobApplicationId: string) {}
}

export class RejectJobApplications {
  public static readonly type = '[Jobs] Reject job applications';

  public constructor(public jobId: string, public ids: string[], public reason?: string) {}
}

export class RejectBookingJobApplication {
  public static readonly type = '[Jobs] Reject booking job application';

  public constructor(
    public userId: string,
    public jobId: string,
    public jobApplicationId: string,
    public reason?: string,
  ) {}
}

export class CancelBookingJobApplication {
  public static readonly type = '[Jobs] cancel booking job application';

  public constructor(
    public userId: string,
    public jobId: string,
    public jobApplicationId: string,
    public reason?: string,
  ) {}
}

export class CasterCancelBookingJobApplication {
  public static readonly type = '[Jobs] caster cancel booking job application';

  public constructor(public jobId: string, public jobApplicationId: string, public reason?: string) {}
}

export class FetchJobApplications {
  public static readonly type = '[Jobs] Fetch job applications';

  public constructor(public userId: string, public jobApplicationStatus: JobApplicationStatus) {}
}

export class FetchAllJobApplications {
  public static readonly type = '[Jobs] Fetch job applications';

  public constructor(public userId: string) {}
}

export class FetchJobApplication {
  public static readonly type = '[Jobs] Fetch job application';

  public constructor(public jobApplicationId: string) {}
}

export class SaveJobApplicationTravelExpenses {
  public static readonly type = '[Jobs] Save a job application travel expenses';

  public constructor(public id: string, public travelExpenses?: TravelExpense[], public receipts?: Partial<Media>[]) {}
}

export class SaveJob {
  public static readonly type = '[Jobs] Save job';

  public constructor(
    public jobData: any,
    public talentId?: string,
    public returnUrl?: string,
    public redirectToPayment: boolean = false,
  ) {}
}

export class MarkJobCompleted {
  public static readonly type = '[Jobs] Mark job completed';
  public constructor(public id: string) {}
}

export class ApproveTravelExpenses {
  public static readonly type = '[Job Applications] Mark travel expenses approved';
  public constructor(public jobId: string, public jobApplicationId: string) {}
}

export class RejectTravelExpenses {
  public static readonly type = '[Job Applications] Mark travel expenses rejected';
  public constructor(public jobId: string, public jobApplicationId: string, public reason: string) {}
}

export class CancelJob {
  public static readonly type = '[Jobs] Cancel job';
  public constructor(public id: string) {}
}

export class DuplicateJob {
  public static readonly type = '[Jobs] Duplicate job';

  public constructor(public id: string) {}
}

export class EnableJobNotification {
  public static readonly type = '[Jobs] Enable Job Notification';
  public constructor(public id: string) {}
}

export class DisableJobNotification {
  public static readonly type = '[Jobs] Disable Job Notification';
  public constructor(public id: string) {}
}

export class FetchPotentialUsersForJob {
  public static readonly type = '[Jobs] Fetch potentials users for job';

  public constructor(public jobId: string) {}
}

export class FetchJobsForHomePage {
  public static readonly type = '[Jobs] Fetch job for home page';

  public constructor() {}
}
