<app-mobile-modal-dialog #appRemoveInvitaionModal>
  <div class="invitation-confirmation-container">
    <p>
      You have been exclusively invited/recommended to Casterbee
      <br />
      <br />
      Please continue with the action below!
    </p>

    <button (click)="selectUserAction(true)" class="sign-up">Sign up</button>
    <p>If you don't have an account yet</p>

    <button (click)="selectUserAction(false)" class="login">Login</button>
    <p>Login if you already have an account</p>
  </div>
</app-mobile-modal-dialog>
