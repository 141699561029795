<div class="mobile-job-rows-list-container">
  <div
    class="mobile-job-rows"
    (click)="openModal(job)"
    [class.promoted-job]="promoted"
    *ngFor="let job of jobs; let i = index">
    <div class="job-title">
      <div class="job-title-img-container">
        <img
          [src]="
          job.media.length >= 1 ?
          job.media[0].url :
          '/assets/img/caster-default.png'"
          alt="" />
      </div>
      <p>{{job.title}}</p>
    </div>
    <div class="job-desc">
      <p *ngIf="!job.hideRate">{{ job.totalRate | customCurrency }}</p>
      <p *ngIf="(!job.hideRate || (userAlreadyApplied$ | async) === true)">
        Buyout
        <app-money
          *ngIf="job.extraFees; else noResults"
          [amount]="job.extraFees"
          class="flex-grow-1 text-break"></app-money>
        <span #buyoutIncluded translate>Included</span>
      </p>
      <p>{{ job.category.label }}</p>
      <p>
        <span *ngIf="job?.formattedGenders.length >= 3; else genderList">{{'All Gender' | translate}}</span>
        <ng-template #genderList>
          <span *ngFor="let gender of job?.formattedGenders; i as index" translate class="mr-1">{{ gender }},</span>
        </ng-template>
      </p>
      <p>{{ job.firstDate.start | date: "dd MMM" }} - {{ job.firstDate.end | date: "dd MMM" }}</p>
      <p *ngIf="job.countries[0]?.city || job.countries[0]?.country?.isoCode">
        <ng-container *ngIf="job.countries[0]?.city">{{ job.countries[0]?.city }},</ng-container>
        {{ job.countries[0]?.country?.isoCode }}
      </p>
    </div>
  </div>
  <div *ngIf="promoted" class="job-list-divider"></div>
</div>
